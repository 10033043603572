import { t } from 'i18next'
import { h } from 'hyperapp'
import { allRoutes, addCSRFToken, isDefined } from '@app/core'
import { Link } from '@app/elements'

import { UserMenu } from '@app/layouts/usermenu'

import icoPatient from '@app/img/ico/left-menu/ico-patient.png'
import icoPatientx2 from '@app/img/ico/left-menu/ico-patient@2x.png'
import icoPatientBlue from '@app/img/ico/left-menu/ico-patient-active.png'
import icoPatientBluex2 from '@app/img/ico/left-menu/ico-patient-active@2x.png'

import icoPatients from '@app/img/ico/left-menu/ico-listing.png'
import icoPatientsx2 from '@app/img/ico/left-menu/ico-listing@2x.png'
import icoPatientsBlue from '@app/img/ico/left-menu/ico-listing-active.png'
import icoPatientsBluex2 from '@app/img/ico/left-menu/ico-listing-active@2x.png'

import icoAlerts from '@app/img/ico/left-menu/ico-alert.png'
import icoAlertsx2 from '@app/img/ico/left-menu/ico-alert@2x.png'
import icoAlertsBlue from '@app/img/ico/left-menu/ico-alert-active.png'
import icoAlertsBluex2 from '@app/img/ico/left-menu/ico-alert-active@2x.png'

export default {
    initView: function () {
        let view = (props, actions) => (
            <div class="leftmenu-wrapper">
                <ul class="mainmenu">
                    <div
                        class={
                            'minifyLeftMenu ' +
                            (props.minifiedLeftMenu ? 'minifiedLeftMenu' : '')
                        }
                        onclick={props.minifyLeftMenu}
                        role="button"
                        tabindex="0"
                        onKeyUp={props.minifyLeftMenu}
                    >
                        <p class=""></p>
                    </div>
                    <p class="thfOnglet">{t('Patients')}</p>
                    <li
                        onclick={() =>
                            (window.location.href = addCSRFToken(
                                allRoutes['private.patients.listing'].pathname
                            ))
                        }
                        class={props.page == 'patients' ? 'active' : ''}
                        tabindex="0"
                        onKeyUp={
                            (window.location.href = addCSRFToken(
                                allRoutes['private.patients.listing'].pathname
                            ))
                        }
                    >
                        <div>
                            {props.page == 'patients' ? (
                                <img
                                    class="activated"
                                    src={icoPatientsBlue}
                                    srcset={`${icoPatientsBluex2} 2x`}
                                    alt=""
                                />
                            ) : (
                                <img
                                    class="activated"
                                    src={icoPatients}
                                    srcset={`${icoPatientsx2} 2x`}
                                    alt=""
                                />
                            )}
                        </div>
                        <Link>{t('Liste des patients')}</Link>
                    </li>
                    {isDefined(props.doctor) &&
                        props.doctor.role !== 'promoter' && (
                            <li
                                onclick={() =>
                                    (window.location.href = addCSRFToken(
                                        allRoutes['private.patient.add']
                                            .pathname
                                    ))
                                }
                                class={
                                    'thfOngletAddPatient ' +
                                    (props.page == 'patient' ? 'active' : '')
                                }
                                tabindex="0"
                                onKeyUp={
                                    (window.location.href = addCSRFToken(
                                        allRoutes['private.patient.add']
                                            .pathname
                                    ))
                                }
                            >
                                <div>
                                    {props.page == 'patient' ? (
                                        <img
                                            class="activated"
                                            src={icoPatientBlue}
                                            srcset={`${icoPatientBluex2} 2x`}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            class="activated"
                                            src={icoPatient}
                                            srcset={`${icoPatientx2} 2x`}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <Link>{t('Ajouter un patient')}</Link>
                            </li>
                        )}
                    <p class="thfOnglet">{t('Télésuivi')}</p>
                    {isDefined(props.doctor) &&
                        isDefined(props.doctor.refHosp) &&
                        isDefined(
                            props.doctor.refHosp.customerModules
                                .studySurveyResult
                        ) &&
                        props.doctor.refHosp.customerModules
                            .studySurveyResult === true && (
                            <li
                                onclick={() =>
                                    (window.location.href = addCSRFToken(
                                        allRoutes['private.alertsrds.listing']
                                            .pathname
                                    ))
                                }
                                class={
                                    props.page == 'alertsrds' ? 'active' : ''
                                }
                                tabindex="0"
                                onKeyUp={
                                    (window.location.href = addCSRFToken(
                                        allRoutes['private.alertsrds.listing']
                                            .pathname
                                    ))
                                }
                            >
                                <div>
                                    {props.page == 'alertsrds' ? (
                                        <img
                                            class="activated"
                                            src={icoAlertsBlue}
                                            srcset={`${icoAlertsBluex2} 2x`}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            class="activated"
                                            src={icoAlerts}
                                            srcset={`${icoAlertsx2} 2x`}
                                            alt=""
                                        />
                                    )}
                                </div>
                                {isDefined(
                                    props.infos
                                        .countUserMedicalStudySurveyAlertTodo
                                ) &&
                                    !Array.isArray(
                                        props.infos
                                            .countUserMedicalStudySurveyAlertTodo
                                    ) && (
                                        <p class="">
                                            {Object.values(
                                                props.infos
                                                    .countUserMedicalStudySurveyAlertTodo
                                            ).reduce((a, b) => a + b)}
                                        </p>
                                    )}
                                <Link>{t('Alertes')}</Link>
                            </li>
                        )}
                </ul>

                <UserMenu
                    {...props}
                    display={'connected'}
                    minifiedLeftMenu={props.minifiedLeftMenu}
                ></UserMenu>
            </div>
        )
        return view
    },
}
