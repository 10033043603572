import { t } from 'i18next'
import { h } from 'hyperapp'
import { Form, FormInput, Card, Button } from '@app/elements'
import { isDefined } from '@app/core'

import * as constants from '@app/constants'

import { ModalViewConfigurationForm } from './forms/modalView-configurationForm-default'
import { ModalViewConfigurationWidget } from './forms/modalView-configurationWidget-default'
import { ModalViewConfirm } from './confirm/modalView-confirm-default'

export const ModalViewConfiguration = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={'configuration'}>
                <p class="btzTitle btzXl">{t(props.editingMesure.value)}</p>
                <Form>
                    <Form.Group>
                        <div class="thfAlignTop">
                            <FormInput
                                key={'input-status'}
                                type="switch"
                                id={'status'}
                                name={'status'}
                                value={props.values.status}
                                list={[
                                    { label: t('donnée activée'), key: true },
                                    {
                                        label: t('donnée désactivée'),
                                        key: false,
                                    },
                                ]}
                                oninputcb={props.retrieveInput}
                                required
                            />
                        </div>
                    </Form.Group>
                </Form>
            </div>
            <div class="btzCard-btzHeader-btzModal-menus">
                <div
                    role="button"
                    tabIndex="-1"
                    class={
                        'btzCard-btzHeader-btzModal-menu ' +
                        (props.modalTab === 'recall' ? 'active' : '')
                    }
                    onclick={() => {
                        props.switchModalTab('recall')
                    }}
                    onkeydown={() => {
                        props.switchModalTab('recall')
                    }}
                >
                    {t('Rappels')}
                </div>
                {props.editingMesure.crypt === 'CryptedHydration' && (
                    <div
                        role="button"
                        tabIndex="-1"
                        class={
                            'btzCard-btzHeader-btzModal-menu ' +
                            (props.modalTab === 'widget' ? 'active' : '')
                        }
                        onclick={() => {
                            props.switchModalTab('widget')
                        }}
                        onkeydown={() => {
                            props.switchModalTab('recall')
                        }}
                    >
                        {t('Widget')}
                    </div>
                )}
            </div>
        </Card.Header>
        {props.loading ? (
            <Card.Body>
                <div
                    class="thfInput thfLoadingBox"
                    style="height: 100%;position: absolute;left: 0;top: 0;width: 100%;"
                >
                    <div
                        class="thfLoadingBoxWrapper"
                        style="margin: 0px;"
                    ></div>
                </div>
            </Card.Body>
        ) : (
            <dummy
                class={
                    'btzClassMarker-btz ' +
                    (isDefined(props.scheduleCurrent) &&
                    isDefined(props.scheduleCurrent.id)
                        ? 'btzClassMarker-btzSmall'
                        : '')
                }
            >
                {props.modalTab === 'recall' && (
                    <dummy>
                        {props.deactivationConfirm || props.stopConfirm ? (
                            <Card.Body>
                                <ModalViewConfirm {...props} />
                            </Card.Body>
                        ) : (
                            <dummy>
                                {props.editCurrentTelesuivi ? (
                                    <Card.Body
                                        classes={'thfTelesuiviModalBlock-form'}
                                    >
                                        <ModalViewConfigurationForm
                                            {...props}
                                        />

                                        {isDefined(props.scheduleCurrent) &&
                                            isDefined(
                                                props.scheduleCurrent.id
                                            ) &&
                                            !props.scheduleCurrent.toDelete && (
                                                <div class="thfTelesuiviModalBlock-deleteRecallBlock">
                                                    <p class="thfTelesuiviModalBlock-contentReadable">
                                                        {t(
                                                            'Mon patient doit prendre'
                                                        ) + ' '}
                                                        <span
                                                            oncreate={(el) =>
                                                                props.getReadableRecap(
                                                                    {
                                                                        el: el,
                                                                        schedule:
                                                                            props.values,
                                                                    }
                                                                )
                                                            }
                                                        ></span>
                                                    </p>
                                                    <Button
                                                        primary
                                                        flat
                                                        dangerous
                                                        onclick={(e) => {
                                                            props.onDeleteRecall(
                                                                e
                                                            )
                                                        }}
                                                    >
                                                        {t(
                                                            'Supprimer le rappel'
                                                        )}
                                                    </Button>
                                                </div>
                                            )}

                                        <div class="btzCard-btzFooter-btzModal">
                                            <Button
                                                margin={'0 0 0 15px'}
                                                primary
                                                anchorRight
                                                flat
                                                active={
                                                    props.isValid.frequencies &&
                                                    ((props.values
                                                        .frequencies === '0' &&
                                                        props.isValid
                                                            .dailySchedule) ||
                                                        (props.values
                                                            .frequencies ===
                                                            '1' &&
                                                            props.isValid
                                                                .interval &&
                                                            props.isValid
                                                                .intervalday) ||
                                                        (props.values
                                                            .frequencies ===
                                                            '2' &&
                                                            props.isValid
                                                                .before) ||
                                                        (props.values
                                                            .frequencies ===
                                                            '3' &&
                                                            props.isValid
                                                                .days &&
                                                            props.isValid
                                                                .dailySchedule))
                                                }
                                                onclick={(e) => {
                                                    props.onSaveConfiguration(e)
                                                }}
                                            >
                                                {t('Enregistrer le rappel')}
                                            </Button>
                                            <Button
                                                anchorRight
                                                flat
                                                cancel
                                                onclick={() => {
                                                    props.onCancelConfiguration()
                                                }}
                                            >
                                                {t('Annuler')}
                                            </Button>
                                        </div>
                                    </Card.Body>
                                ) : (
                                    <Card.Body>
                                        <div class="thfTelesuiviModalBlock">
                                            <div class="thfTelesuiviModalBlock-header">
                                                <p class="">
                                                    {t(
                                                        'Quand votre patient doit-il prendre sa mesure'
                                                    ) +
                                                        ' ' +
                                                        t(
                                                            props.editingMesure
                                                                .value
                                                        ) +
                                                        '?'}
                                                </p>
                                            </div>
                                            <div class="thfTelesuiviModalBlock-content">
                                                {isDefined(props.values) &&
                                                props.values.exist === true &&
                                                props.values.toDelete ===
                                                    false ? (
                                                    <dummy>
                                                        <p
                                                            class="thfTelesuiviModalBlock-contentReadable"
                                                            style="margin-right: 20px;"
                                                        >
                                                            {t(
                                                                'Mon patient doit prendre'
                                                            ) + ' '}
                                                            <span
                                                                oncreate={(
                                                                    el
                                                                ) =>
                                                                    props.getReadableRecap(
                                                                        {
                                                                            el: el,
                                                                            schedule:
                                                                                props.values,
                                                                        }
                                                                    )
                                                                }
                                                            ></span>
                                                        </p>
                                                        <Button
                                                            primary
                                                            flat
                                                            anchorRight
                                                            onclick={() => {
                                                                props.onEditCurrentTelesuivi(
                                                                    true
                                                                )
                                                            }}
                                                        >
                                                            {t('Modifier')}
                                                        </Button>
                                                    </dummy>
                                                ) : (
                                                    <dummy>
                                                        {props.ICMesureIgnore.indexOf(
                                                            props.editingMesure
                                                                .crypt
                                                        ) === -1 ? (
                                                            <Button
                                                                primary
                                                                flat
                                                                onclick={() => {
                                                                    props.onEditCurrentTelesuivi(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                {t(
                                                                    'Définir une fréquence'
                                                                )}
                                                            </Button>
                                                        ) : (
                                                            <p class="">
                                                                {t(
                                                                    'Vous ne pouvez pas définir de fréquence pour cette mesure.'
                                                                )}
                                                            </p>
                                                        )}
                                                    </dummy>
                                                )}
                                            </div>
                                        </div>
                                        <div class="thfTelesuiviModalBlock">
                                            <div class="thfTelesuiviModalBlock-header">
                                                <p class="">
                                                    {t('Quelles alertes') +
                                                        ' ' +
                                                        t(
                                                            props.editingMesure
                                                                .value
                                                        ) +
                                                        ' ' +
                                                        t(
                                                            'souhaitez-vous recevoir ?'
                                                        )}
                                                </p>
                                            </div>
                                            <div class="thfTelesuiviModalBlock-content">
                                                {props.ICMesureIgnore.indexOf(
                                                    props.editingMesure.crypt
                                                ) === -1 ? (
                                                    <dummy class="thfTelesuiviModalBlock-contentAlert">
                                                        {(!isDefined(
                                                            props.values
                                                                .frequencies
                                                        ) ||
                                                            !props.values
                                                                .exist) && (
                                                            <p
                                                                class=""
                                                                style="line-height: 1.2; margin-bottom: 15px;"
                                                            >
                                                                {t(
                                                                    'Avant de pouvoir sélectionner une alerte « mesure manquante », définissez une fréquence de rappel de saisie pour votre patient.'
                                                                )}
                                                            </p>
                                                        )}
                                                        {constants.custo.telesuiviScenarios
                                                            .filter(
                                                                (f) =>
                                                                    f.reserved
                                                                        .length ===
                                                                        0 ||
                                                                    f.reserved.indexOf(
                                                                        props
                                                                            .editingMesure
                                                                            .crypt
                                                                    ) > -1
                                                            )
                                                            .map((item) => (
                                                                <Button
                                                                    primary
                                                                    flat
                                                                    loading={
                                                                        (!isDefined(
                                                                            props
                                                                                .values
                                                                                .frequencies
                                                                        ) ||
                                                                            props
                                                                                .values
                                                                                .frequencies ===
                                                                                '' ||
                                                                            !props
                                                                                .values
                                                                                .exist) &&
                                                                        item.mandatoryFrequency ===
                                                                            true
                                                                    }
                                                                    active={isDefined(
                                                                        props.values.alerts.filter(
                                                                            (
                                                                                f
                                                                            ) =>
                                                                                f.key ===
                                                                                item.key
                                                                        )[0]
                                                                    )}
                                                                    onclick={() => {
                                                                        if (
                                                                            (isDefined(
                                                                                props
                                                                                    .values
                                                                                    .frequencies
                                                                            ) &&
                                                                                props
                                                                                    .values
                                                                                    .frequencies !==
                                                                                    '' &&
                                                                                props
                                                                                    .values
                                                                                    .exist) ||
                                                                            item.mandatoryFrequency ===
                                                                                false
                                                                        ) {
                                                                            props.onActivateAlerting(
                                                                                item.key
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    {t(
                                                                        item.value
                                                                    )}
                                                                </Button>
                                                            ))}
                                                    </dummy>
                                                ) : (
                                                    <p class="">
                                                        {t(
                                                            "Vous ne pouvez pas définir d'alertes pour cette mesure."
                                                        )}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            class="btzCard-btzFooter-btzModal"
                                            style="margin-top: 40px;"
                                        >
                                            {isDefined(props.scheduleCurrent) &&
                                                isDefined(
                                                    props.scheduleCurrent.id
                                                ) && (
                                                    <div class="thfTelesuiviModalBlock-deleteRecallBlock">
                                                        <p class="thfTelesuiviModalBlock-contentReadable">
                                                            {t(
                                                                'Mon patient doit prendre'
                                                            ) + ' '}
                                                            <span
                                                                oncreate={(
                                                                    el
                                                                ) =>
                                                                    props.getReadableRecap(
                                                                        {
                                                                            el: el,
                                                                            schedule:
                                                                                props.values,
                                                                        }
                                                                    )
                                                                }
                                                            ></span>
                                                        </p>
                                                        <Button
                                                            primary
                                                            anchorLeft
                                                            flat
                                                            dangerous
                                                            onclick={() => {
                                                                props.onDeactivateMesure(
                                                                    'recallwithalerts'
                                                                )
                                                            }}
                                                        >
                                                            {t(
                                                                'Arrêter le télésuivi'
                                                            )}
                                                        </Button>
                                                    </div>
                                                )}
                                            <Button
                                                margin={'0 0 0 15px'}
                                                active={
                                                    props.updateStatus ||
                                                    (isDefined(
                                                        props.scheduleCurrent
                                                    ) &&
                                                        isDefined(
                                                            props
                                                                .scheduleCurrent
                                                                .id
                                                        )) ||
                                                    (props.isValid
                                                        .frequencies &&
                                                        ((props.values
                                                            .frequencies ===
                                                            '0' &&
                                                            props.isValid
                                                                .dailySchedule) ||
                                                            (props.values
                                                                .frequencies ===
                                                                '1' &&
                                                                props.isValid
                                                                    .interval &&
                                                                props.isValid
                                                                    .intervalday) ||
                                                            (props.values
                                                                .frequencies ===
                                                                '2' &&
                                                                props.isValid
                                                                    .before) ||
                                                            (props.values
                                                                .frequencies ===
                                                                '3' &&
                                                                props.isValid
                                                                    .days &&
                                                                props.isValid
                                                                    .dailySchedule)))
                                                }
                                                primary
                                                anchorRight
                                                flat
                                                onclick={(e) => {
                                                    if (
                                                        props.updateStatus ||
                                                        (isDefined(
                                                            props.scheduleCurrent
                                                        ) &&
                                                            isDefined(
                                                                props
                                                                    .scheduleCurrent
                                                                    .id
                                                            )) ||
                                                        (props.isValid
                                                            .frequencies &&
                                                            ((props.values
                                                                .frequencies ===
                                                                '0' &&
                                                                props.isValid
                                                                    .dailySchedule) ||
                                                                (props.values
                                                                    .frequencies ===
                                                                    '1' &&
                                                                    props
                                                                        .isValid
                                                                        .interval &&
                                                                    props
                                                                        .isValid
                                                                        .intervalday) ||
                                                                (props.values
                                                                    .frequencies ===
                                                                    '2' &&
                                                                    props
                                                                        .isValid
                                                                        .before) ||
                                                                (props.values
                                                                    .frequencies ===
                                                                    '3' &&
                                                                    props
                                                                        .isValid
                                                                        .days &&
                                                                    props
                                                                        .isValid
                                                                        .dailySchedule)))
                                                    ) {
                                                        props.onSubmit(e)
                                                    }
                                                }}
                                            >
                                                {t('Enregistrer')}
                                            </Button>
                                            <Button
                                                anchorRight
                                                flat
                                                cancel
                                                onclick={(e) => {
                                                    props.cancelAction(e)
                                                }}
                                            >
                                                {t('Annuler')}
                                            </Button>
                                        </div>
                                    </Card.Body>
                                )}
                            </dummy>
                        )}
                    </dummy>
                )}
                {props.modalTab === 'widget' && (
                    <Card.Body>
                        <ModalViewConfigurationWidget {...props} />
                        <div
                            class="btzCard-btzFooter-btzModal"
                            style="margin-top: 40px;"
                        >
                            <Button
                                margin={'0 0 0 15px'}
                                active
                                primary
                                anchorRight
                                flat
                                onclick={(e) => {
                                    props.onSubmitWidget(e)
                                }}
                            >
                                {t('Enregistrer')}
                            </Button>
                            <Button
                                anchorRight
                                flat
                                cancel
                                onclick={(e) => {
                                    props.cancelAction(e)
                                }}
                            >
                                {t('Annuler')}
                            </Button>
                        </div>
                    </Card.Body>
                )}
            </dummy>
        )}
    </Card>
)
