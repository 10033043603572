import { getYMDfromDateObj } from '@app/core';

export const state = {
    isICModule: true,
    patientid: null,
    editedPatient: null,
    editingMesure: '',
    ICMesure: 'CryptedWeight',
    ICMesureUnit: 'VALUE',
    ICMesureIgnore: ['Observance'],
    pastDays: 7,
    dataType: {
        all: [],
        specific: [],
    },
    mesures: {
        followed: [],
        activated: [],
        deactivated: [],
    },
    dvalues: {
        ddays: [1, 2],
        days: [1, 2, 3, 4, 5, 6, 7],
        frequencies: '0',
        dailySchedule: ['6-10'],
        interval: 'D',
        intervalday: 2,
        status: false,
    },
    ovalues: {
        days: [],
        frequencies: '0',
        dailySchedule: ['6-10'],
        interval: '',
        intervalday: '',
        cycle: '',
        start: getYMDfromDateObj(),
        end: {
            year: '',
            month: '',
            day: ''
        },
        before: {
            year: '',
            month: '',
            day: ''
        },
        alerts: [],
        toDelete: false,
        exist: false,
        measureType: '',
        status: null,
    },
    values: {},
    isTouched: {
        days: false,
        frequencies: false,
        dailySchedule: false,
        interval: false,
        intervalday: false,
        cycle: false,
        start: false,
        end: false,
        before: false,
        alerts: false,
        status: false,
    },
    isValid: {
        days: false,
        frequencies: false,
        dailySchedule: false,
        interval: false,
        intervalday: false,
        cycle: false,
        start: false,
        end: false,
        before: false,
        alerts: false,
        status: false,
    },
    errorMessages: {
        days: '',
        frequencies: '',
        dailySchedule: '',
        interval: '',
        intervalday: '',
        cycle: '',
        end: '',
        before: '',
    },
    isTyping: {
        days: false,
        frequencies: false,
        dailySchedule: false,
        interval: false,
        intervalday: false,
        cycle: false,
        start: false,
        end: false,
        before: false,
        alerts: false,
        status: false,
    },
    widgetSetting: {
        'HYDRATION_WIDGET': false,
    },
    page: 0,
    menu: '',
    switchedToConfiguration: false,
    forceRender: null,
    editCurrentTelesuivi: false,
    dataTypeCurrent: [''],
    currentDate: getYMDfromDateObj(null, true, true).split('/').join(''),
    currentPeriod: 'week',
    themeCurrent: null,
    scheduleCurrent: null,
    updateStatus: false,
    alerts: [],
    chartMesures: [],
    tableDatas: [],
    tableDatasReversed: [],
    modules: [],
    enableModules: [],
    formSubmittedOnce: false,
    modalDisplayed: false,
    deactivationConfirm: false,
    deactivationConfirmKind: '',
    specificDashApi: ['observance'],
    modalEditing: '',
    loading: false,
    defaultLegendColor: '#f2f4f6',
    modalTab: 'recall',
    updatePatient: null,
};
