import { t } from 'i18next'
import { h } from 'hyperapp'
import {
    isDefined,
    getYMDfromDateObj,
    getDateObjFromYMD,
    getReadableDate,
    getDiffDate,
    createUTCDate,
    getClosest,
} from '@app/core'
import { Card, Button, Form, Tile, Enter, Modal } from '@app/elements'
import * as constants from '@app/constants'

import { ModalViewSurvey } from './../../modals/modalView-survey-default'
import { ModalViewAcquit } from './../../modals/modalView-acquit-default'
import { ModalViewSurveyPreview } from './../../modals/modalView-surveyPreview-default'
import { ModalViewSwitchPhase } from './../../modals/modalView-switchPhase-default'
import { ModalViewDoSurvey } from './../../../../study/views/modals/modalView-doSurvey'

import icoAdd from '@app/img/ico/ico-add.png'
import icoAddx2 from '@app/img/ico/ico-add@2x.png'
import icoAcquit from '@app/img/ico/ico-plain-valid.png'
import icoAcquitx2 from '@app/img/ico/ico-plain-valid@2x.png'
import imgNoPatient from '@app/img/deco/img-no-patient.png'
import imgNoPatientx2 from '@app/img/deco/img-no-patient@2x.png'

import icoMessageOn from '@app/img/ico/ico-message-on.png'
import icoMessageOnx2 from '@app/img/ico/ico-message-on@2x.png'

import icoInterrogation from '@app/img/ico/ico-interrogation-white.png'
import icoInterrogationx2 from '@app/img/ico/ico-interrogation-white@2x.png'
import icoInterrogationBlue from '@app/img/ico/ico-interrogation.png'
import icoInterrogationBluex2 from '@app/img/ico/ico-interrogation@2x.png'
import icoInterrogationDarkBlue from '@app/img/ico/ico-interrogation-darkblue.png'
import icoInterrogationDarkBluex2 from '@app/img/ico/ico-interrogation-darkblue@2x.png'

export const ViewHorizontalTable = (props, actions) => (
    <Card
        classes={'btzPageCard btzBackgroundCover'}
        kind={'datas'}
        style={{ margin: '30px 30px 0px 30px' }}
    >
        <Card.Header classes={'btzCard-btzHeader-btzPage'}>
            <Enter
                time={constants.custo.ANIM_DURATION}
                easing={constants.custo.ANIM_EASING}
                css={constants.custo.ANIM_CSS}
            >
                <dummy>
                    {props.loaded && (
                        <Tile>
                            <div
                                class="thfSurveyReport-header"
                                style="white-space: nowrap;"
                            >
                                <div class="col-6 col-xs-12 thfAlignTop">
                                    <div
                                        class={
                                            'thfSurveyReportEdit ' +
                                            (props.medicalStudyEdited.status ===
                                            true
                                                ? 'active'
                                                : 'deactivated')
                                        }
                                    >
                                        <div
                                            class="fakeButton active"
                                            style="margin: 0px;"
                                        >
                                            <p class="thfSwitchLabel">
                                                {
                                                    props.medicalStudyEdited
                                                        .usualName
                                                }
                                            </p>
                                            <div
                                                tabindex="1"
                                                class={
                                                    'btzLightSmallPopupShower thfInfoIco ' +
                                                    (props.medicalStudyEdited
                                                        .status === true
                                                        ? 'active'
                                                        : 'deactivated')
                                                }
                                            >
                                                <img
                                                    alt={'Activé ?'}
                                                    class=""
                                                    src={icoInterrogation}
                                                    srcset={`${icoInterrogationx2} 2x`}
                                                />
                                            </div>
                                            <div class="rdsTreatmentHeaderInfo btzLightSmallPopup">
                                                <div class="btzLightSmallPopup-content">
                                                    <p class="btzSmallPopupTitle">
                                                        {
                                                            props
                                                                .medicalStudyEdited
                                                                .usualName
                                                        }
                                                    </p>
                                                    <div class="btzSmallPopupContent">
                                                        {isDefined(
                                                            props
                                                                .medicalStudyEdited
                                                                .clinicalIndication
                                                        ) &&
                                                            props
                                                                .medicalStudyEdited
                                                                .clinicalIndication !==
                                                                '' && (
                                                                <dummy>
                                                                    <p class="btzSmallPopupLabel">
                                                                        {t(
                                                                            'Indication clinique'
                                                                        ) +
                                                                            ' :'}
                                                                    </p>
                                                                    <p class="btzSmallPopupContentText">
                                                                        {
                                                                            props
                                                                                .medicalStudyEdited
                                                                                .clinicalIndication
                                                                        }
                                                                    </p>
                                                                </dummy>
                                                            )}
                                                        <p
                                                            class="btzSmallPopupLightButton"
                                                            style="display: block; margin: 15px auto;"
                                                            onclick={() => {
                                                                props.retrieveSurveyContent(
                                                                    props.medicalStudyEdited
                                                                )
                                                            }}
                                                        >
                                                            {t(
                                                                'Consulter le questionnaire'
                                                            )}
                                                        </p>
                                                        <p class="btzSmallPopupLabel">
                                                            {t(
                                                                'Etat du questionnaire'
                                                            ) + ' :'}
                                                        </p>
                                                        <div class="displayed-if-deactivated">
                                                            <p
                                                                class="displayed-if-deactivated text-dangerous"
                                                                style="margin-left: 0px;"
                                                            >
                                                                {t(
                                                                    'Envois arrêtés'
                                                                )}
                                                            </p>
                                                            {' - '}
                                                            <p
                                                                class="btzSmallPopupLightButton"
                                                                onclick={(
                                                                    e
                                                                ) => {
                                                                    props._setState(
                                                                        {
                                                                            key: 'newSurvey',
                                                                            value: true,
                                                                        }
                                                                    )
                                                                    props.retrieveStudy()
                                                                }}
                                                            >
                                                                {t(
                                                                    'Réactiver les envois'
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div class="displayed-if-active ">
                                                            <p
                                                                class="thfColored-green"
                                                                style="margin-left: 0px;"
                                                            >
                                                                {t(
                                                                    'Envois en cours'
                                                                )}
                                                            </p>
                                                            {' - '}
                                                            <p
                                                                class="btzSmallPopupLightButton"
                                                                onclick={() => {
                                                                    props._setState(
                                                                        {
                                                                            key: 'newSurvey',
                                                                            value: false,
                                                                        }
                                                                    )
                                                                    props.retrieveStudy()
                                                                }}
                                                            >
                                                                {t('Modifier')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="btzLightSmallPopup-footer">
                                                    <Button
                                                        cancel
                                                        flat
                                                        onclick={(el) => {
                                                            getClosest(
                                                                el.target,
                                                                '.btzLightSmallPopup'
                                                            ).style.display =
                                                                'none'
                                                        }}
                                                    >
                                                        {t('Fermer')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <p
                                            class=""
                                            style={{
                                                'max-width':
                                                    'calc(100% - 195px)',
                                                'cursor': 'default',
                                                'white-space': 'normal',
                                                'vertical-align':
                                                    props.medicalStudyEdited
                                                        .status === false
                                                        ? 'top'
                                                        : 'middle',
                                            }}
                                        >
                                            <dummy>
                                                {props.medicalStudyEdited
                                                    .status === false
                                                    ? t('Envois arrêtés')
                                                    : t('envoyé') +
                                                      ' ' +
                                                      (props.medicalStudyEdited
                                                          .issueSentBy ===
                                                      'EMAIL'
                                                          ? t('par Email')
                                                          : t('par SMS')) +
                                                      ', ' +
                                                      t(
                                                          constants.custo.periodFrequencies.filter(
                                                              (f) =>
                                                                  f.key ===
                                                                  props
                                                                      .medicalStudyEdited
                                                                      .issuePeriod
                                                          )[0]?.label
                                                      ).toLowerCase()}
                                                {' - '}
                                            </dummy>
                                            {props.modalDisplayed &&
                                                !props.switchPhase &&
                                                props.modalEditing.indexOf(
                                                    'survey'
                                                ) > -1 && (
                                                    <Modal
                                                        key={
                                                            'modal-survey-survey'
                                                        }
                                                        cancelAction={() => {
                                                            props.onCancelEditSurvey()
                                                        }}
                                                        modalDisplayed={true}
                                                        content={
                                                            <ModalViewSurvey
                                                                displayed={
                                                                    'survey'
                                                                }
                                                                {...props}
                                                                retrieveInput={
                                                                    props.retrieveInput
                                                                }
                                                                onSubmit={
                                                                    props.updateStudy
                                                                }
                                                                cancelAction={() => {
                                                                    props.onCancelEditSurvey()
                                                                }}
                                                                loaded={true}
                                                            ></ModalViewSurvey>
                                                        }
                                                    ></Modal>
                                                )}
                                            <div
                                                style="display: inline-block; vertical-align: middle; height: 13px; cursor: pointer;margin-left: 3px;"
                                                onclick={() => {
                                                    props.retrieveStudy()
                                                }}
                                            >
                                                <font>
                                                    {props.medicalStudyEdited
                                                        .status === false
                                                        ? t(
                                                              'Réactiver le questionnaire'
                                                          )
                                                        : t('Modifier')}
                                                </font>
                                            </div>
                                            {props.medicalStudyEdited.status ===
                                                true && (
                                                <dummy style="display: block;">
                                                    {isDefined(
                                                        props.medicalStudyAnswers
                                                    ) &&
                                                    props.medicalStudyAnswers
                                                        .length > 0 &&
                                                    isDefined(
                                                        props
                                                            .medicalStudyAnswers[0]
                                                            .id
                                                    ) &&
                                                    props.medicalStudyAnswers[0]
                                                        .date ===
                                                        getYMDfromDateObj(
                                                            null,
                                                            true,
                                                            true
                                                        ) ? (
                                                        <dummy style="display: block">
                                                            {t(
                                                                "Questionnaire déjà répondu aujourd'hui"
                                                            )}
                                                        </dummy>
                                                    ) : (
                                                        <dummy>
                                                            {isDefined(
                                                                props
                                                                    .medicalStudyEdited
                                                                    .issueSentAt
                                                            ) &&
                                                            getYMDfromDateObj(
                                                                false,
                                                                true,
                                                                true
                                                            ) ===
                                                                getYMDfromDateObj(
                                                                    props
                                                                        .medicalStudyEdited
                                                                        .issueSentAt
                                                                        .date,
                                                                    true,
                                                                    true
                                                                ) ? (
                                                                <dummy style="display: block">
                                                                    {t(
                                                                        "Questionnaire envoyé aujourd'hui à"
                                                                    ) +
                                                                        ' ' +
                                                                        getReadableDate(
                                                                            createUTCDate(
                                                                                props
                                                                                    .medicalStudyEdited
                                                                                    .issueSentAt
                                                                                    .date
                                                                            ),
                                                                            'HH[h]mm'
                                                                        ) +
                                                                        '.' +
                                                                        ' ' +
                                                                        t(
                                                                            'Prochain envoi possible demain matin.'
                                                                        )}
                                                                </dummy>
                                                            ) : (
                                                                <dummy>
                                                                    {props.justSendedSurvey ? (
                                                                        <font class="thfColored-green">
                                                                            {t(
                                                                                'Questionnaire envoyé'
                                                                            )}
                                                                        </font>
                                                                    ) : (
                                                                        <font
                                                                            onclick={
                                                                                props.sendInstantSurvey
                                                                            }
                                                                            style="cursor: pointer;"
                                                                        >
                                                                            {t(
                                                                                'Envoyer un questionnaire dès maintenant'
                                                                            )}
                                                                        </font>
                                                                    )}
                                                                </dummy>
                                                            )}
                                                        </dummy>
                                                    )}
                                                </dummy>
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="col-5 col-xs-12 thfAlignTop topStudiesContainer"
                                    style="white-space: normal;"
                                >
                                    {props.medicalStudies
                                        .sort((x, y) =>
                                            x.usualName === y.usualName
                                                ? 0
                                                : x.usualName < y.usualName
                                                  ? -1
                                                  : 1
                                        )
                                        .sort((x, y) =>
                                            x.status === y.status
                                                ? 0
                                                : x.status
                                                  ? -1
                                                  : 1
                                        )
                                        .filter(
                                            (f) =>
                                                f.name !==
                                                props.medicalStudyEdited.name
                                        )
                                        .map((study) => (
                                            <dummy
                                                class={
                                                    'thfSurveyReportsList ' +
                                                    (study.status === true
                                                        ? 'active'
                                                        : 'deactivated')
                                                }
                                            >
                                                <div class="fakeButton">
                                                    <p
                                                        class="thfSwitchLabel"
                                                        onclick={() =>
                                                            props.updateCurrentMedicalStudy(
                                                                study.name
                                                            )
                                                        }
                                                    >
                                                        {study.usualName ||
                                                            study.name}
                                                    </p>
                                                    <div
                                                        tabindex="1"
                                                        class={
                                                            'btzLightSmallPopupShower thfInfoIco ' +
                                                            (study.status ===
                                                            true
                                                                ? 'active'
                                                                : 'deactivated')
                                                        }
                                                        onclick={(el) => {
                                                            el.preventDefault()
                                                            el.stopPropagation()
                                                        }}
                                                    >
                                                        <img
                                                            alt={'Activé ?'}
                                                            class=""
                                                            src={
                                                                props.customer ===
                                                                'resilience'
                                                                    ? icoInterrogationDarkBlue
                                                                    : icoInterrogationBlue
                                                            }
                                                            srcset={`${
                                                                props.customer ===
                                                                'resilience'
                                                                    ? icoInterrogationDarkBluex2
                                                                    : icoInterrogationBluex2
                                                            } 2x`}
                                                        />
                                                    </div>
                                                    <div class="rdsTreatmentHeaderInfo btzLightSmallPopup">
                                                        <div class="btzLightSmallPopup-content">
                                                            <p class="btzSmallPopupTitle">
                                                                {study.usualName ||
                                                                    study.name}
                                                            </p>
                                                            <div class="btzSmallPopupContent">
                                                                {isDefined(
                                                                    study.clinicalIndication
                                                                ) && (
                                                                    <dummy>
                                                                        <p class="btzSmallPopupLabel">
                                                                            {t(
                                                                                'Indication clinique'
                                                                            ) +
                                                                                ' :'}
                                                                        </p>
                                                                        <p class="btzSmallPopupContentText">
                                                                            {
                                                                                study.clinicalIndication
                                                                            }
                                                                        </p>
                                                                    </dummy>
                                                                )}
                                                                <p
                                                                    class="btzSmallPopupLightButton"
                                                                    style="display: block !important; margin: 15px auto;"
                                                                    onclick={() => {
                                                                        props.retrieveSurveyContent(
                                                                            study
                                                                        )
                                                                    }}
                                                                >
                                                                    {t(
                                                                        'Consulter le questionnaire'
                                                                    )}
                                                                </p>
                                                                <p class="btzSmallPopupLabel">
                                                                    {t(
                                                                        'Etat du questionnaire'
                                                                    ) + ' :'}
                                                                </p>
                                                                <div class="displayed-if-deactivated">
                                                                    <p
                                                                        class="displayed-if-deactivated text-dangerous"
                                                                        style="margin-left: 0px;"
                                                                    >
                                                                        {t(
                                                                            'Envois arrêtés'
                                                                        )}
                                                                    </p>
                                                                    {' - '}
                                                                    <p
                                                                        class="btzSmallPopupLightButton"
                                                                        onclick={() => {
                                                                            props._setState(
                                                                                {
                                                                                    key: 'newSurvey',
                                                                                    value: true,
                                                                                }
                                                                            )
                                                                            props.retrieveStudy(
                                                                                study
                                                                            )
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'Réactiver les envois'
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div class="displayed-if-active ">
                                                                    <p
                                                                        class="thfColored-green"
                                                                        style="margin-left: 0px;"
                                                                    >
                                                                        {t(
                                                                            'Envois en cours'
                                                                        )}
                                                                    </p>
                                                                    {' - '}
                                                                    <p
                                                                        class="btzSmallPopupLightButton"
                                                                        onclick={() => {
                                                                            props._setState(
                                                                                {
                                                                                    key: 'newSurvey',
                                                                                    value: false,
                                                                                }
                                                                            )
                                                                            props.retrieveStudy(
                                                                                study
                                                                            )
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'Modifier'
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="btzLightSmallPopup-footer">
                                                            <Button
                                                                cancel
                                                                flat
                                                                onclick={(
                                                                    el
                                                                ) => {
                                                                    getClosest(
                                                                        el.target,
                                                                        '.btzLightSmallPopup'
                                                                    ).style.display =
                                                                        'none'
                                                                }}
                                                            >
                                                                {t('Fermer')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dummy>
                                        ))}
                                </div>
                                <div class="col-1 thfAlignTop surveyAddStudy">
                                    <Button
                                        flat
                                        active
                                        onclick={() => {
                                            props._setState({
                                                key: 'configurationMode',
                                                value: true,
                                            })
                                        }}
                                    >
                                        <img
                                            class=""
                                            src={icoAdd}
                                            srcset={`${icoAddx2} 2x`}
                                            alt=""
                                        />
                                    </Button>
                                </div>
                            </div>

                            {isDefined(props.medicalStudies) &&
                                isDefined(props.medicalStudyCards) && (
                                    <dummy>
                                        {isDefined(props.medicalStudyAnswers) &&
                                        props.medicalStudyAnswers.length > 0 ? (
                                            <div class="thfSurveyReport-cells">
                                                <div
                                                    class={
                                                        'thfSurveyReport-cellColDate ' +
                                                        (props.popupDisplayed !==
                                                            '' &&
                                                        isDefined(
                                                            document.activeElement
                                                        ) &&
                                                        isDefined(
                                                            document.activeElement?.getAttribute(
                                                                'data-cardname'
                                                            )
                                                        )
                                                            ? 'tricked'
                                                            : '')
                                                    }
                                                >
                                                    <div
                                                        class="thfSurveyReport-topControls thfSurveyReport-topControlLeft"
                                                        onclick={() =>
                                                            props.scrollBoard({
                                                                side: 'left',
                                                            })
                                                        }
                                                    >
                                                        <p class=""></p>
                                                    </div>
                                                    {props.medicalStudyAnswers.map(
                                                        (answer) => (
                                                            <div
                                                                key={
                                                                    'thfSurveyReport-cell-' +
                                                                    '-' +
                                                                    answer.date +
                                                                    '-' +
                                                                    answer.id
                                                                }
                                                                class={
                                                                    'thfSurveyReport-cell thfSurveyReport-cellDate ' +
                                                                    (!Array.isArray(
                                                                        answer.values
                                                                    ) &&
                                                                    !isDefined(
                                                                        answer.alertClosedBy
                                                                    ) &&
                                                                    !isDefined(
                                                                        answer.action
                                                                    )
                                                                        ? 'thfSurveyReport-cellAlertLine'
                                                                        : '')
                                                                }
                                                            >
                                                                {getYMDfromDateObj(
                                                                    createUTCDate(
                                                                        getDateObjFromYMD(
                                                                            answer.date
                                                                        ).date
                                                                    ),
                                                                    true,
                                                                    false,
                                                                    {
                                                                        year: true,
                                                                        shortyear: true,
                                                                        month: true,
                                                                        day: true,
                                                                    }
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div
                                                    class={
                                                        'thfSurveyReport ' +
                                                        (props.popupDisplayed !==
                                                            '' &&
                                                        isDefined(
                                                            document.activeElement
                                                        ) &&
                                                        isDefined(
                                                            document.activeElement?.getAttribute(
                                                                'data-cardname'
                                                            )
                                                        )
                                                            ? 'tricked'
                                                            : '')
                                                    }
                                                >
                                                    <div
                                                        class={
                                                            'thfSurveyReport-row thfSurveyReport-rowHeader'
                                                        }
                                                    >
                                                        {props.medicalStudyCards.map(
                                                            (card) => (
                                                                <div
                                                                    key={
                                                                        'thfSurveyReport-cell-header-' +
                                                                        card.name
                                                                    }
                                                                    class={
                                                                        'thfSurveyReport-cell thfSurveyReport-cellHeader ' +
                                                                        (isDefined(
                                                                            card.shortTitle
                                                                        ) &&
                                                                        card.shortTitle.split(
                                                                            ' '
                                                                        )
                                                                            .length >
                                                                            1
                                                                            ? 'thfSurveyReport-cellHeaderSplitter '
                                                                            : '') +
                                                                        card.name
                                                                    }
                                                                >
                                                                    <font class="thfSurveyReport-cellHeader-cardIdx">
                                                                        {
                                                                            card.cardIdx
                                                                        }
                                                                    </font>
                                                                    <p>
                                                                        {isDefined(
                                                                            card.shortTitle
                                                                        )
                                                                            ? card.shortTitle.split(
                                                                                  ' '
                                                                              )[0]
                                                                            : ''}
                                                                        <font>
                                                                            {isDefined(
                                                                                card.shortTitle
                                                                            ) &&
                                                                            card.shortTitle.split(
                                                                                ' '
                                                                            )
                                                                                .length >
                                                                                1
                                                                                ? card.shortTitle.split(
                                                                                      ' '
                                                                                  )[1]
                                                                                : ''}
                                                                        </font>
                                                                    </p>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div
                                                        key={
                                                            'thfSurveyReport-cells-' +
                                                            (isDefined(
                                                                props.medicalStudyAnswers
                                                            )
                                                                ? props
                                                                      .medicalStudyAnswers
                                                                      .length
                                                                : '')
                                                        }
                                                        class="thfSurveyReport-row"
                                                    >
                                                        {props.medicalStudyAnswers.map(
                                                            (
                                                                answer,
                                                                answeridx
                                                            ) => (
                                                                <dummy
                                                                    key={
                                                                        'thfSurveyReport-cells-wrapper-' +
                                                                        '-' +
                                                                        answer.date +
                                                                        '-' +
                                                                        answer.id
                                                                    }
                                                                >
                                                                    <div
                                                                        key={
                                                                            'thfSurveyReport-cells-' +
                                                                            '-' +
                                                                            answer.date +
                                                                            '-' +
                                                                            answer.id
                                                                        }
                                                                        class={
                                                                            'thfSurveyReport-cellRow ' +
                                                                            (!isDefined(
                                                                                answer.expiration
                                                                            ) ||
                                                                            !Array.isArray(
                                                                                answer.values
                                                                            ) ||
                                                                            (isDefined(
                                                                                answer.expiration
                                                                            ) &&
                                                                                getDiffDate(
                                                                                    new Date(),
                                                                                    createUTCDate(
                                                                                        getDateObjFromYMD(
                                                                                            answer.expiration
                                                                                        )
                                                                                            .date
                                                                                    )
                                                                                ) <=
                                                                                    -1)
                                                                                ? ''
                                                                                : 'thfSurveyReport-cellRow-transparent')
                                                                        }
                                                                    >
                                                                        {props.medicalStudyCards.map(
                                                                            (
                                                                                card,
                                                                                idx
                                                                            ) => (
                                                                                <div
                                                                                    id={
                                                                                        isDefined(
                                                                                            answer.alerts.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.evaluationName ===
                                                                                                    card.name
                                                                                            )[0]
                                                                                        )
                                                                                            ? answer.id +
                                                                                              '-' +
                                                                                              idx
                                                                                            : ''
                                                                                    }
                                                                                    tabindex="0"
                                                                                    data-index={
                                                                                        idx
                                                                                    }
                                                                                    key={
                                                                                        'thfSurveyReport-cells-cell-' +
                                                                                        card.name
                                                                                    }
                                                                                    onclick={() => {
                                                                                        if (
                                                                                            isDefined(
                                                                                                answer.id
                                                                                            )
                                                                                        ) {
                                                                                            props._setState(
                                                                                                {
                                                                                                    key: 'popupDisplayed',
                                                                                                    value:
                                                                                                        card.name +
                                                                                                        answer.date +
                                                                                                        answer.id,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                    onblur={() => {
                                                                                        props._setState(
                                                                                            {
                                                                                                key: 'popupDisplayed',
                                                                                                value: '',
                                                                                            }
                                                                                        )
                                                                                        document?.activeElement?.blur()
                                                                                    }}
                                                                                    data-cardname={
                                                                                        card.name
                                                                                    }
                                                                                    data-answerdate={
                                                                                        answer.date
                                                                                    }
                                                                                    data-answerid={
                                                                                        answer.id
                                                                                    }
                                                                                    class={
                                                                                        'cellWrapper-' +
                                                                                        (isDefined(
                                                                                            card.labels
                                                                                        )
                                                                                            ? card
                                                                                                  .labels
                                                                                                  .length
                                                                                            : '') +
                                                                                        ' ' +
                                                                                        (isDefined(
                                                                                            answer.alerts.filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f.evaluationName ===
                                                                                                    card.name
                                                                                            )[0]
                                                                                        )
                                                                                            ? 'thfSurveyReport-nextCell'
                                                                                            : '') +
                                                                                        ' thfSurveyReport-cell ' +
                                                                                        (!Array.isArray(
                                                                                            answer.values
                                                                                        ) &&
                                                                                        !isDefined(
                                                                                            answer.alertClosedBy
                                                                                        ) &&
                                                                                        !isDefined(
                                                                                            answer.action
                                                                                        )
                                                                                            ? 'thfSurveyReport-cellAlertLine'
                                                                                            : '')
                                                                                    }
                                                                                >
                                                                                    {card.labels.map(
                                                                                        (
                                                                                            label
                                                                                        ) => (
                                                                                            <p
                                                                                                key={
                                                                                                    'thfSurveyReport-cells-cell-content-' +
                                                                                                    answer.date +
                                                                                                    '-' +
                                                                                                    label.tag +
                                                                                                    '-' +
                                                                                                    answer.id
                                                                                                }
                                                                                                class={
                                                                                                    'thfSurveyReport-cell thfSurveyReport-cell-' +
                                                                                                    (isDefined(
                                                                                                        answer.alerts.filter(
                                                                                                            (
                                                                                                                lrt
                                                                                                            ) =>
                                                                                                                lrt.evaluationName ===
                                                                                                                card.name
                                                                                                        )[0]
                                                                                                    )
                                                                                                        ? answer.alerts.filter(
                                                                                                              (
                                                                                                                  lrt
                                                                                                              ) =>
                                                                                                                  lrt.evaluationName ===
                                                                                                                  card.name
                                                                                                          )[0]
                                                                                                              .color
                                                                                                        : isDefined(
                                                                                                                answer
                                                                                                                    .values[
                                                                                                                    card
                                                                                                                        .name
                                                                                                                ]
                                                                                                            )
                                                                                                          ? isDefined(
                                                                                                                label.tag
                                                                                                            ) &&
                                                                                                            isDefined(
                                                                                                                answer
                                                                                                                    .values[
                                                                                                                    card
                                                                                                                        .name
                                                                                                                ][
                                                                                                                    label
                                                                                                                        .tag
                                                                                                                ]
                                                                                                            )
                                                                                                              ? answer
                                                                                                                    .values[
                                                                                                                    card
                                                                                                                        .name
                                                                                                                ][
                                                                                                                    label
                                                                                                                        .tag
                                                                                                                ]
                                                                                                                    .color ||
                                                                                                                ''
                                                                                                              : 'noValue'
                                                                                                          : 'noValue') +
                                                                                                    ' thfSurveyReport-cellAlert-' +
                                                                                                    (isDefined(
                                                                                                        answer.alerts.filter(
                                                                                                            (
                                                                                                                f
                                                                                                            ) =>
                                                                                                                f.evaluationName ===
                                                                                                                card.name
                                                                                                        )[0]
                                                                                                    )
                                                                                                        ? 'isAlert'
                                                                                                        : 'isNotAlert') +
                                                                                                    ' ' +
                                                                                                    (props.popupDisplayed ===
                                                                                                    card.name +
                                                                                                        answer.date +
                                                                                                        answer.id
                                                                                                        ? 'selected'
                                                                                                        : '')
                                                                                                }
                                                                                            >
                                                                                                {isDefined(
                                                                                                    answer
                                                                                                        .values[
                                                                                                        card
                                                                                                            .name
                                                                                                    ]
                                                                                                )
                                                                                                    ? isDefined(
                                                                                                          label.tag
                                                                                                      )
                                                                                                        ? card.type ===
                                                                                                          'Textarea'
                                                                                                            ? 'Aa'
                                                                                                            : isDefined(
                                                                                                                    answer
                                                                                                                        .values[
                                                                                                                        card
                                                                                                                            .name
                                                                                                                    ][
                                                                                                                        label
                                                                                                                            .tag
                                                                                                                    ]
                                                                                                                )
                                                                                                              ? answer
                                                                                                                    .values[
                                                                                                                    card
                                                                                                                        .name
                                                                                                                ][
                                                                                                                    label
                                                                                                                        .tag
                                                                                                                ]
                                                                                                                    .value
                                                                                                              : '-'
                                                                                                        : '-'
                                                                                                    : '-'}
                                                                                            </p>
                                                                                        )
                                                                                    )}
                                                                                    {props.popupDisplayed ===
                                                                                        card.name +
                                                                                            answer.date +
                                                                                            answer.id && (
                                                                                        <div
                                                                                            key={
                                                                                                'thfSurveyReport-cells-cell-popup-' +
                                                                                                card.name
                                                                                            }
                                                                                            class="thfSurveyReport-cellPopup"
                                                                                        >
                                                                                            <div
                                                                                                class={
                                                                                                    'thfSurveyReport-cellPopupHeader cellHeaderWrapper-' +
                                                                                                    (isDefined(
                                                                                                        card.labels
                                                                                                    )
                                                                                                        ? card
                                                                                                              .labels
                                                                                                              .length
                                                                                                        : '')
                                                                                                }
                                                                                            >
                                                                                                <p class="">
                                                                                                    {
                                                                                                        card.shortTitle
                                                                                                    }
                                                                                                </p>
                                                                                                {card.labels.map(
                                                                                                    (
                                                                                                        label
                                                                                                    ) => (
                                                                                                        <p
                                                                                                            key={
                                                                                                                'thfSurveyReport-cells-cell-content-recap-' +
                                                                                                                answer.date +
                                                                                                                '-' +
                                                                                                                label.tag +
                                                                                                                '-' +
                                                                                                                answer.id
                                                                                                            }
                                                                                                            class={
                                                                                                                'selected thfSurveyReport-cell thfSurveyReport-cellPopup thfSurveyReport-cell-' +
                                                                                                                (isDefined(
                                                                                                                    answer.alerts.filter(
                                                                                                                        (
                                                                                                                            lrt
                                                                                                                        ) =>
                                                                                                                            lrt.evaluationName ===
                                                                                                                            card.name
                                                                                                                    )[0]
                                                                                                                )
                                                                                                                    ? answer.alerts.filter(
                                                                                                                          (
                                                                                                                              lrt
                                                                                                                          ) =>
                                                                                                                              lrt.evaluationName ===
                                                                                                                              card.name
                                                                                                                      )[0]
                                                                                                                          .color
                                                                                                                    : isDefined(
                                                                                                                            answer
                                                                                                                                .values[
                                                                                                                                card
                                                                                                                                    .name
                                                                                                                            ]
                                                                                                                        )
                                                                                                                      ? isDefined(
                                                                                                                            label.tag
                                                                                                                        ) &&
                                                                                                                        isDefined(
                                                                                                                            answer
                                                                                                                                .values[
                                                                                                                                card
                                                                                                                                    .name
                                                                                                                            ][
                                                                                                                                label
                                                                                                                                    .tag
                                                                                                                            ]
                                                                                                                        )
                                                                                                                          ? answer
                                                                                                                                .values[
                                                                                                                                card
                                                                                                                                    .name
                                                                                                                            ][
                                                                                                                                label
                                                                                                                                    .tag
                                                                                                                            ]
                                                                                                                                .color
                                                                                                                          : 'noValue'
                                                                                                                      : 'noValue') +
                                                                                                                ' thfSurveyReport-cellAlert-' +
                                                                                                                (isDefined(
                                                                                                                    answer.alerts.filter(
                                                                                                                        (
                                                                                                                            f
                                                                                                                        ) =>
                                                                                                                            f.evaluationName ===
                                                                                                                            card.name
                                                                                                                    )[0]
                                                                                                                )
                                                                                                                    ? 'isAlert'
                                                                                                                    : 'isNotAlert')
                                                                                                            }
                                                                                                        >
                                                                                                            {isDefined(
                                                                                                                answer
                                                                                                                    .values[
                                                                                                                    card
                                                                                                                        .name
                                                                                                                ]
                                                                                                            )
                                                                                                                ? isDefined(
                                                                                                                      label.tag
                                                                                                                  ) &&
                                                                                                                  isDefined(
                                                                                                                      answer
                                                                                                                          .values[
                                                                                                                          card
                                                                                                                              .name
                                                                                                                      ][
                                                                                                                          label
                                                                                                                              .tag
                                                                                                                      ]
                                                                                                                  )
                                                                                                                    ? card.type ===
                                                                                                                      'Textarea'
                                                                                                                        ? 'Aa'
                                                                                                                        : answer
                                                                                                                              .values[
                                                                                                                              card
                                                                                                                                  .name
                                                                                                                          ][
                                                                                                                              label
                                                                                                                                  .tag
                                                                                                                          ]
                                                                                                                              .value
                                                                                                                    : '-'
                                                                                                                : '-'}
                                                                                                        </p>
                                                                                                    )
                                                                                                )}
                                                                                            </div>
                                                                                            <div class="thfSurveyReport-cellPopupContent">
                                                                                                <p class="btzTitle">
                                                                                                    {t(
                                                                                                        'Question'
                                                                                                    ) +
                                                                                                        ' :'}
                                                                                                </p>
                                                                                                <p
                                                                                                    class="btzSubTitle"
                                                                                                    style="margin-bottom: 15px;max-height: 125px;overflow: auto;"
                                                                                                >
                                                                                                    {
                                                                                                        card.question
                                                                                                    }
                                                                                                </p>
                                                                                                {isDefined(
                                                                                                    card.labels.filter(
                                                                                                        (
                                                                                                            f
                                                                                                        ) =>
                                                                                                            isDefined(
                                                                                                                answer
                                                                                                                    .values[
                                                                                                                    card
                                                                                                                        .name
                                                                                                                ]
                                                                                                            ) &&
                                                                                                            isDefined(
                                                                                                                answer
                                                                                                                    .values[
                                                                                                                    card
                                                                                                                        .name
                                                                                                                ][
                                                                                                                    f
                                                                                                                        .tag
                                                                                                                ]
                                                                                                                    .value
                                                                                                            ) &&
                                                                                                            isDefined(
                                                                                                                f
                                                                                                                    .values[
                                                                                                                    answer
                                                                                                                        .values[
                                                                                                                        card
                                                                                                                            .name
                                                                                                                    ][
                                                                                                                        f
                                                                                                                            .tag
                                                                                                                    ]
                                                                                                                        .value
                                                                                                                ]
                                                                                                            )
                                                                                                    )[0]
                                                                                                ) ? (
                                                                                                    <dummy>
                                                                                                        <p class="btzTitle">
                                                                                                            {t(
                                                                                                                'Réponse'
                                                                                                            ) +
                                                                                                                ' :'}
                                                                                                        </p>
                                                                                                        {card.labels
                                                                                                            .filter(
                                                                                                                (
                                                                                                                    f
                                                                                                                ) =>
                                                                                                                    isDefined(
                                                                                                                        answer
                                                                                                                            .values[
                                                                                                                            card
                                                                                                                                .name
                                                                                                                        ]
                                                                                                                    ) &&
                                                                                                                    isDefined(
                                                                                                                        answer
                                                                                                                            .values[
                                                                                                                            card
                                                                                                                                .name
                                                                                                                        ][
                                                                                                                            f
                                                                                                                                .tag
                                                                                                                        ]
                                                                                                                            .value
                                                                                                                    ) &&
                                                                                                                    isDefined(
                                                                                                                        f
                                                                                                                            .values[
                                                                                                                            answer
                                                                                                                                .values[
                                                                                                                                card
                                                                                                                                    .name
                                                                                                                            ][
                                                                                                                                f
                                                                                                                                    .tag
                                                                                                                            ]
                                                                                                                                .value
                                                                                                                        ]
                                                                                                                    )
                                                                                                            )
                                                                                                            .map(
                                                                                                                (
                                                                                                                    label
                                                                                                                ) => (
                                                                                                                    <p
                                                                                                                        key={
                                                                                                                            'thfSurveyReport-cells-cell-content-subtite-' +
                                                                                                                            label.tag
                                                                                                                        }
                                                                                                                        style="max-height: 210px;overflow: auto;"
                                                                                                                        class={
                                                                                                                            'btzSubTitle ' +
                                                                                                                            (isDefined(
                                                                                                                                answer.alerts.filter(
                                                                                                                                    (
                                                                                                                                        f
                                                                                                                                    ) =>
                                                                                                                                        f.evaluationName ===
                                                                                                                                        card.name
                                                                                                                                )[0]
                                                                                                                            )
                                                                                                                                ? 'btzSubTitleBold'
                                                                                                                                : '')
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            label
                                                                                                                                .values[
                                                                                                                                answer
                                                                                                                                    .values[
                                                                                                                                    card
                                                                                                                                        .name
                                                                                                                                ][
                                                                                                                                    label
                                                                                                                                        .tag
                                                                                                                                ]
                                                                                                                                    .value
                                                                                                                            ]
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                )
                                                                                                            )}
                                                                                                    </dummy>
                                                                                                ) : (
                                                                                                    <dummy>
                                                                                                        <p class="btzTitle">
                                                                                                            {t(
                                                                                                                'Réponse'
                                                                                                            ) +
                                                                                                                ' :'}
                                                                                                        </p>
                                                                                                        {card.labels
                                                                                                            .filter(
                                                                                                                (
                                                                                                                    f
                                                                                                                ) =>
                                                                                                                    isDefined(
                                                                                                                        answer
                                                                                                                            .values[
                                                                                                                            card
                                                                                                                                .name
                                                                                                                        ]
                                                                                                                    ) &&
                                                                                                                    isDefined(
                                                                                                                        answer
                                                                                                                            .values[
                                                                                                                            card
                                                                                                                                .name
                                                                                                                        ][
                                                                                                                            f
                                                                                                                                .tag
                                                                                                                        ]
                                                                                                                            .value
                                                                                                                    )
                                                                                                            )
                                                                                                            .map(
                                                                                                                (
                                                                                                                    label
                                                                                                                ) => (
                                                                                                                    <p
                                                                                                                        key={
                                                                                                                            'thfSurveyReport-cells-cell-content-subtite-' +
                                                                                                                            label.tag
                                                                                                                        }
                                                                                                                        style="max-height: 210px;overflow: auto;"
                                                                                                                        class={
                                                                                                                            'btzSubTitle ' +
                                                                                                                            (isDefined(
                                                                                                                                answer.alerts.filter(
                                                                                                                                    (
                                                                                                                                        f
                                                                                                                                    ) =>
                                                                                                                                        f.evaluationName ===
                                                                                                                                        card.name
                                                                                                                                )[0]
                                                                                                                            )
                                                                                                                                ? 'btzSubTitleBold'
                                                                                                                                : '')
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            answer
                                                                                                                                .values[
                                                                                                                                card
                                                                                                                                    .name
                                                                                                                            ][
                                                                                                                                label
                                                                                                                                    .tag
                                                                                                                            ]
                                                                                                                                .value
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                )
                                                                                                            )}
                                                                                                    </dummy>
                                                                                                )}
                                                                                            </div>
                                                                                            <div class="thfSurveyReport-cellPopupFooter">
                                                                                                {answer.alertTotal >
                                                                                                    0 &&
                                                                                                    isDefined(
                                                                                                        answer.alerts.filter(
                                                                                                            (
                                                                                                                f
                                                                                                            ) =>
                                                                                                                f.evaluationName ===
                                                                                                                card.name
                                                                                                        )[0]
                                                                                                    ) && (
                                                                                                        <p
                                                                                                            class="thfSurveyReport-cellPopupPagination thfSurveyReport-cellPopupPaginationLeft"
                                                                                                            onclick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                props.switchDisplayedAlert(
                                                                                                                    {
                                                                                                                        e: e,
                                                                                                                        side: 'left',
                                                                                                                    }
                                                                                                                )
                                                                                                            }}
                                                                                                        ></p>
                                                                                                    )}
                                                                                                <Button
                                                                                                    cancel
                                                                                                    flat
                                                                                                >
                                                                                                    {t(
                                                                                                        'Fermer'
                                                                                                    )}
                                                                                                </Button>
                                                                                                {answer.alertTotal >
                                                                                                    0 &&
                                                                                                    isDefined(
                                                                                                        answer.alerts.filter(
                                                                                                            (
                                                                                                                f
                                                                                                            ) =>
                                                                                                                f.evaluationName ===
                                                                                                                card.name
                                                                                                        )[0]
                                                                                                    ) && (
                                                                                                        <p
                                                                                                            class="thfSurveyReport-cellPopupPagination thfSurveyReport-cellPopupPaginationRight"
                                                                                                            onclick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                props.switchDisplayedAlert(
                                                                                                                    {
                                                                                                                        e: e,
                                                                                                                        side: 'right',
                                                                                                                    }
                                                                                                                )
                                                                                                            }}
                                                                                                        ></p>
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                    {!isDefined(
                                                                        answer.expiration
                                                                    ) ||
                                                                    !Array.isArray(
                                                                        answer.values
                                                                    ) ||
                                                                    (isDefined(
                                                                        answer.expiration
                                                                    ) &&
                                                                        getDiffDate(
                                                                            new Date(),
                                                                            createUTCDate(
                                                                                getDateObjFromYMD(
                                                                                    answer.expiration
                                                                                )
                                                                                    .date
                                                                            )
                                                                        ) <=
                                                                            -1) ? (
                                                                        <dummy></dummy>
                                                                    ) : (
                                                                        <dummy class="thfSurveyReport-cells-cell-content-waitingWrapper">
                                                                            <p class="thfSurveyReport-cells-cell-content-waiting">
                                                                                {t(
                                                                                    'En attente de réponse du patient'
                                                                                )}
                                                                                {/*<span key={'case-answer-' + answeridx + '-survey'} class='thfTable-cellAnswerSurvey' style='display: inline-block;margin: 0 0 0 5px;cursor: pointer;' onclick={() => { props.defineSurveyUrl({answer: answer, answeridx: answeridx}); }}>
                                                                            <Modal key={'modal-do-survey-' + answeridx} customClass='modal-central' cancelAction={() => { props.setModaldisplayed(''); props._setState({key: 'surveyUrl', value: null}); }} modalDisplayed={(isDefined(props.surveyUrl) && isDefined(props.modaldisplayed) && props.modaldisplayed.indexOf('dosurvey-' + answeridx) > -1)} content={<ModalViewDoSurvey displayed={'dosurvey-' + answeridx} cancelAction={() => { props.setModaldisplayed(''); props._setState({key: 'surveyUrl', value: null}); }} {...props} loaded={true}></ModalViewDoSurvey>}>
                                                                                {' - ' + t('Compléter le questionnaire')}
                                                                            </Modal>
                                                                        </span>*/}
                                                                            </p>
                                                                        </dummy>
                                                                    )}
                                                                </dummy>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="thfSurveyReport-cellColAcquit">
                                                    <div
                                                        class="thfSurveyReport-topControls thfSurveyReport-topControlRight"
                                                        onclick={() =>
                                                            props.scrollBoard({
                                                                side: 'right',
                                                            })
                                                        }
                                                    >
                                                        <p class=""></p>
                                                    </div>
                                                    {props.medicalStudyAnswers.map(
                                                        (answer, idx) => (
                                                            <div
                                                                key={
                                                                    'thfSurveyReport-cells-cell-acquit-' +
                                                                    answer.date +
                                                                    '-' +
                                                                    idx
                                                                }
                                                                class={
                                                                    'thfSurveyReport-cell thfSurveyReport-cellAcquit'
                                                                }
                                                            >
                                                                {isDefined(
                                                                    answer.id
                                                                ) && (
                                                                    <div
                                                                        key={
                                                                            'modal-patient-acquit'
                                                                        }
                                                                        tabindex="0"
                                                                        class="thfSurveyReport-cell thfSurveyReport-cellAcquited"
                                                                    >
                                                                        <div
                                                                            onclick={() => {
                                                                                props.onAcquitSurvey(
                                                                                    answer.id
                                                                                )
                                                                            }}
                                                                        >
                                                                            {isDefined(
                                                                                answer.alertClosedBy
                                                                            ) ||
                                                                            isDefined(
                                                                                answer.action
                                                                            ) ? (
                                                                                <img
                                                                                    class=""
                                                                                    src={
                                                                                        icoAcquit
                                                                                    }
                                                                                    srcset={`${icoAcquitx2} 2x`}
                                                                                    alt=""
                                                                                />
                                                                            ) : (
                                                                                <div
                                                                                    class={
                                                                                        'thfSurveyReport-toacquit ' +
                                                                                        (isDefined(
                                                                                            answer.alerts
                                                                                        ) &&
                                                                                        answer
                                                                                            .alerts
                                                                                            .length ===
                                                                                            0
                                                                                            ? 'thfSurveyReport-toacquitNoAlert'
                                                                                            : '')
                                                                                    }
                                                                                ></div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div class="thfSurveyReport-cellColCommentCounts">
                                                    <div
                                                        class={
                                                            'thfSurveyReport-cell thfSurveyReport-cellAcquit'
                                                        }
                                                    ></div>
                                                    {props.medicalStudyAnswers.map(
                                                        (answer) => (
                                                            <div
                                                                class={
                                                                    'thfSurveyReport-cell thfSurveyReport-cellAcquit'
                                                                }
                                                            >
                                                                {answer.commentsCount >
                                                                    0 && (
                                                                    <dummy
                                                                        style="pointer: cursor;"
                                                                        onclick={() => {
                                                                            props.onAcquitSurvey(
                                                                                answer.id
                                                                            )
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                icoMessageOn
                                                                            }
                                                                            srcset={`${icoMessageOnx2} 2x`}
                                                                            alt=""
                                                                        />
                                                                        <p class="commentCount">
                                                                            {
                                                                                answer.commentsCount
                                                                            }
                                                                        </p>
                                                                    </dummy>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                class="thfEmptyTable"
                                                style="padding-top: 40px;min-height: auto;"
                                            >
                                                <img
                                                    class="emptyDraw"
                                                    src={imgNoPatient}
                                                    srcset={`${imgNoPatientx2} 2x`}
                                                    alt=""
                                                    style="max-width: 250px;margin: 0 0 30px 0;"
                                                />
                                                <p class="thfEmptyTable-thfEmptyTableTitle">
                                                    {t('Aucun questionnaire') +
                                                        ' ' +
                                                        props.medicalStudyEdited
                                                            .usualName +
                                                        ' ' +
                                                        t('complété')}
                                                </p>
                                                <p class="thfEmptyTable-thfEmptyTableSubTitle">
                                                    {t(
                                                        'Votre patient n’a pas encore complété ce questionnaire.'
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                        {props.thereAreNextPage === true && (
                                            <div
                                                class=""
                                                style="display: block; width: auto;max-width: 280px;margin: 25px auto;text-align: center;"
                                            >
                                                <Button
                                                    active
                                                    onclick={
                                                        props.loadNextSurvey
                                                    }
                                                >
                                                    <img
                                                        class="activated"
                                                        src={icoAdd}
                                                        srcset={`${icoAddx2} 2x`}
                                                        alt=""
                                                    />
                                                    {t(
                                                        'Afficher plus de rapports'
                                                    )}
                                                </Button>
                                            </div>
                                        )}
                                    </dummy>
                                )}
                            {props.modalDisplayed &&
                                isDefined(props.currentAnswer) && (
                                    <Modal
                                        customClass="modal-central modal-acquitsurvey"
                                        cancelAction={
                                            props.onCancelAcquitSurvey
                                        }
                                        modalDisplayed={
                                            props.modalDisplayed &&
                                            props.modalEditing.indexOf(
                                                'acquit-' +
                                                    props.currentAnswer.id
                                            ) > -1
                                        }
                                        content={
                                            <ModalViewAcquit
                                                displayed={'acquit'}
                                                {...props}
                                                editedPatient={
                                                    props.editedPatient
                                                }
                                                answer={props.currentAnswer}
                                                retrieveInput={
                                                    props.retrieveInput
                                                }
                                                onSubmit={() => {
                                                    props.acquitSurvey(
                                                        props.currentAnswer.id
                                                    )
                                                }}
                                                cancelAction={
                                                    props.onCancelAcquitSurvey
                                                }
                                                commentCallback={
                                                    props.commentCallback
                                                }
                                                updateCurrentActionState={
                                                    props.updateCurrentActionState
                                                }
                                                exportSurvey={
                                                    props.exportSurvey
                                                }
                                                loaded={true}
                                            ></ModalViewAcquit>
                                        }
                                    ></Modal>
                                )}
                            {props.surveyPreviewDisplayed && (
                                <Modal
                                    key={'modal-medical-confirm'}
                                    customClass="modal-central modal-previewsurvey"
                                    cancelAction={() => {
                                        props._setState({
                                            key: 'surveyPreviewDisplayed',
                                            value: false,
                                        })
                                    }}
                                    modalDisplayed={true}
                                    content={
                                        <ModalViewSurveyPreview
                                            displayed={'medicalconfirm'}
                                            {...props}
                                            cancelAction={() => {
                                                props._setState({
                                                    key: 'surveyPreviewDisplayed',
                                                    value: false,
                                                })
                                            }}
                                            loaded={true}
                                        ></ModalViewSurveyPreview>
                                    }
                                ></Modal>
                            )}
                            {props.switchPhase && (
                                <Modal
                                    key={'modal-medical-confirm'}
                                    customClass="modal-central"
                                    cancelAction={() => {
                                        props.cancelPhaseSwitching()
                                    }}
                                    modalDisplayed={true}
                                    content={
                                        <ModalViewSwitchPhase
                                            displayed={'medicalconfirm'}
                                            {...props}
                                            cancelAction={() => {
                                                props.cancelPhaseSwitching()
                                            }}
                                            loaded={true}
                                        ></ModalViewSwitchPhase>
                                    }
                                ></Modal>
                            )}
                        </Tile>
                    )}
                </dummy>
            </Enter>
        </Card.Header>
    </Card>
)
