import { t } from 'i18next'
import { h } from 'hyperapp'
import { Form, FormInput } from '@app/elements'
import { isDefined, getReadableDate, createUTCDate } from '@app/core'

import * as constants from '@app/constants'

export const TableViewUrine = (props, actions) => (
    <table
        key={'thfTableUrine'}
        class="thfTable thfTableUrine"
        style="margin-top: 0px;"
    >
        <thead>
            <tr>
                <th class="thfTable-cellDate">{t('Date')}</th>
                <th class="thfTable-cellValue">{t('Couleur')}</th>
                <th class="thfTable-cellReason">{t('pH')}</th>
                <th class="thfTable-cellAction">{t('Volume')}</th>
                <th class="thfTable-cellAction">{t('Actions')}</th>
                {/*<th class='thfTable-cellAction'>{t('Densité')}</th>*/}
            </tr>
        </thead>
        {isDefined(props.tableDatasReversed) &&
        props.tableDatasReversed.length > 0 ? (
            <tbody>
                {props.tableDatasReversed.map((item, idx) => (
                    <tr>
                        <td class="thfTable-cellDate">
                            {getReadableDate(
                                createUTCDate(item.date.date),
                                'DD MMMM YYYY HH:mm'
                            )}
                        </td>
                        <td
                            class={
                                'thfTable-cellColor thfTable-cellUrineColor thfTable-cellUrineColor-' +
                                item.color
                            }
                        >
                            {item.color === 'NODATA' || !isDefined(item.color)
                                ? 'n.c'
                                : item.color + '/8'}
                        </td>
                        <td class="thfTable-cellPh">
                            {item.ph === 'NODATA' || !isDefined(item.ph)
                                ? '-'
                                : item.ph}
                        </td>
                        <td class="thfTable-cellVolume">
                            {item.volume === 'NODATA' || !isDefined(item.volume)
                                ? '-'
                                : item.volume + ' ' + t('cl')}
                        </td>
                        <td class="thfTable-cellAction">
                            {isDefined(
                                constants.custo.telesuiviActionsAlert.filter(
                                    (f) => +f.key === item.status
                                )[0]
                            ) ? (
                                <Form>
                                    <Form.Group>
                                        <FormInput
                                            key={'input-action-' + idx}
                                            type="dropdown"
                                            id={item.id}
                                            name="action"
                                            value={
                                                constants.custo.telesuiviActionsAlert.filter(
                                                    (f) =>
                                                        +f.key === item.status
                                                )[0].key
                                            }
                                            list={
                                                constants.custo
                                                    .telesuiviActionsAlert
                                            }
                                            initialCalls={false}
                                            validation={true}
                                            withSearch={false}
                                            textedArea={false}
                                            oninputcb={
                                                props.retrieveInputAction
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Form>
                            ) : (
                                <p>{'-'}</p>
                            )}
                        </td>
                        {/*<td class='thfTable-cellValue'>{(((item.density === 'NODATA') || (item.density === '')) ? 'N.C' : item.density)}</td>*/}
                    </tr>
                ))}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colspan="5" class="">
                        <p class="" style="padding: 50px 0;text-align: center">
                            {t('aucune mesure sur cette période')}
                        </p>
                    </td>
                </tr>
            </tbody>
        )}
    </table>
)
