import { t } from 'i18next'
import { Medical, Admin } from '@app/api'
import {
    allRoutes,
    addCSRFToken,
    isDefined,
    getUser,
    coreFn,
    parseJsonObjectToString,
} from '@app/core'
import { settings } from '@app/core/settings'

import * as constants from '@app/constants'

const { APP_IC_MODULE } = settings

/**
 * @module AlertscurveListing
 * @description Display listing of specific IC alerts, related to activated specific monitoring IC module
 */

export default {
    initAction: function () {
        let AlertscurveListing = {
            onComponentCreate: (props) => (state, actions) => {
                let ICMesure = APP_IC_MODULE || ''
                actions._setState({ key: 'ICMesure', value: ICMesure })

                let thenn = () => {
                    let then = (loggedUser) => {
                        if (
                            isDefined(loggedUser.refHosp) &&
                            isDefined(loggedUser.refHosp.customerModules)
                        ) {
                            let enableSurvey =
                                loggedUser.refHosp.customerModules.studySettings
                                    .filter(
                                        (f) => f.status && f.name !== 'palatine'
                                    )
                                    .map((m) => {
                                        return m.name
                                    })
                            actions._setState({
                                key: 'enableSurvey',
                                value: enableSurvey,
                            })
                            // get the study defined by default
                            let defaultSurvey =
                                loggedUser.refHosp.customerModules.studySettings.filter(
                                    (f) =>
                                        f.status &&
                                        f.default &&
                                        f.name !== 'palatine'
                                )[0]
                            if (!isDefined(defaultSurvey)) {
                                // if no study defined by default, get first study activated
                                defaultSurvey =
                                    loggedUser.refHosp.customerModules.studySettings.filter(
                                        (f) => f.status && f.name !== 'palatine'
                                    )[0]
                            }
                            if (!isDefined(defaultSurvey)) {
                                // if no study defined by default, get first study activated
                                var defaultSurveyTemp =
                                    constants.custo.studies.filter(
                                        (f) => enableSurvey.indexOf(f.key) > -1
                                    )[0]
                                defaultSurvey =
                                    loggedUser.refHosp.customerModules.studySettings.filter(
                                        (f) => f.name === defaultSurveyTemp.key
                                    )[0]
                            }
                            if (isDefined(defaultSurvey)) {
                                actions._setState({
                                    key: 'selectedSurvey',
                                    value: defaultSurvey.name,
                                })
                            }
                        }
                        actions._setState({ key: 'doctor', value: loggedUser })
                        actions.getPage()
                    }
                    getUser(then)
                }

                if (JSON.parse(sessionStorage.getItem('infos')) === null) {
                    Admin.getInfo().then((res) => {
                        sessionStorage.setItem(
                            'infos',
                            parseJsonObjectToString(res.data)
                        )
                        actions._setState({
                            key: 'infos',
                            value: JSON.parse(sessionStorage.getItem('infos')),
                        })
                        thenn()
                    })
                } else {
                    actions._setState({
                        key: 'infos',
                        value: JSON.parse(sessionStorage.getItem('infos')),
                    })
                    thenn()
                }
            },
            /**
             * Redirect when user click on a table row
             * @function AlertscurveListing:redirectToPatient
             * @param {string} patientid - Id of patient
             * @param {ymd} date - ymd date of alert
             * @param {string} crypt - crypt of alert
             * @param {string} period - period of alert (week, month)
             */
            redirectToPatient:
                ({ patient, moment }) =>
                (state, actions) => {
                    let trgid = ''
                    if (isDefined(moment) && isDefined(moment.surveyId)) {
                        trgid = '&trgid=' + moment.surveyId
                    }
                    window.location.href = addCSRFToken(
                        allRoutes['private.patient.edit'].pathname.replace(
                            ':id',
                            patient.userId
                        ) +
                            '?sp=study' +
                            trgid +
                            '&surveyName=' +
                            patient.name
                    )
                },
            /**
             * Parse a value to get a human readable string
             * @param {object} el - object to update
             * @param {object} item - Item, containing value to parse
             * @function AlertscurveListing:getReadableValue
             */
            getReadableValue:
                ({ el, item }) =>
                (state, actions) => {
                    let parsedValue = ''
                    if (item.value === 'NODATA') {
                        parsedValue = 'N.C'
                    } else {
                        parsedValue = item.value
                    }
                    el.innerHTML = parsedValue
                },
            /**
             * Parse a dataType to get a human readable string
             * @param {object} el - object to update
             * @param {object} item - Item, containing dataType to parse
             * @function AlertscurveListing:getReadableDataType
             */
            getReadableDataType:
                ({ el, item }) =>
                (state, actions) => {
                    let parsedValue = constants.custo.telesuiviSettings.filter(
                        (f) =>
                            f.crypt.toUpperCase() ===
                            item.dataType.split('_')[0].toUpperCase()
                    )[0].value
                    el.innerHTML = parsedValue
                },
            /**
             * Parse a triggerType to get a human readable string
             * @param {object} el - object to update
             * @param {object} item - Item, containing triggerType to parse
             * @function AlertscurveListing:getReadableTriggerType
             */
            getReadableTriggerType:
                ({ el, item }) =>
                (state, actions) => {
                    let parsedValue = ''
                    if (item.triggerType === 'NODATA') {
                        parsedValue = t('1 mesure est manquante')
                    } else if (item.triggerType === 'INCREASE') {
                        parsedValue = t('1 mesure est en augmentation')
                    } else {
                        parsedValue = item.triggerType
                    }
                    el.innerHTML = parsedValue
                },
            /**
             * Sort listing
             * @param {string} sortby - string to sort by
             * @function AlertscurveListing:getReadableTriggerType
             */
            onSortList: (sortby) => (state, actions) => {
                let sorting = state.sort,
                    newSorting = { sort: {} }
                if (!isDefined(sorting.sort)) {
                    sorting.sort = {}
                }
                if (isDefined(sorting.sort[sortby])) {
                    if (sorting.sort[sortby] === 'ASC') {
                        newSorting.sort[sortby] = 'DESC'
                    } else {
                        newSorting.sort[sortby] = 'ASC'
                    }
                } else {
                    newSorting.sort[sortby] = 'ASC'
                }
                actions._setState({ key: 'sort', value: newSorting })
                actions.getPage()
            },
            /**
             * Sort listing (by survey)
             * @param {string} survey - Survey to selecte
             * @function AlertscurveListing:updateSelectedSurvey
             */
            updateSelectedSurvey: (survey) => (state, actions) => {
                actions._setState({ key: 'selectedSurvey', value: survey })
                actions.getPage()
            },
            /**
             * Update listing page following current page variable
             * @function AlertscurveListing:getPage
             */
            getPage: () => (state, actions) => {
                let dataAll = {
                    name: state.selectedSurvey,
                    sort: state.sort.sort,
                }
                if (state.selectedDate !== 'null') {
                    dataAll.period = state.selectedDate
                }
                if (state.selectedStatus !== 'null') {
                    if (state.selectedStatus === 'nodata') {
                        dataAll['with-alerts'] = ['NODATA']
                    } else if (state.selectedStatus === 'alerts') {
                        dataAll['has-alert'] = true
                    }
                }
                Medical.getMomentsUnderAlert(state.page, dataAll).then(
                    (alerts) => {
                        let parsedAlert = alerts.data,
                            totalAlerts = 0
                        if (isDefined(parsedAlert.infos)) {
                            totalAlerts = parsedAlert.infos.count
                        }
                        if (isDefined(parsedAlert.result)) {
                            parsedAlert = parsedAlert.result
                        }
                        //
                        for (let pAlert in parsedAlert) {
                            for (let moment in parsedAlert[pAlert].moments) {
                                parsedAlert[pAlert].moments[moment] =
                                    Object.values(
                                        parsedAlert[pAlert].moments[moment]
                                    ).flat()
                            }
                            parsedAlert[pAlert].moments = Object.values(
                                parsedAlert[pAlert].moments
                            ).flat()
                        }
                        actions._setState({ key: 'alerts', value: parsedAlert })
                        actions._setState({
                            key: 'totalAlerts',
                            value: totalAlerts,
                        })
                    }
                )
            },
            /**
             * Change current page, then call [getPage]{@link module:AlertscurveListing~AlertscurveListing:getPage})
             * @param {int} page - Page so select
             * @function AlertscurveListing:targetPage
             */
            targetPage: (page) => (state, actions) => {
                actions._setState({ key: 'page', value: page })
                actions.getPage(page)
            },
            /**
             * Increments current page, then call [getPage]{@link module:AlertscurveListing~AlertscurveListing:getPage})
             * @function AlertscurveListing:nextPage
             */
            nextPage: () => (state, actions) => {
                actions._setState({ key: 'page', value: state.page + 1 })
                actions.getPage()
            },
            /**
             * Decrement current page, then call [getPage]{@link module:AlertscurveListing~AlertscurveListing:getPage})
             * @function AlertscurveListing:prevPage
             */
            prevPage: () => (state, actions) => {
                actions._setState({ key: 'page', value: state.page - 1 })
                actions.getPage()
            },
            /**
             * Update alert mode, then call [getPage]{@link module:AlertscurveListing~AlertscurveListing:getPage})
             * @function AlertscurveListing:switchAlertMode
             * @param {string} totreat - New alert mode
             */
            switchAlertMode: (totreat) => (state, actions) => {
                actions._setToTreatAlert(totreat)
                actions._setState({ key: 'toTreatAlert', value: totreat })
                actions.getPage()
            },
            /**
             * Parse a patient lname and fname to get a human readable string
             * @function AlertscurveListing:getPatientName
             * @param {object} el - object to update
             * @param {object} patient - Patient, containing patient lname and fname to parse
             */
            getPatientName:
                ({ el, patient }) =>
                (state, actions) => {
                    let patientLName = patient.lastName.split(' ')[0] || ''
                    let patientFName = patient.firstName.split(' ')[1] || ''
                    let patientName =
                        patientLName.substring(0, 1).toUpperCase() +
                        patientFName.substring(0, 1).toUpperCase()
                    el.innerHTML = patientName
                },
            /**
             * Sort listing (by date)
             * @param {string} input - Value to select
             * @function AlertscurveListing:retrieveDateSort
             */
            retrieveDateSort: (input) => (state, actions) => {
                const { values } = input
                actions._setState({ key: 'selectedDate', value: values })
                actions.getPage()
            },
            /**
             * Sort listing (by status)
             * @param {string} input - Value to select
             * @function AlertscurveListing:retrieveSurveyStatus
             */
            retrieveSurveyStatus: (input) => (state, actions) => {
                const { values } = input
                actions._setState({ key: 'selectedStatus', value: values })
                actions.getPage()
            },
            /**
             * Display alert mini popup
             * @param {string} id - Id of target alert
             * * @param {string} color - Color of target alert
             * @function AlertscurveListing:retrieveAlertDetail
             */
            retrieveAlertDetail:
                ({ id, color }) =>
                (state, actions) => {
                    if (state.displayedPopup === id + '-' + color) {
                        return false
                    }
                    //
                    actions._setState({
                        key: 'displayedPopup',
                        value: id + '-' + color,
                    })
                    Medical.getMedicalColoredSurveyAlert(id).then((res) => {
                        actions._setState({
                            key: 'alertDetail',
                            value: res.data,
                        })
                    })
                },
        }
        AlertscurveListing = { ...coreFn, ...AlertscurveListing }
        return AlertscurveListing
    },
}
