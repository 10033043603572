import { t } from 'i18next'
import { h } from 'hyperapp'
import { Component } from '@app/utils'
import { isDefined } from '@app/core'
import { settings } from '@app/core/settings'

const { APP_FRONT_WEB_URL } = settings

const state = {
    //
}

const actions = {
    onComponentUpdate: (props) => (state, actions) => {
        //
    },
}

const view = (state, actions) => (props, children) => (
    <div class="thfMentionsWrapper-utilities">
        <p class="thfMentionsWrapper-utilityTitle">
            {t("Instructions d'utilisation :")}
        </p>
        <p class="thfMentionsWrapper-utilityText" style="display: inline;">
            {(props.forcedCustomer || props.support.title) +
                ' ' +
                t(
                    'est une web-application qui ne nécessite pas d’installation ni aucun paramétrage avant son utilisation. Le service est disponible directement sur le web à l’adresse url :'
                ) +
                ' '}
        </p>
        <a class="underlined" href={APP_FRONT_WEB_URL}>
            {APP_FRONT_WEB_URL}
        </a>
        <br />
        <p class="thfMentionsWrapper-utilityText">
            {t('Pour utiliser') +
                ' ' +
                (props.forcedCustomer || props.support.title) +
                ', ' +
                t(
                    'les professionnels de santé doivent disposer d’une connexion internet pour se connecter au service depuis leur ordinateur et d’une boîte mail pour recevoir les alertes.'
                )}
        </p>
        <br />
        <p class="thfMentionsWrapper-utilityText" style="margin-bottom: 30px;">
            {t(
                'Nous assurons le bon fonctionnement de l’ensemble du service à partir des versions des navigateurs listées ci-dessous :'
            )}
        </p>
        <p class="thfMentionsWrapper-utilityCompat">
            {t('• Google Chrome : 79')}
        </p>
        <p class="thfMentionsWrapper-utilityCompat">
            {t('• Mozilla Firefox : 80')}
        </p>
        <p
            class="thfMentionsWrapper-utilityCompat"
            style="margin-bottom: 20px;"
        >
            {t('• Microsoft Edge : 18')}
        </p>
        <p class="thfMentionsWrapper-utilityTitle">{t('Liens utiles :')}</p>
        {isDefined(props.support.modules.notice) && (
            <a
                class="underlined"
                style="display: block;"
                href={APP_FRONT_WEB_URL + props.support.modules.notice}
                target="_blank"
            >
                {t("Notice d'utilisation Soignant")}
            </a>
        )}
        {isDefined(props.support.modules.noticePatient) && (
            <a
                class="underlined"
                style="display: block;"
                href={APP_FRONT_WEB_URL + props.support.modules.noticePatient}
                target="_blank"
            >
                {t("Notice d'utilisation Patient")}
            </a>
        )}
        {isDefined(props.legalDocuments) && props.legalDocuments.length > 0 && (
            <dummy>
                {isDefined(
                    props.legalDocuments.filter((f) => f.type === 'terms')[0]
                ) && (
                    <a
                        class="underlined"
                        style="display: block;"
                        href={
                            props.legalDocuments.filter(
                                (f) => f.type === 'terms'
                            )[0].media.url
                        }
                        target="_blank"
                    >
                        {t("Conditions Générales d'Utilisation")}
                    </a>
                )}
                {isDefined(
                    props.legalDocuments.filter((f) => f.type === 'privacy')[0]
                ) && (
                    <a
                        class="underlined"
                        style="display: block;"
                        href={
                            props.legalDocuments.filter(
                                (f) => f.type === 'privacy'
                            )[0].media.url
                        }
                        target="_blank"
                    >
                        {t('Politique de Confidentialité')}
                    </a>
                )}
            </dummy>
        )}
    </div>
)

export default Component(state, actions, view, 'instructions')
