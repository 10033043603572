import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries, executeSynchedQuery } from '@app/api/controlleur';

const { APP_API_URL } = settings;
const ECOMPENDIUM_APIKEY = process.env.APP_ECOMPEDIUM;

function getTreatments(id) {
    return queueQueries(`${APP_API_URL}/theraflow/treatments/${id}/`, 'GET');
}
function getTreatment(id, treatid) {
    return queueQueries(`${APP_API_URL}/theraflow/treatments/${id}/${treatid}/`, 'GET');
}
function addTreatment(id, data) {
    return queueQueries(`${APP_API_URL}/theraflow/treatments/${id}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function updateTreatment(id, treatid, data) {
    return queueQueries(`${APP_API_URL}/theraflow/treatments/${id}/${treatid}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}
function searchTreatment(search, lang = 'FR', maxRow = 20) {
    return queueQueries(`${APP_API_URL}/theraflow/treatments/medicine/${search}/`, 'GET');
}

export const Treatment = {
    getTreatment,
    getTreatments,
    addTreatment,
    updateTreatment,
    searchTreatment,
};