import { t } from 'i18next'
import { Medical } from '@app/api'
import {
    deepCopy,
    getClosest,
    coreFn,
    scrollTo,
    getQueryVariable,
} from '@app/core'
import {
    isDefined,
    getYMDfromDateObj,
    getDateObjFromYMD,
    getNextSibling,
    getPreviousSibling,
    addCSRFToken,
    allRoutes,
} from '@app/core'
import { settings } from '@app/core/settings'
import { displayErrors } from '@app/api/errors'

import * as constants from '@app/constants'
import * as rds from '@app/constants/constants-rds'

const { APP_WEBAPP_URL, APP_FRONT_WEB_URL } = settings

/**
 * @module PatientEditSurvey
 * @description Patient configuration survey
 */

export default {
    initAction: function () {
        let PatientEditSurvey = {
            onComponentCreate: (props) => (state, actions) => {
                actions._setState({ key: 'doctor', value: props.doctor })
                actions._setState({ key: 'patientid', value: props.patientid })
                actions._setState({
                    key: 'editedPatient',
                    value: props.editedPatient,
                })
                actions._setState({ key: 'medicalStudyCards', value: [] })
                actions._setState({ key: 'medicalStudyAnswers', value: [] })
                //
                actions._setState({
                    key: 'referenceHealTeam',
                    value: props.referenceHealTeam,
                })
                //
                let patientHealTeam = []
                if (isDefined(props.editedPatient)) {
                    if (isDefined(props.editedPatient.userTheraflow.doctor)) {
                        patientHealTeam.push(
                            props.editedPatient.userTheraflow.doctor.id
                        )
                    }
                    if (isDefined(props.editedPatient.userTheraflow.coach)) {
                        patientHealTeam.push(
                            props.editedPatient.userTheraflow.coach.id
                        )
                    }
                    if (
                        isDefined(props.editedPatient.userTheraflow.nurses) &&
                        props.editedPatient.userTheraflow.nurses.length > 0
                    ) {
                        patientHealTeam = patientHealTeam.concat(
                            props.editedPatient.userTheraflow.nurses.map(
                                (m) => m.id
                            )
                        )
                    }
                }
                actions._setState({
                    key: 'patientHealTeam',
                    value: patientHealTeam,
                })
                //
                let autoGenerated = false
                if (
                    props.editedPatient.email.indexOf(props.editedPatient.id) >
                    -1
                ) {
                    autoGenerated = true
                }
                actions._setState({
                    key: 'autoGeneratedEmail',
                    value: autoGenerated,
                })
                //
                let hospital = isDefined(props.hospitals)
                    ? props.hospitals[0]
                    : null
                let surveys = null
                if (isDefined(hospital)) {
                    surveys = hospital.customerModules.studySettings.filter(
                        (f) => f.status
                    )
                    if (isDefined(surveys) && surveys.length > 0) {
                        actions._setState({
                            key: 'medicalStudiesConst',
                            value: surveys,
                        })
                        let defaultSurvey = surveys[0]
                        if (
                            isDefined(defaultSurvey) &&
                            defaultSurvey.length > 0
                        ) {
                            state.medicalStudy.name =
                                defaultSurvey[defaultSurvey.length - 1].key
                        }
                    }
                }
                //
                let reset = false
                if (isDefined(props.trgid)) {
                    // when comming from email redirect
                    reset = true
                    state.medicalStudy.name = props.trgid
                    actions.backToSurvey()
                }
                if (isDefined(props.strgid)) {
                    actions._setState({
                        key: 'autoOpenAnswerFromEmailId',
                        value: props.strgid,
                    })
                }
                //
                if (props.doctor.readableRole === 'promoter') {
                    actions.getMedicalStudy(reset)
                } else {
                    Medical.getMedicalInfos().then((res) => {
                        let organs = res.data.organs
                        let parsedOrgans = {},
                            parsedSurvey = []
                        //
                        if (isDefined(surveys)) {
                            for (let organ in organs) {
                                parsedSurvey = []
                                for (let survey in organs[organ]) {
                                    if (
                                        isDefined(
                                            surveys.filter(
                                                (f) =>
                                                    f.name ===
                                                    organs[organ][survey].name
                                            )[0]
                                        )
                                    ) {
                                        parsedSurvey.push(organs[organ][survey])
                                    }
                                }
                                if (
                                    isDefined(parsedSurvey) &&
                                    parsedSurvey.length > 0
                                ) {
                                    parsedOrgans[organ] = parsedSurvey
                                }
                            }
                        }
                        //
                        let rdsMapping = rds.RDSMapping
                        if (props.customer === 'resilience') {
                            rdsMapping = rds.RDSMappingResilience
                        }
                        parsedOrgans = Object.entries(parsedOrgans).map(
                            (obj) => {
                                return {
                                    key: obj[0],
                                    label: isDefined(
                                        rdsMapping.filter(
                                            (f) => f.key === obj[0]
                                        )[0]
                                    )
                                        ? rdsMapping.filter(
                                              (f) => f.key === obj[0]
                                          )[0].label
                                        : 'Ø',
                                    img: isDefined(
                                        rdsMapping.filter(
                                            (f) => f.key === obj[0]
                                        )[0]
                                    )
                                        ? rdsMapping.filter(
                                              (f) => f.key === obj[0]
                                          )[0].img
                                        : 'Ø',
                                    surveys: obj[1],
                                }
                            }
                        )
                        actions._setState({
                            key: 'repeatableMedicalInfos',
                            value: parsedOrgans,
                        })
                        //
                        actions.getMedicalStudy(reset)
                    })
                }
            },
            /**
             * Get patient medical study, then call [updateMedicalStudy]{@link module:PatientEditSurvey~PatientEditSurvey:updateMedicalStudy})
             * @function PatientEditSurvey:getMedicalStudy
             * @param {boolean} reset - Boolean to reset listing
             */
            getMedicalStudy: (reset) => (state, actions) => {
                Medical.getStudy(state.patientid).then((medicalStudies) => {
                    medicalStudies = medicalStudies.data.filter(
                        (std) =>
                            !isDefined(std.surgeryDate) &&
                            !isDefined(std.visitDate)
                    )
                    if (
                        isDefined(medicalStudies) &&
                        medicalStudies.length > 0
                    ) {
                        if (
                            isDefined(
                                medicalStudies.filter(
                                    (f) => f.status === true
                                )[0]
                            ) &&
                            state.medicalStudies.length === 0
                        ) {
                            actions.backToSurvey()
                        }
                        actions._setState({
                            key: 'medicalStudies',
                            value: medicalStudies.map((m) => {
                                return {
                                    key: m.id,
                                    name: m.name,
                                    issuePeriod: actions.retrieveRealPeriod(
                                        m.issuePeriod
                                    ),
                                    frequency: m.frequency,
                                    issueSentBy: m.issueSentBy,
                                    issueSentAt: m.issueSentAt,
                                    status: m.status,
                                    startDate: m.startDate || m.date,
                                    organ: m.organ,
                                    usualName: m.usualName,
                                    clinicalIndication: m.clinicalIndication,
                                    phase: m.phase,
                                    frequencyAvailable: m.frequencyAvailable,
                                }
                            }),
                        })
                    } else {
                        actions._setState({ key: 'medicalStudies', value: [] })
                    }
                    actions.updateMedicalStudy({
                        surveyid: null,
                        reset: reset,
                        alertpage: null,
                    })
                })
            },
            /**
             * Incremente medical survey listing page, then call [updateMedicalStudy]{@link module:PatientEditSurvey~PatientEditSurvey:updateMedicalStudy})
             * @function PatientEditSurvey:loadNextSurvey
             */
            loadNextSurvey: () => (state, actions) => {
                actions._setState({ key: 'page', value: state.page + 1 })
                actions.updateMedicalStudy({
                    surveyid: null,
                    reset: false,
                    alertpage: null,
                })
            },
            backToSurvey: () => (state, actions) => {
                actions._setState({ key: 'configurationMode', value: false })
            },
            /**
             * Update patient medical study, <surveyid> is defined only when an alert is acquitted, if <surveyid> is defined call [updateMedicalStudyAnswers]{@link module:PatientEditSurvey~PatientEditSurvey:updateMedicalStudyAnswers})
             * @function PatientEditSurvey:getMedicalStudy
             * @param {string} surveyid - Id of medical study
             * @param {boolean} reset - Boolean to reset listing
             * @param {int} alertpage - Specific page, used to retrieve the page corresponding to the last edited alert
             */
            updateMedicalStudy:
                ({ surveyid = null, reset = false, alertpage = null }) =>
                (state, actions) => {
                    let targetPage = state.page
                    if (isDefined(alertpage)) {
                        // need to retrieve the page according to the last acquitted alerts /!!\
                        targetPage = alertpage
                    }
                    let medicalStudyName = state.medicalStudy.name
                    let medicalStudy = state.medicalStudies.filter(
                        (f) => f.name === medicalStudyName
                    )[0]
                    if (
                        !reset &&
                        targetPage === 0 &&
                        (!isDefined(medicalStudy) || !medicalStudy.status)
                    ) {
                        medicalStudyName = null
                    }
                    if (
                        !isDefined(medicalStudyName) &&
                        state.medicalStudies.length > 0
                    ) {
                        let tempMedicalStudies = state.medicalStudies.filter(
                            (f) => f.status
                        )
                        if (isDefined(tempMedicalStudies)) {
                            medicalStudyName =
                                tempMedicalStudies[
                                    tempMedicalStudies.length - 1
                                ]?.name
                        }
                        //
                        if (!isDefined(medicalStudyName)) {
                            medicalStudyName =
                                state.medicalStudies[
                                    state.medicalStudies.length - 1
                                ].name
                        }
                    }
                    if (!isDefined(medicalStudyName)) {
                        return false
                    }
                    //
                    Medical.getMedicalSurvey(
                        state.patientid,
                        medicalStudyName,
                        targetPage
                    ).then((medicalStudy) => {
                        actions._setState({
                            key: 'medicalStudy',
                            value: medicalStudy.data,
                        })
                        actions.updateCurrentMedicalStudyInfos(
                            state.medicalStudy.name
                        )
                        //
                        let medicalStudyEdited = null
                        if (
                            isDefined(state.medicalStudies) &&
                            state.medicalStudies.length > 0
                        ) {
                            medicalStudyEdited = state.medicalStudies.filter(
                                (f) => f.name === medicalStudy.data.name
                            )[0]
                        }
                        //
                        if (isDefined(medicalStudyEdited)) {
                            if (
                                isDefined(
                                    state.medicalStudies.filter(
                                        (f) => f.name === medicalStudy.data.name
                                    )[0]
                                )
                            ) {
                                let targetFrequency =
                                    constants.custo.periodFrequencies.filter(
                                        (f) =>
                                            f.key ===
                                            state.medicalStudies.filter(
                                                (f) =>
                                                    f.name ===
                                                    medicalStudy.data.name
                                            )[0].issuePeriod
                                    )[0]
                                if (isDefined(targetFrequency)) {
                                    medicalStudyEdited.frequency =
                                        targetFrequency.frequency
                                } else {
                                    medicalStudyEdited.frequency =
                                        state.newStudy.frequency
                                }
                            }
                            //
                            // if (isDefined(constants.custo.sendingWays.filter((f) => medicalStudy.data.issueSentBy === f.key)[0])) {
                            //     medicalStudyEdited.issueSentBy = constants.custo.sendingWays.filter((f) => medicalStudy.data.issueSentBy === f.key)[0].label;
                            // } else {
                            //     medicalStudyEdited.issueSentBy = state.newStudy.issueSentBy;
                            // }
                            medicalStudyEdited.issueSentBy =
                                state.medicalStudies.filter(
                                    (f) => f.name === medicalStudyEdited.name
                                )[0]?.issueSentBy
                            //
                            if (!isDefined(medicalStudyEdited.usualName)) {
                                medicalStudyEdited.usualName =
                                    medicalStudyEdited.name
                            }
                            actions._setState({
                                key: 'medicalStudyEdited',
                                value: deepCopy(medicalStudyEdited),
                            })
                            //
                            let dataAnswer = null,
                                card = null
                            let dataCards = medicalStudy.data.cards
                            let dataAnswers = medicalStudy.data.answers
                            if (
                                isDefined(dataAnswers) &&
                                dataAnswers.length >= 20
                            ) {
                                actions._setState({
                                    key: 'thereAreNextPage',
                                    value: true,
                                })
                            } else {
                                actions._setState({
                                    key: 'thereAreNextPage',
                                    value: false,
                                })
                            }
                            //
                            for (dataAnswer in dataAnswers) {
                                dataAnswers[dataAnswer].page = targetPage
                            }
                            // used to jump from one alert to next/prev alert from popup
                            let nswr = null,
                                nswrIdx = 0
                            for (dataAnswer in dataAnswers) {
                                for (card in dataCards) {
                                    for (var answer in dataAnswers[dataAnswer]
                                        .alerts) {
                                        nswr =
                                            dataAnswers[dataAnswer].alerts[
                                                answer
                                            ]
                                        if (
                                            nswr.evaluationName ===
                                            dataCards[card].name
                                        ) {
                                            nswrIdx++
                                            nswr.alertIndex = nswrIdx
                                        }
                                    }
                                }
                                if (isDefined(dataAnswers[dataAnswer].alerts)) {
                                    dataAnswers[dataAnswer].alertTotal =
                                        dataAnswers[dataAnswer].alerts.length
                                }
                                nswrIdx = 0
                            }
                            //
                            if (isDefined(surveyid)) {
                                let existingAnswers = state.medicalStudyAnswers,
                                    answer = null
                                for (answer in existingAnswers) {
                                    for (dataAnswer in dataAnswers) {
                                        if (
                                            existingAnswers[answer].id ===
                                            dataAnswers[dataAnswer].id
                                        ) {
                                            existingAnswers[answer] =
                                                dataAnswers[dataAnswer]
                                        }
                                    }
                                }
                                dataAnswers = existingAnswers
                                actions._setState({
                                    key: 'medicalStudyAnswers',
                                    value: dataAnswers,
                                })
                            } else {
                                let cardIdx = 0,
                                    cardIdxChild = 0
                                for (card in dataCards) {
                                    if (!isDefined(dataCards[card].parent)) {
                                        cardIdx++
                                        cardIdxChild = 0
                                        dataCards[card].cardIdx = cardIdx
                                    } else {
                                        cardIdxChild++
                                        dataCards[card].cardIdx =
                                            cardIdx +
                                            '.' +
                                            String.fromCharCode(
                                                96 + cardIdxChild
                                            )
                                    }
                                }
                                // console.log('dataCards : ', dataCards);
                                // let tempAlert = dataAnswers.filter((f) => f.id === '61f10fb50fbc4d4bd716cb90')[0].alerts
                                // let duplicateAlert = Object.assign({}, dataAnswers.filter((f) => f.id === '61f10fb50fbc4d4bd716cb90')[0].alerts[0])
                                // duplicateAlert.color = 4;
                                // tempAlert.push(duplicateAlert)
                                // dataAnswers.filter((f) => f.id === '61f10fb50fbc4d4bd716cb90')[0].alerts = tempAlert;
                                // console.log('#1 dataAnswers : ', dataAnswers);
                                //
                                for (let answer in dataAnswers) {
                                    dataAnswers[answer].alerts =
                                        actions.removeDuplicateAlertKeepHighestColor(
                                            dataAnswers[answer].alerts
                                        )
                                    dataAnswers[answer].values =
                                        actions.parseValueFollingType(
                                            dataAnswers[answer].values
                                        )
                                }
                                //
                                actions._setState({
                                    key: 'medicalStudyCards',
                                    value: dataCards,
                                })
                                actions.updateMedicalStudyAnswers({
                                    answers: dataAnswers,
                                    reset: reset,
                                })
                            }
                        } else {
                            let newStudy = state.newStudy
                            newStudy.name = state.medicalStudy.name
                            actions._setState({
                                key: 'medicalStudyEdited',
                                value: newStudy,
                            })
                            actions._setState({
                                key: 'medicalStudyAnswers',
                                value: [],
                            })
                            actions._setState({
                                key: 'medicalStudyCards',
                                value: null,
                            })
                        }
                        //
                        let surveyid = getQueryVariable('surveyid', false)
                        if (!isDefined(surveyid) || surveyid === false) {
                            surveyid = state.autoOpenAnswerFromEmailId
                        }
                        if (isDefined(surveyid) && surveyid !== false) {
                            actions.backToSurvey()
                            actions.onAcquitSurvey(surveyid)
                            actions._setState({
                                key: 'autoOpenAnswerFromEmailId',
                                value: null,
                            })
                        } else {
                            actions._setState({ key: 'loaded', value: true })
                        }
                    })
                },
            removeDuplicateAlertKeepHighestColor:
                (alerts) => (state, actions) => {
                    return alerts.filter((alert) => {
                        let sameAlerts = alerts.filter(
                            (lrt) => lrt.evaluationName === alert.evaluationName
                        )
                        // found multiple alert with same evaluationName, keeping the higher color rate
                        if (sameAlerts.length > 1) {
                            let reducedAlerts = sameAlerts.filter(
                                (f) => alert.color > f.color
                            )
                            return isDefined(reducedAlerts[0])
                        }
                        return true
                    })
                },
            parseValueFollingType: (values) => (state, actions) => {
                for (let value in values) {
                    if (values[value].value !== undefined) {
                        values[value].value =
                            values[value]?.type === 'Yesno'
                                ? values[value].label
                                : values[value].value
                    } else {
                        for (let question in values[value]) {
                            values[value][question] = {
                                ...values[value][question],
                                value:
                                    values[value][question]?.type === 'Yesno'
                                        ? values[value][question].label
                                        : values[value][question].value,
                            }
                        }
                    }
                }
                return values
            },
            /**
             * Update the listing of alerts, if <reset> is false, array is re-create before parsing <answers>
             * @function PatientEditSurvey:updateMedicalStudyAnswers
             * @param {object} answers - Array of answer objects
             * @param {boolean} reset - Boolean to reset listing
             */
            updateMedicalStudyAnswers:
                ({ answers, reset = false }) =>
                (state, actions) => {
                    let newAnswers = !reset
                        ? state.medicalStudyAnswers
                        : new Array()
                    for (let answer in answers) {
                        newAnswers.push(answers[answer])
                    }
                    actions._setState({
                        key: 'medicalStudyAnswers',
                        value: newAnswers,
                    })
                },
            /**
             * Retrieve form input on the fly
             * @function PatientEditStudy:retrieveInput
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             */
            retrieveInput: (input) => (state, actions) => {
                const { name, values } = input
                let medicalStudyEdited = state.medicalStudyEdited
                if (isDefined(medicalStudyEdited)) {
                    medicalStudyEdited[name] = values
                    actions._setState({
                        key: 'medicalStudyEdited',
                        value: medicalStudyEdited,
                    })
                }
                if (name === 'issueSentBy') {
                    actions._setState({
                        key: 'formSubmittedOnce',
                        value: false,
                    })
                    actions._setErrorMessages({ issueSentBy: '' })
                    actions._setValidity({ issueSentBy: true })
                }
                if (name === 'frequency') {
                    if (
                        !isDefined(
                            constants.custo.periodFrequencies
                                .filter((f) => f.frequency === values)
                                .filter(
                                    (f) =>
                                        f.key === medicalStudyEdited.issuePeriod
                                )[0]
                        )
                    ) {
                        medicalStudyEdited.issuePeriod =
                            constants.custo.periodFrequencies.filter(
                                (f) => f.frequency === values
                            )[0].key
                        actions._setState({
                            key: 'medicalStudyEdited',
                            value: medicalStudyEdited,
                        })
                    }
                }
                actions._setTyping({ [name]: true })
                if (name === 'issueSentBy') {
                    if (values === 'EMAIL' && state.autoGeneratedEmail) {
                        actions._setTypingFalse()
                        actions._setErrorMessages({
                            issueSentBy: t(
                                'Rendez-vous dans l’onglet « Profil » pour définir l’adresse email de votre patient.'
                            ),
                        })
                        actions._setValidity({ issueSentBy: false })
                        actions._setState({
                            key: 'formSubmittedOnce',
                            value: true,
                        })
                        actions._setTyping({ [name]: false })
                    }
                }
            },
            updateCurrentMedicalStudy: (values) => (state, actions) => {
                actions._setState({
                    key: 'medicalStudy',
                    value: {
                        name: values,
                        frequency: state.newStudy.frequency,
                        issuePeriod: state.newStudy.issuePeriod,
                        issueSentBy: state.newStudy.issueSentBy,
                    },
                })
                actions.updateMedicalStudy({
                    surveyid: null,
                    reset: true,
                    alertpage: null,
                })
            },
            updateCurrentMedicalStudyInfos: (values) => (state, actions) => {
                let usualName = ''
                let clinicalIndication = ''
                for (let mInfos in state.repeatableMedicalInfos) {
                    for (let mSurveys in state.repeatableMedicalInfos[mInfos]
                        .surveys) {
                        if (
                            state.repeatableMedicalInfos[mInfos].surveys[
                                mSurveys
                            ].name === values
                        ) {
                            usualName =
                                state.repeatableMedicalInfos[mInfos].surveys[
                                    mSurveys
                                ].usualName
                            clinicalIndication =
                                state.repeatableMedicalInfos[mInfos].surveys[
                                    mSurveys
                                ].clinicalIndication
                            break
                        }
                    }
                    if (usualName !== '') {
                        break
                    }
                }
                if (usualName === '') {
                    usualName = values
                }
                //
                let medicalStudy = state.medicalStudy
                medicalStudy.usualName = usualName
                medicalStudy.clinicalIndication = clinicalIndication
                actions._setState({ key: 'medicalStudy', value: medicalStudy })
            },
            retrieveStudy: (study) => (state, actions) => {
                actions._setState({ key: 'summaryDisplayed', value: true })
                actions.onEditSurvey()
                //
                //
                let name = null
                let medicalStudyEdited = null
                if (isDefined(study)) {
                    medicalStudyEdited = study
                    name = study.name
                    let activeStudy = state.medicalStudies.filter(
                        (f) => f.name === name
                    )[0]
                    //
                    if (isDefined(activeStudy)) {
                        if (
                            !isDefined(activeStudy.frequency) ||
                            !isDefined(
                                activeStudy.frequencyAvailable.filter(
                                    (f) => f === activeStudy.frequency
                                )[0]
                            )
                        ) {
                            activeStudy.frequency =
                                activeStudy.frequencyAvailable[0]
                        }
                        //
                        medicalStudyEdited = deepCopy(activeStudy)
                    }
                } else {
                    medicalStudyEdited = state.medicalStudyEdited
                }
                //
                let parsedFrequencyAvailable =
                    medicalStudyEdited.frequencyAvailable.map(
                        (m) =>
                            constants.custo.periodFrequenciesPeriods.filter(
                                (f) => f.key === (m.key || m)
                            )[0]
                    )
                medicalStudyEdited.frequencyAvailable =
                    parsedFrequencyAvailable.map((m, idx) => {
                        return {
                            label:
                                idx === 0
                                    ? t(m.label) + ' (' + t('Recommandée') + ')'
                                    : t(m.label),
                            key: m.key,
                        }
                    })
                //
                if (!isDefined(medicalStudyEdited.key)) {
                    let temp = medicalStudyEdited.frequencyAvailable.map(
                        (m) =>
                            constants.custo.periodFrequencies.filter(
                                (f) => f.frequency === m
                            )[0]
                    )[0]
                    if (isDefined(temp)) {
                        medicalStudyEdited.frequency = temp.frequency
                    } else {
                        medicalStudyEdited.frequency =
                            medicalStudyEdited.frequencyAvailable[0].key
                    }
                    //
                    if (!isDefined(medicalStudyEdited.key)) {
                        medicalStudyEdited.issuePeriod =
                            constants.custo.periodFrequencies.filter(
                                (f) =>
                                    f.frequency === medicalStudyEdited.frequency
                            )[0].key
                    }
                }
                //
                if (!medicalStudyEdited.status) {
                    medicalStudyEdited.alreadySended = false
                }
                //
                if (state.newSurvey && !isDefined(medicalStudyEdited.key)) {
                    medicalStudyEdited.status = true
                }
                //
                if (!isDefined(medicalStudyEdited.issueSentBy)) {
                    medicalStudyEdited.issueSentBy = 'SMS'
                }
                //
                if (state.newSurvey) {
                    Medical.getLastStudyConfig(state.patientid).then((res) => {
                        let lastEditedStudy = res.data
                        // /////////
                        if (
                            isDefined(lastEditedStudy) &&
                            isDefined(lastEditedStudy.frequency)
                        ) {
                            let temp =
                                medicalStudyEdited.frequencyAvailable.filter(
                                    (m) => lastEditedStudy.frequency === m.key
                                )[0]
                            if (isDefined(temp)) {
                                medicalStudyEdited.frequency =
                                    lastEditedStudy.frequency
                                medicalStudyEdited.issuePeriod =
                                    lastEditedStudy.issuePeriod
                                medicalStudyEdited.issueSentBy =
                                    lastEditedStudy.issueSentBy
                            }
                        }
                        // /////////
                        //
                        actions._setState({
                            key: 'medicalStudyEdited',
                            value: medicalStudyEdited,
                        })
                        actions.isTherePhaseSwitch()
                    })
                } else {
                    actions._setState({
                        key: 'medicalStudyEdited',
                        value: medicalStudyEdited,
                    })
                    actions.isTherePhaseSwitch()
                }
            },
            retrieveSurveyContent: (survey) => (state, actions) => {
                Medical.getMedicalNamedInfos(survey.name).then((res) => {
                    let cardsContentPreview = res.data
                    let idx = 0
                    let letterIdx = 0
                    for (let card in cardsContentPreview.cardsContent) {
                        card = cardsContentPreview.cardsContent[card]
                        if (card.parent === null) {
                            idx++
                        }
                        card.idx = idx
                        if (card.parent !== null) {
                            card.idx =
                                card.idx +
                                '.' +
                                String.fromCharCode(96 + letterIdx)
                            letterIdx++
                        } else {
                            letterIdx = 1
                        }
                    }
                    actions._setState({
                        key: 'cardsContentPreview',
                        value: cardsContentPreview,
                    })
                    actions._setState({
                        key: 'surveyPreviewDisplayed',
                        value: true,
                    })
                })
            },
            /**
             * Save medical study, then call [getMedicalStudy]{@link module:PatientEditSurvey~PatientEditSurvey:getMedicalStudy})
             * @function PatientEditSurvey:onSaveStudy
             */
            onSaveStudy:
                (newWay = false) =>
                (state, actions) => {
                    if (
                        isDefined(state.foundComonPhase) &&
                        state.foundComonPhase.length > 0
                    ) {
                        actions.switchStudies()
                        return false
                    }
                    //
                    if (isDefined(state.medicalStudyEdited.key)) {
                        actions.updateStudy()
                        return false
                    }
                    //
                    if (state.formIsSubmitting) {
                        return false
                    } else if (
                        state.medicalStudyEdited.issueSentBy === 'EMAIL' &&
                        state.autoGeneratedEmail
                    ) {
                        return false
                    }
                    //
                    actions._setState({ key: 'formIsSubmitting', value: true })
                    actions._setTypingFalse()
                    let { frequency, issuePeriod, issueSentBy } = state.newStudy
                    let name = state.medicalStudyEdited.name
                    //
                    if (newWay) {
                        frequency = state.medicalStudyEdited.frequency
                        issuePeriod = state.medicalStudyEdited.issuePeriod
                        issueSentBy = state.medicalStudyEdited.issueSentBy
                    }
                    //
                    let today = getYMDfromDateObj()
                    today = getDateObjFromYMD(
                        today.year + '' + today.month + '' + today.day,
                        'now',
                        true
                    )
                    let data = {
                        frequency: frequency,
                        issuePeriod: actions.retrieveRealPeriod(issuePeriod),
                        issueSentBy: issueSentBy,
                        name: name,
                        startDate: today,
                        status: true,
                    }
                    Medical.sendStudy(state.patientid, data).then((res) => {
                        actions.setModalDisplayed(false)
                        actions.cancelPhaseSwitching()
                        actions._setState({
                            key: 'summaryDisplayed',
                            value: false,
                        })
                        //
                        state.medicalStudy.name = state.medicalStudyEdited.name
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        actions._setState({ key: 'newSurvey', value: false })
                        actions.getMedicalStudy(true)
                        actions.updateStudiesListing(res.data)
                        displayErrors(
                            t('Questionnaire créé avec succès.'),
                            3000,
                            'success'
                        )
                    })
                },
            /**
             * Update medical study, then hide modal and call [getMedicalStudy]{@link module:PatientEditSurvey~PatientEditSurvey:getMedicalStudy})
             * @function PatientEditSurvey:updateStudy
             */
            updateStudy: () => (state, actions) => {
                if (state.formIsSubmitting) {
                    return false
                } else if (
                    state.medicalStudyEdited.issueSentBy === 'EMAIL' &&
                    state.autoGeneratedEmail
                ) {
                    return false
                }
                //
                if (
                    isDefined(state.foundComonPhase) &&
                    state.foundComonPhase.length > 0
                ) {
                    actions.switchStudies()
                    return false
                }
                //
                actions._setState({ key: 'formIsSubmitting', value: true })
                actions._setTypingFalse()
                let data = {
                    frequency: state.medicalStudyEdited.frequency,
                    issuePeriod: actions.retrieveRealPeriod(
                        state.medicalStudyEdited.issuePeriod
                    ),
                    issueSentBy: state.medicalStudyEdited.issueSentBy,
                    name: state.medicalStudyEdited.name,
                    startDate: state.medicalStudyEdited.startDate,
                    status: state.medicalStudyEdited.status,
                }
                Medical.updateStudy(
                    state.patientid,
                    state.medicalStudyEdited.key,
                    data
                ).then((res) => {
                    actions.setModalDisplayed(false)
                    actions.cancelPhaseSwitching()
                    actions._setState({ key: 'summaryDisplayed', value: false })
                    //
                    state.medicalStudy.name = state.medicalStudyEdited.name
                    actions._setState({ key: 'formIsSubmitting', value: false })
                    actions._setState({ key: 'newSurvey', value: false })
                    actions.getMedicalStudy(true)
                    actions.updateStudiesListing(res.data)
                    displayErrors(
                        t('Modifications enregistrées !'),
                        3000,
                        'success'
                    )
                })
            },
            updateStudiesListing: (study) => (state, actions) => {
                for (let std in state.medicalStudies) {
                    if (state.medicalStudies[std].name === study.name) {
                        state.medicalStudies[std] = study
                        break
                    }
                }
            },
            switchStudies: () => (state, actions) => {
                let nFoundComonPhase = 0
                if (isDefined(state.foundComonPhase)) {
                    nFoundComonPhase = state.foundComonPhase.length
                }
                let currentFoundPhase = null
                for (let foundComonPhase in state.foundComonPhase) {
                    currentFoundPhase = state.foundComonPhase[foundComonPhase]
                    let data = {
                        frequency: currentFoundPhase.frequency,
                        issuePeriod: actions.retrieveRealPeriod(
                            currentFoundPhase.issuePeriod
                        ),
                        issueSentBy: currentFoundPhase.issueSentBy,
                        name: currentFoundPhase.name,
                        startDate: currentFoundPhase.startDate,
                        status: false,
                    }
                    Medical.updateStudy(
                        state.patientid,
                        currentFoundPhase.key,
                        data
                    ).then((res) => {
                        nFoundComonPhase--
                        if (nFoundComonPhase <= 0) {
                            if (!isDefined(res.data.code)) {
                                actions._setState({
                                    key: 'formIsSubmitting',
                                    value: false,
                                })
                                actions._setState({
                                    key: 'foundComonPhase',
                                    value: null,
                                })
                                //
                                actions.onSaveStudy(true)
                            }
                        }
                    })
                }
            },
            isTherePhaseSwitch: () => (state, actions) => {
                let medicalStudies = state.medicalStudies.filter(
                    (f) => f.status
                )
                let foundComonPhase = []
                for (let medicalStudy in medicalStudies) {
                    medicalStudy = medicalStudies[medicalStudy]
                    if (
                        medicalStudy.status === true &&
                        medicalStudy.organ === state.medicalStudyEdited.organ &&
                        medicalStudy.phase !== state.medicalStudyEdited.phase
                    ) {
                        foundComonPhase.push(medicalStudy)
                    }
                }
                if (foundComonPhase.length > 0) {
                    actions._setState({
                        key: 'foundComonPhase',
                        value: foundComonPhase,
                    })
                    actions._setState({ key: 'switchPhase', value: true })
                    actions._setState({ key: 'modalEditing', value: '' })
                    // actions._setState({key: 'newSurvey', value: false});
                    actions._setState({ key: 'summaryDisplayed', value: false })
                    actions.setModalDisplayed(false)
                }
            },
            confirmPhaseSwitching: () => (state, actions) => {
                actions._setState({ key: 'summaryDisplayed', value: true })
                actions._setState({ key: 'switchPhaseDisplayed', value: false })
                actions._setState({ key: 'switchPhase', value: false })
                //
                actions.onEditSurvey()
            },
            cancelPhaseSwitching: () => (state, actions) => {
                actions._setState({ key: 'switchPhaseDisplayed', value: false })
                actions._setState({ key: 'foundComonPhase', value: [] })
                actions._setState({ key: 'switchPhase', value: false })
                actions._setState({ key: 'modalEditing', value: '' })
                //
                actions.onCancelEditSurvey()
            },
            /**
             * Switch displayed alert from alert popup (next / previous)
             * @function PatientEditSurvey:switchDisplayedAlert
             */
            switchDisplayedAlert:
                ({ e, side }) =>
                (state, actions) => {
                    e.preventDefault()
                    e.stopPropagation()
                    //
                    let target = getClosest(
                        e.currentTarget,
                        '.thfSurveyReport-cell'
                    )
                    let nextTarget = '.thfSurveyReport-nextCell'
                    //
                    if (side === 'right') {
                        nextTarget = getNextSibling(target, nextTarget)
                    } else if (side === 'left') {
                        nextTarget = getPreviousSibling(target, nextTarget)
                    }
                    //
                    if (isDefined(nextTarget)) {
                        let distCell = Math.abs(
                            +target.getAttribute('data-index') -
                                +nextTarget.getAttribute('data-index')
                        )
                        actions.scrollBoard({
                            side: side,
                            dist: 35 * distCell,
                            time: 180,
                        })
                        //
                        let cardName = nextTarget.getAttribute('data-cardname')
                        let answerId = nextTarget.getAttribute('data-answerid')
                        let answerDate =
                            nextTarget.getAttribute('data-answerdate')
                        //
                        target.blur()
                        state.popupDisplayed = cardName + answerDate + answerId
                        actions._setState({
                            key: 'popupDisplayed',
                            value: cardName + answerDate + answerId,
                        })
                        nextTarget.focus()
                    }
                    return false
                },
            /**
             * Display medical survey edition modal
             * @function PatientEditSurvey:onEditSurvey
             */
            onEditSurvey: () => (state, actions) => {
                actions._setState({
                    key: 'medicalStudyEditedCanceled',
                    value: deepCopy(state.medicalStudyEdited),
                })
                actions._setState({ key: 'modalEditing', value: 'survey' })
                actions.setModalDisplayed(true)
            },
            /**
             * Cancel edition and hide medical survey edition modal
             * @function PatientEditSurvey:onEditSurvey
             */
            onCancelEditSurvey: () => (state, actions) => {
                actions._setState({
                    key: 'medicalStudyEdited',
                    value: deepCopy(state.medicalStudyEditedCanceled),
                })
                actions._setState({ key: 'summaryDisplayed', value: false })
                actions._setState({ key: 'modalEditing', value: '' })
                actions.setModalDisplayed(false)
            },
            /**
             * Display medical survey acquitment
             * @function PatientEditSurvey:onAcquitSurvey
             */
            onAcquitSurvey: (id) => (state, actions) => {
                Medical.getStudyAnswers(id).then((res) => {
                    let currentAnswer = res.data
                    currentAnswer.alerts =
                        actions.removeDuplicateAlertKeepHighestColor(
                            currentAnswer.alerts
                        )
                    currentAnswer.values = actions.parseValueFollingType(
                        currentAnswer.values
                    )
                    //
                    if (!isDefined(currentAnswer.usualName)) {
                        let retrieveStudy = state.medicalStudies.filter(
                            (f) => f.name === currentAnswer.name
                        )[0]
                        if (isDefined(retrieveStudy)) {
                            currentAnswer.usualName =
                                retrieveStudy.usualName || retrieveStudy.name
                        }
                    }
                    actions._setState({
                        key: 'newAnswerSelected',
                        value: false,
                    })
                    actions._setState({
                        key: 'currentAnswer',
                        value: currentAnswer,
                    })
                    actions._setState({
                        key: 'modalEditing',
                        value: 'acquit-' + id,
                    })
                    actions.setModalDisplayed(true)
                    if (!state.loaded) {
                        actions._setState({ key: 'loaded', value: true })
                    }
                })
            },
            /**
             * Cancel acquitment and hide medical survey acquitment
             * @function PatientEditSurvey:onCancelAcquitSurvey
             */
            onCancelAcquitSurvey: () => (state, actions) => {
                let isMobile = localStorage.getItem('isMobile')
                if (isMobile == true || isMobile === 'true') {
                    window.location.href = addCSRFToken(
                        allRoutes['private.alertsrds.listing'].pathname
                    )
                } else {
                    actions._setState({ key: 'modalEditing', value: '' })
                    actions.setModalDisplayed(false)
                }
            },
            /**
             * Update modal display status and kind of display
             * @function PatientEditSurvey:setModalDisplayed
             * @param {boolean} active - Show/hide modal, if false, empty <modalEditing>
             */
            setModalDisplayed: (active) => (state, actions) => ({
                modalDisplayed: active,
                modalEditing: active ? state.modalEditing : '',
            }),
            commentCallback: (currentAnswer) => (state, actions) => {
                let answerId = currentAnswer.id
                let searchedAnswer = null
                if (isDefined(state.medicalStudyAnswers)) {
                    searchedAnswer = state.medicalStudyAnswers.filter(
                        (f) => f.id === answerId
                    )[0]
                }
                if (isDefined(searchedAnswer)) {
                    if (!isDefined(searchedAnswer.commentsCount)) {
                        searchedAnswer.commentsCount = 0
                    }
                    searchedAnswer.commentsCount += 1
                    state.medicalStudyAnswers.filter(
                        (f) => f.id === answerId
                    )[0] = searchedAnswer
                }
                //
                currentAnswer.nique = 'ok - ' + currentAnswer.usualName
                actions._setState({
                    key: 'currentAnswer',
                    value: currentAnswer,
                })
                actions._setState({
                    key: 'medicalStudyAnswers',
                    value: state.medicalStudyAnswers,
                })
            },
            /**
             * Acquit a survey alert, then call [updateMedicalStudy]{@link module:PatientEditSurvey~PatientEditSurvey:updateMedicalStudy})
             * @function PatientEditSurvey:acquitSurvey
             * @param {string} surveyid - Id of medical study
             */
            acquitSurvey: (surveyid) => (state, actions) => {
                let newAction = state.currentAnswer.action
                if (!isDefined(newAction) || newAction === '') {
                    //
                    return false
                }
                //
                Medical.updateStudyAnswerAction(state.currentAnswer.id, {
                    action: newAction,
                }).then((res) => {
                    let currentAnswer = state.currentAnswer
                    if (isDefined(res.data) && isDefined(res.data.action)) {
                        currentAnswer.action = res.data.action
                        //
                        let medicalStudyAnswers = state.medicalStudyAnswers
                        if (isDefined(medicalStudyAnswers)) {
                            let currentSTEntry = medicalStudyAnswers.filter(
                                (f) => f.id === res.data.id
                            )[0]
                            if (isDefined(currentSTEntry)) {
                                currentSTEntry.action = res.data.action.action
                            }
                        }
                        actions._setState({
                            key: 'medicalStudyAnswers',
                            value: medicalStudyAnswers,
                        })
                    }
                    //
                    actions._setState({
                        key: 'currentAnswer',
                        value: currentAnswer,
                    })
                    //
                    actions._setState({ key: 'popupDisplayed', value: null })
                    actions._setState({
                        key: 'newAnswerSelected',
                        value: false,
                    })
                    var event = new CustomEvent('updateInfos')
                    window.dispatchEvent(event)
                })
            },
            updateCurrentActionState: (input) => (state, actions) => {
                const { values } = input
                let currentAnswer = state.currentAnswer
                currentAnswer.action = values
                actions._setState({
                    key: 'currentAnswer',
                    value: currentAnswer,
                })
                actions._setState({ key: 'newAnswerSelected', value: true })
            },
            scrollBoard:
                ({ side, dist = 200, time = 180 }) =>
                (state, actions) => {
                    let target = document.querySelector('div.thfSurveyReport')
                    let newScroll = target.scrollLeft
                    if (isDefined(target)) {
                        if (side === 'left') {
                            newScroll -= dist
                        } else if (side === 'right') {
                            newScroll += dist
                        }
                    }
                    scrollTo(target, newScroll, time, 'horizontal')
                },
            sendInstantSurvey: () => (state, actions) => {
                Medical.sendInstantSurvey(
                    state.editedPatient.id,
                    state.medicalStudyEdited.key
                ).then((res) => {
                    if (isDefined(res.data.code)) {
                        if (+res.data.code === 2310) {
                            displayErrors(
                                t(
                                    "Aucune carte éligible, impossible d'envoyer le questionnaire"
                                ),
                                3000,
                                'error'
                            )
                        } else {
                            displayErrors(
                                t('Une erreur est survenue'),
                                3000,
                                'error'
                            )
                        }
                    } else {
                        actions._setState({
                            key: 'justSendedSurvey',
                            value: true,
                        })
                        setTimeout(() => {
                            actions._setState({
                                key: 'justSendedSurvey',
                                value: false,
                            })
                            actions.getMedicalStudy(true)
                        }, 500)
                    }
                })
            },
            selectThisOrgan: (organ) => (state, actions) => {
                actions._setState({ key: 'selectedOrgan', value: organ })
                let tempTmout = setTimeout(() => {
                    clearTimeout(tempTmout)
                    let targetScrollEl = document.querySelector(
                        'div.btzMainPage.btzMainPageCenter'
                    )
                    let targetScrollPos = document.querySelector(
                        'div.rdsCong-scrollMarker'
                    )
                    if (
                        isDefined(targetScrollPos) &&
                        isDefined(targetScrollEl)
                    ) {
                        scrollTo(
                            targetScrollEl,
                            targetScrollPos.getBoundingClientRect().top +
                                targetScrollEl.scrollTop -
                                75,
                            210,
                            'vertical'
                        )
                    }
                }, 200)
            },
            // http://localhost:8085/medical-study/61c3463ba800a25dae60f94a/5f881079f16a2b7523161f01/VlBEbFpwR1o4c3NydE95K3dVUzFqS2tYWnM5TEZLa3l3cndaSUVjME14LzFmSlpldlR2NGhvdXREUDk4K0Jtam1vTXVhbEhLZzM4ekF2dzBRckxnMEhIWEVvaGN2Mmd4bitUaFNILzh4YXVDQnpwR0lWK2RvcXpyQVhQS3JOMlRNRkxqKzU5SjdJejk0aFVYOXMrTEtkMkNEdGVaeFBlQVd2M1NtUzN4VGJZdU50cnZGdWV1SkhrZnZLaWhwNXVncVVod1JDSmZydk9lSmh6dlI5SXNlbStkTWVQRFZ1cmNEc2RtbDNTQUNKa2hFcTR4NjFJaWw3Z1pldz09fC18TvMU3N/v2k/X2O+kfC18KegzF52IBUxWN6EIGvOmtA==/?display=popup
            activateThisTreatment: (treat) => (state, actions) => {
                //
            },
            retrieveRealPeriod: (period) => (state, actions) => {
                if (period === 'quarterly') {
                    let currentMonth = new Date().getMonth()
                    let parsedPeriod = constants.custo.quarterlyPeriod.filter(
                        (f) => f.key.indexOf(currentMonth) > -1
                    )[0].value
                    return parsedPeriod
                } else if (
                    isDefined(period) &&
                    period.indexOf('quarterly') > -1
                ) {
                    return 'quarterly'
                }
                return period
            },
            defineSurveyUrl:
                ({ answer, answeridx }) =>
                (state, actions) => {
                    let token = encodeURIComponent(
                        localStorage.getItem('apitoken')
                    )
                    let iframeUrl =
                        APP_WEBAPP_URL +
                        '/medical-study/' +
                        answer.surveyId +
                        '/' +
                        state.editedPatient.id +
                        '/' +
                        token +
                        '/?display=popup'
                    actions.setModaldisplayed('dosurvey-' + answeridx)
                    actions._setState({ key: 'surveyUrl', value: iframeUrl })
                    var iframe = document.getElementById('inlineFrameExample')
                    if (!isDefined(iframe)) {
                        return false
                    }
                    // eslint-disable-next-line
                    var messager = new Messager({
                        remoteWindow: iframe.contentWindow,
                        local: APP_FRONT_WEB_URL,
                        remote: APP_WEBAPP_URL,
                    })
                    messager.bind('updateTimelines', () => {
                        actions.setModaldisplayed('')
                        actions._setState({ key: 'surveyUrl', value: null })
                        actions.updateMedicalStudy({
                            surveyid: null,
                            reset: true,
                            alertpage: null,
                        })
                        messager.destroy()
                    })
                },
            setModaldisplayed: (newState) => (state) => ({
                modaldisplayed: newState,
            }),
        }
        PatientEditSurvey = { ...coreFn, ...PatientEditSurvey }
        return PatientEditSurvey
    },
}
