import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { APP_API_URL } = settings;

function askPhone(data) {
    return queueQueries(`${APP_API_URL}/theraflow/profile/ask-phone/`, 'POST', 'application/json', true, true, true, true, true, data, false, 'doctorReset');
}
function checkPhone(code) {
    return queueQueries(`${APP_API_URL}/theraflow/profile/check-phone/`, 'POST', 'application/json', true, true, true, true, true, {code: code}, false, 'doctorReset');
}
function updatePhone(answer, code) {
    return queueQueries(`${APP_API_URL}/theraflow/profile/do-update-phone/`, 'POST', 'application/json', true, true, true, true, true, {answer: answer, code: code}, false, 'doctorReset');
}
function savePhone(id, data) {
    return queueQueries(`${APP_API_URL}/user/save-phone/${id}/`, 'POST', 'application/json', true, true, true, false, true, data, false, 'doctorReset');
}
function validatePhone(id, data) {
    return queueQueries(`${APP_API_URL}/user/validate-phone/${id}/`, 'POST', 'application/json', true, true, true, false, true, data, false, 'doctorReset');
}

export const Phone = {
    askPhone,
    checkPhone,
    updatePhone,
    savePhone,
    validatePhone
};