import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { APP_API_URL } = settings;

function createSession(email, password) {
    return queueQueries(`${APP_API_URL}/session/`, 'POST', 'application/json', true, true, true, false, true, {email: email, password: password}, true, 'doctorSession');
}
function openSession(code, password, token) {
    return queueQueries(`${APP_API_URL}/session/`, 'PUT', 'application/json', true, true, true, false, true, {code: code, password: password, token: token}, true, 'doctorSession');
}
function resetPwd() {
    return queueQueries(`${APP_API_URL}/session/new-password/`, 'GET', 'application/json', true, true, true, true, true, null, false, 'doctorSession');
}

export const Session = {
    createSession,
    openSession,
    resetPwd,
};