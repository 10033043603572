import 'es7-object-polyfill'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'custom-event-polyfill'

export function iePolyfills() {
    if ('NodeList' in window && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this)
            }
        }
    }
    ;(function () {
        if (typeof window.CustomEvent === 'function') return false
        function CustomEvent(event, params) {
            params = params || {
                bubbles: false,
                cancelable: false,
                detail: undefined,
            }
            var evt = document.createEvent('CustomEvent')
            evt.initCustomEvent(
                event,
                params.bubbles,
                params.cancelable,
                params.detail
            )
            return evt
        }
        CustomEvent.prototype = window.Event.prototype
        window.CustomEvent = CustomEvent
    })()
}
