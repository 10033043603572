import { settings } from '@app/core/settings'
import { handleErrors } from '@app/api/errors'
import { queueQueries } from '@app/api/controlleur'

const { API_URL } = settings
function getSurveyPage(surveyalertid) {
    return queueQueries(
        `${APP_API_URL}/theraflow/medical-study-repeatable/alert/redirect/response/${surveyalertid}/`,
        'GET'
    )
}
function getTheraflowUserById(id) {
    return queueQueries(`${APP_API_URL}/theraflow/users/${id}/`, 'GET')
}
function blockTheraflowUser(id) {
    return queueQueries(`${APP_API_URL}/theraflow/users/${id}/block/`, 'GET')
}
function searchTheraflowUser(data) {
    return queueQueries(
        `${APP_API_URL}/theraflow/users/search/`,
        'GET',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

export const Alert = {
    getSurveyPage,
    getTheraflowUserById,
    blockTheraflowUser,
    searchTheraflowUser,
}
