import { t } from 'i18next'
import { h } from 'hyperapp'
import { Button } from '@app/elements'

export const ModalViewConfirm = (props, actions) => (
    <div
        class={'btzClassMarker-btz btzClassMarker-btzAutoHeight'}
        key={'configuration'}
    >
        <div class="thfTelesuiviModalBlock" style="border: none !important;">
            {props.deactivationConfirmKind === 'deactivate' ? (
                <dummy>
                    <p class="btzSubTitle" style="text-align: center">
                        {t('Vous êtes sur le point de désactiver la mesure') +
                            ' ' +
                            t(props.editingMesure.value) +
                            '. ' +
                            t('Elle ne sera plus affichée pour votre patient.')}
                    </p>
                    <p class="btzSubTitle" style="text-align: center">
                        {t('Vous pourrez la réactiver à tout moment.')}
                    </p>
                </dummy>
            ) : (
                <dummy>
                    <p class="btzSubTitle" style="text-align: center">
                        {t(
                            "Vous êtes sur le point d'arrêter le télésuivi de la mesure"
                        ) +
                            ' ' +
                            t(props.editingMesure.value) +
                            '. ' +
                            t(
                                'Votre patient pourra continuer à remonter ses mesures.'
                            )}
                    </p>
                    <p class="btzSubTitle" style="text-align: center">
                        {t('Vous pourrez la réactiver à tout moment.')}
                    </p>
                </dummy>
            )}
            <div class="btzCard-btzFooter-btzModal" style="margin-top: 40px;">
                <Button
                    anchorLeft
                    flat
                    cancel
                    onclick={() => {
                        props.onDeactivateMesureCancel()
                    }}
                >
                    {t('Annuler')}
                </Button>
                {props.deactivationConfirmKind === 'deactivate' ? (
                    <Button
                        anchorRight
                        flat
                        dangerous
                        onclick={() => {
                            props.onDeactivateMesure('deactivate')
                        }}
                    >
                        {t('Désactiver')}
                    </Button>
                ) : (
                    <Button
                        anchorRight
                        flat
                        dangerous
                        onclick={() => {
                            props.onDeactivateMesure('recallwithalerts')
                        }}
                    >
                        {t('Arrêter')}
                    </Button>
                )}
            </div>
        </div>
    </div>
)
