import { t } from 'i18next'
import { h } from 'hyperapp'
import { Form, FormInput, Calendar } from '@app/elements'
import * as constants from '@app/constants'

export const ModalViewConfigurationForm = (props, actions) => (
    <div class={'btzClassMarker-btz'} key={'configuration'}>
        <div class="thfTelesuiviModalBlock" style="border: none !important;">
            <Form onsubmit={(event) => event.preventDefault()}>
                <Form.Group>
                    <div class="col-12 thfAlignTop" style="padding-right: 0px;">
                        <p
                            class="btzForm-btzLabelTitle"
                            style="display: block;margin-bottom: 10px !important;"
                        >
                            {t(
                                "Quand souhaitez-vous informer automatiquement votre patient qu'il doit prendre sa mesure ?"
                            )}
                        </p>
                        <p class="btzForm-btzLabel">{t('Quand ?')}</p>
                        <FormInput
                            key="input-frequencies"
                            type="dropdown"
                            id="frequencies"
                            name="frequencies"
                            value={props.values.frequencies}
                            list={constants.custo.takeFrequenciesMesure}
                            validation={true}
                            withSearch={false}
                            textedArea={false}
                            oninputcb={props.retrieveInput}
                            onblurcb={props.retrieveErrorMessage}
                            formSubmittedOnce={props.formSubmittedOnce}
                            errorMessage={props.errorMessages.frequencies}
                            isTyping={props.isTyping.frequencies}
                            required
                        />
                        {props.values.frequencies === '1' && (
                            <dummy>
                                <FormInput
                                    key="input-intervalday"
                                    type="int"
                                    id="intervalday"
                                    name="intervalday"
                                    value={props.values.intervalday}
                                    validation={true}
                                    isInteger={true}
                                    oninputcb={props.retrieveInput}
                                    onblurcb={props.retrieveErrorMessage}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={
                                        props.errorMessages.intervalday
                                    }
                                    isTyping={props.isTyping.intervalday}
                                    required
                                />
                                <FormInput
                                    key="input-interval"
                                    type="dropdown"
                                    id="interval"
                                    name="interval"
                                    value={props.values.interval}
                                    list={constants.custo.periodsFrequencies}
                                    validation={true}
                                    withSearch={false}
                                    textedArea={false}
                                    oninputcb={props.retrieveInput}
                                    onblurcb={props.retrieveErrorMessage}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={props.errorMessages.interval}
                                    isTyping={props.isTyping.interval}
                                    required
                                />
                            </dummy>
                        )}
                        {props.values.frequencies === '3' && (
                            <FormInput
                                key="input-days"
                                type="dropdown"
                                id="days"
                                name="days"
                                value={props.values.days}
                                list={constants.custo.daysFrequencies}
                                validation={true}
                                withSearch={false}
                                textedArea={false}
                                multiselect={true}
                                oninputcb={props.retrieveInput}
                                onblurcb={props.retrieveErrorMessage}
                                formSubmittedOnce={props.formSubmittedOnce}
                                errorMessage={props.errorMessages.days}
                                isTyping={props.isTyping.days}
                                required
                            />
                        )}
                        {props.values.frequencies === '2' && (
                            <div
                                class="col-12 thfAlignTop thfAlignTopCalendar"
                                style="width: auto;vertical-align: middle;padding: 0px;"
                            >
                                <Calendar
                                    oninput={props.retrieveInput}
                                    onblur={props.retrieveErrorMessage}
                                    key={'before'}
                                    name={'before'}
                                    calendarDate={props.values.before}
                                    deletetable={false}
                                    isValid={props.isValid.before}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={props.errorMessages.before}
                                />
                            </div>
                        )}
                    </div>
                    {props.values.frequencies !== '2' && (
                        <dummy>
                            <div class="col-12 thfAlignTop">
                                <p class="btzForm-btzLabel">{t('Période')}</p>
                                <FormInput
                                    key="input-dailySchedule"
                                    type="dropdown"
                                    id="dailySchedule"
                                    name="dailySchedule"
                                    value={props.values.dailySchedule}
                                    list={constants.custo.takePeriods}
                                    validation={true}
                                    withSearch={false}
                                    textedArea={false}
                                    multiselect={true}
                                    oninputcb={props.retrieveInput}
                                    onblurcb={props.retrieveErrorMessage}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={
                                        props.errorMessages.dailySchedule
                                    }
                                    isTyping={props.isTyping.dailySchedule}
                                    required
                                />
                            </div>
                            <div class="col-12 thfAlignTop thfAlignTopCalendar">
                                <Calendar
                                    oninput={props.retrieveInput}
                                    onblur={props.retrieveErrorMessage}
                                    key={'start'}
                                    name={'start'}
                                    title={t('Date de début')}
                                    calendarDate={props.values.start}
                                    deletetable={false}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={props.errorMessages.start}
                                    isValid={props.isValid.start}
                                />
                            </div>
                            <div class="col-12 thfAlignTop thfAlignTopCalendar">
                                <Calendar
                                    oninput={props.retrieveInput}
                                    onblur={props.retrieveErrorMessage}
                                    key={'end'}
                                    name={'end'}
                                    title={t('Date de fin')}
                                    calendarDate={props.values.end}
                                    deletetable={true}
                                    optional={true}
                                    min={props.values.start}
                                    formSubmittedOnce={props.formSubmittedOnce}
                                    errorMessage={props.errorMessages.end}
                                    isValid={props.isValid.end}
                                />
                            </div>
                        </dummy>
                    )}
                </Form.Group>
            </Form>
        </div>
    </div>
)
