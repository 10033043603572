import { t } from 'i18next'
import { h } from 'hyperapp'
import { Form, FormInput } from '@app/elements'

export const ModalViewConfigurationWidget = (props, actions) => (
    <div
        class={'btzClassMarker-btz btzClassMarker-btzWidget'}
        key={'configuration'}
    >
        <div class="thfTelesuiviModalBlock" style="border: none !important;">
            <Form>
                <Form.Group>
                    <div class="thfAlignTop">
                        <p class="">
                            {t(
                                "Souhaitez vous activer l'outil de suivi Hydratation pour votre patient? Fonction du poids de votre patient un objectif de quantité d'eau  à consommer dans la journée sera fixé selon la formule suivante: [Poids] x 35ml = [Objectif en Litres]"
                            )}
                        </p>
                        <FormInput
                            key={'input-status'}
                            type="switch"
                            id={'status'}
                            name={'status'}
                            value={props.widgetSetting['HYDRATION_WIDGET']}
                            list={[
                                { label: t('Oui, activer'), key: '1' },
                                { label: t('Non, désactiver'), key: '0' },
                            ]}
                            oninputcb={props.retrieveInputWidget}
                            required
                        />
                    </div>
                </Form.Group>
            </Form>
        </div>
    </div>
)
