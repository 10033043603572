import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { APP_API_URL } = settings;

function getHospitals() {
    return queueQueries(`${APP_API_URL}/theraflow/hospitals/`, 'GET');
}

function checkCHU(name = '', data = {}) {
    if (name !== '') {
        return queueQueries(`${APP_API_URL}/${name}/`, 'POST', 'application/json', true, true, true, true, true, data);
    }
}

export const Hospital = {
    getHospitals,
    checkCHU,
};