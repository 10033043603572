import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined } from '@app/core';
import { Card, Enter } from '@app/elements';
import * as constants from '@app/constants';

import TelesuiviIc from './../subpages/telesuiviic';
import Etp from './../subpages/etp';
import Profil from './../subpages/profil';
import Treatment from './../subpages/treatment';
import Study from './../subpages/study';
import Survey from './../subpages/survey';

import { HeaderViewPatient } from './headers/headerView-default';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class=''>
                {state.loaded &&
                    <dummy>
                        <Card classes={'btzPageCard'} kind={'datas'}>
                            <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                    {isDefined(state.editedPatient) &&
                                        <HeaderViewPatient {...state} customer={props.customer} setModalDisplayed={actions.setModalDisplayed} setModalEditing={actions.setModalEditing} onTwilioCall={actions.onTwilioCall} onTwilioHangup={actions.onTwilioHangup} onTwilioConfirmHangup={actions.onTwilioConfirmHangup} onTwilioCancelHangup={actions.onTwilioCancelHangup} updateCurrentPage={actions.updateCurrentPage} />
                                    }
                                    <div class='btzCard-btzHeader-btzPage-btzSubMenu' style='margin-top: 50px'>
                                        <div class={((state.currentPage === 'profil') ? 'selected' : '')} onclick={() => { actions.switchingPage('profil') }}>{t('Profil')}</div>
                                        {(state.editedPatient.customer.customerModules.studySurveyResult === true) &&
                                            <div class={((state.currentPage === 'survey') ? 'selected' : '')} onclick={() => { actions.switchingPage('survey') }}>{t('Rapport de santé')}</div>
                                        }
                                        {((state.editedPatient.customer.customerModules.monitoring === true) && (isDefined(state.editedPatient.customer.customerModules.monitoringSettings.filter((f) => (f.name === state.ICMesure))[0])) && (state.editedPatient.customer.customerModules.monitoringSettings.filter((f) => (f.name === state.ICMesure))[0].status === true)) &&
                                            <div class={((state.currentPage === 'telesuiviic') ? 'selected' : '')} onclick={() => { actions.switchingPage('telesuiviic') }}>{t('Télésuivi IC')}</div>
                                        }
                                        {((state.editedPatient.customer.customerModules.etp === true) || (state.editedPatient.customer.customerModules.programETP === true)) &&
                                            <div class={((state.currentPage === 'etp') ? 'selected' : '')} onclick={() => { actions.switchingPage('etp') }}>{t('Apprentissages')}</div>
                                        }
                                        {(state.editedPatient.customer.customerModules.treatment === true) &&
                                            <div class={((state.currentPage === 'treatment') ? 'selected' : '')} onclick={() => { actions.switchingPage('treatment') }}>{t('Traitements')}</div>
                                        }
                                        {((state.editedPatient.customer.customerModules.study === true) && (state.studies.length > 0)) &&
                                            <div class={((state.currentPage === 'study') ? 'selected' : '')} onclick={() => { actions.switchingPage('study') }}>{t('Questionnaires')}</div>
                                        }
                                    </div>
                                </Enter>
                            </Card.Header>
                        </Card>

                        <div key={state.currentPage} class={'root-' + state.currentPage}>
                            {state.currentPage === 'survey' &&
                                <Survey {...state} customer={props.customer} updatePatient={actions.getTheraflowUser} setAtLeastOneModification={actions.setAtLeastOneModification} />
                            }
                            {state.currentPage === 'telesuiviic' &&
                                <TelesuiviIc {...state} customer={props.customer} updatePatient={actions.getTheraflowUser} setAtLeastOneModification={actions.setAtLeastOneModification} />
                            }
                            {state.currentPage === 'etp' &&
                                <Etp {...state} customer={props.customer} updatePatient={actions.getTheraflowUser} setAtLeastOneModification={actions.setAtLeastOneModification} />
                            }
                            {state.currentPage === 'treatment' &&
                                <Treatment {...state} customer={props.customer} updatePatient={actions.getTheraflowUser} setAtLeastOneModification={actions.setAtLeastOneModification} />
                            }
                            {state.currentPage === 'profil' &&
                                <Profil {...state} customer={props.customer} updatePatient={actions.getTheraflowUser} setAtLeastOneModification={actions.setAtLeastOneModification} parseHospitalHealTeam={actions.parseHospitalHealTeam} />
                            }
                            {state.currentPage === 'study' &&
                                <Study {...state} customer={props.customer} updatePatient={actions.getTheraflowUser} setAtLeastOneModification={actions.setAtLeastOneModification} updateParentState={actions._setState} />
                            }
                        </div>
                    </dummy>
                }
            </div>
        );
        return view;
    }
}