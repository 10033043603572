import { t } from 'i18next'
import { Admin, Hospital } from '@app/api'
import { displayErrors } from '@app/api/errors'
import { allRoutes } from '@app/core'
import { settings } from '@app/core/settings'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import * as constants from '@app/constants'

const { APP_CUSTOMISATION, APP_FRONT_WEB_URL } = settings

Math.fmod = function (a, b) {
    return Number((a - Math.floor(a / b) * b).toPrecision(8))
}

Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1)
    var jul = new Date(this.getFullYear(), 6, 1)
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset()
}

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000)
    return this
}
Date.prototype.addMinutes = function (m) {
    this.setTime(this.getTime() + m * 60 * 1000)
    return this
}

const $ = function (selector) {
    if (!selector) {
        return null
    }

    const els = document.querySelectorAll(selector)
    return selector.indexOf('#') > -1 ? els[0] : els
}

const scrollTo = function (element, to, duration, direction = 'vertical') {
    if (duration <= 0) {
        return
    }
    if (element === null) {
        element = document.scrollingElement || document.documentElement
    }
    var difference =
        to - (direction === 'vertical' ? element.scrollTop : element.scrollLeft)
    var perTick = (difference / duration) * 10

    // document.scrollingElement || document.documentElement
    setTimeout(function () {
        if (direction === 'vertical') {
            element.scrollTop = element.scrollTop + perTick
            if (element.scrollTop === to) {
                return
            }
        } else {
            element.scrollLeft = element.scrollLeft + perTick
            if (element.scrollLeft === to) {
                return
            }
        }
        scrollTo(element, to, duration - 10, direction)
    }, 10)
}

const getClosest = function (elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                var matches = (
                        this.document || this.ownerDocument
                    ).querySelectorAll(s),
                    i = matches.length
                while (--i >= 0 && matches.item(i) !== this) {
                    //
                }
                return i > -1
            }
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem
    }
    return null
}

const createDateComplientSafari = function (date, withOffset = false) {
    if (!isDefined(date)) {
        date = moment().format()
    }
    // if ((date.indexOf('Z') === -1) && (date.indexOf('GMT') === -1)) {
    //     date = date.split(' ');
    //     date = date[0] + 'T' + date[1] + 'Z';
    // }
    let newDate = moment(date)
    if (withOffset) {
        newDate.add(newDate.utcOffset() / 60, 'hours')
    }
    return newDate
}

const getYMDfromDateObj = function (
    obj,
    readable = false,
    ymd = false,
    format = {
        year: true,
        shortyear: false,
        month: true,
        day: true,
        hour: false,
    }
) {
    // if (!obj || (Object.prototype.toString.call(obj) !== '[object Date]')) {
    if (!obj || !moment.isMoment(obj)) {
        let tempObj = moment(obj)
        if (tempObj.isValid()) {
            obj = tempObj
        } else {
            obj = moment()
        }
    }
    let utcDate = createUTCDate(obj, false)
    obj = obj.toDate()

    let dateDay = obj.getDate() // TO SURVEY //!!\\
    let dateMonth = obj.getMonth() + 1
    let dateYear = obj.getFullYear()
    if (+dateDay < 10) {
        dateDay = '0' + dateDay
    }
    if (+dateMonth < 10) {
        dateMonth = '0' + dateMonth
    }
    let dateTime = ''
    if (format.hour) {
        let utcTime =
            (+utcDate.hour() < 10 ? '0' + +utcDate.hour() : utcDate.hour()) +
            ':' +
            (+utcDate.minutes() < 10
                ? '0' + +utcDate.minutes()
                : utcDate.minutes()) +
            ':' +
            (+utcDate.seconds() < 10
                ? '0' + +utcDate.seconds()
                : utcDate.seconds())
        dateTime = ' ' + t('à') + ' ' + utcTime
    }

    if (format.shortyear) {
        dateYear = String(dateYear).substring(2, 4)
    }

    if (readable) {
        if (ymd) {
            return dateYear + '' + dateMonth + '' + dateDay + dateTime
        }
        if (format.year && format.month && format.day) {
            return dateDay + '.' + dateMonth + '.' + dateYear + dateTime
        } else {
            if (format.month && format.day) {
                return dateDay + '.' + dateMonth + dateTime
            } else if (format.year && format.month) {
                return dateMonth + '.' + dateYear + dateTime
            }
        }
    }

    return {
        day: dateDay,
        month: dateMonth,
        year: dateYear,
        time: dateTime,
    }
}

// const getDateObjFromYMD = function (ymd) {
//     if (!ymd) {
//         return null;
//     }

//     let dateYear = ymd.substring(0, 4);
//     let dateMonth = ymd.substring(4, 6);
//     let dateDay = ymd.substring(6, 8);

//     var parsedDate = {
//         // date: new Date(dateYear + '-' + dateMonth + '-' + dateDay),
//         date: dateYear + '-' + dateMonth + '-' + dateDay,
//         timezone: 'UTC',
//         timezone_type: 3
//     }

//     return parsedDate;
// }

const getMinHourFromMinutes = function (min) {
    let hours = Math.floor(min / 60)
    let minutes = Math.round(min % 60)

    if (minutes < 10) {
        minutes = '0' + minutes
    }

    return { min: minutes, h: hours }
}

const sanitize = function (string) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
        '`': '&grave;',
    }
    const reg = /[&<>"'/]/gi
    return string.replace(reg, (match) => map[match])
}
const getQueryVariable = function (variable, editQuery = true, brut = false) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
            if (editQuery) {
                window.history.pushState(
                    '',
                    '',
                    removeURLParameter(
                        window.location.pathname + window.location.search,
                        variable
                    )
                )
            }
            if (brut) {
                return pair[1]
            }
            return decodeURI(sanitize(pair[1]))
        }
    }

    return null
}

const removeURLParameter = function (url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?')
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '='
        var pars = urlparts[1].split(/[&;]/g)

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0; ) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1)
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
        return url
    } else {
        return url
    }
}

const createCookie = function (name, value, days) {
    var expires = null
    if (days) {
        var date = new Date()
        date.setTime(date.valueOf() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toGMTString()
    } else {
        expires = ''
    }
    document.cookie = name + '=' + value + expires + '; path=/'
}

const readCookie = function (name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

const eraseCookie = function (name) {
    createCookie(name, '', -1)
}

const isPublicRoute = function () {
    var currentUrl = window.location.href
    var publicRoutes = ['register', 'mentions', 'pdf', 'chv', 'ijb', 'recovery']
    // var notPublicRoutes = [];
    var isPublic = false
    if (window.location.pathname === '/') {
        isPublic = true
    } else {
        for (var route in publicRoutes) {
            if (currentUrl.indexOf(publicRoutes[route]) > -1) {
                isPublic = true
            }
        }
        // for (var notroute in notPublicRoutes) {
        //     if (currentUrl.indexOf(notPublicRoutes[notroute]) > -1) {
        //         isPublic =  false;
        //     }
        // }
    }
    return isPublic
}

const isAuthorizedRole = function (mode = 'crud') {
    if (isPublicRoute()) {
        return true
    }
    //
    let sessionUser = sessionStorage.getItem('adminuser')
    sessionUser = getUserRole(JSON.parse(sessionUser))
    if (!isDefined(sessionUser)) {
        return false
    }
    //
    if (mode === 'crud') {
        if (sessionUser.role === 'promoter') {
            return false
        }
    }
    return true
}

const thenCheckForValidCSRFToken = function () {
    var isValid = true
    var token = getQueryVariable('csrftoken', false)
    if (!token || token === '') {
        token = getQueryVariable('token', false)
        if (!token || token === '') {
            displayErrors('The provided token is corrupted or missing.', 2000)
            isValid = false
        }
    }

    var localToken = sessionStorage.getItem('csrftoken')
    if (token !== localToken) {
        displayErrors('The provided token does not match expected one.', 2000)
        isValid = false
    }

    if (!isValid) {
        setTimeout(function () {
            logoutAndRedirect()
        }, 1500)
        return false
    }

    return true
}

const checkForValidCSRFToken = function () {
    if (isPublicRoute()) {
        return true
    }
    let xtoken = getQueryVariable('xtoken', false, true)
    if (isDefined(xtoken) && xtoken != false) {
        localStorage.setItem('apitoken', xtoken)
        if (!isDefined(sessionStorage.getItem('csrftoken'))) {
            let urlLanguage = getQueryVariable('lang', false)
            if (isDefined(urlLanguage) && urlLanguage != false) {
                sessionStorage.setItem('urlLanguage', urlLanguage)
            }
            createAndSaveCSRFToken()
            window.location.href = putCSRFTokenInURL()
        }
        return true
    } else {
        return thenCheckForValidCSRFToken()
    }
}

const addCSRFToken = function (string) {
    var existingCsrftoken = sessionStorage.getItem('csrftoken')
    if (!isDefined(existingCsrftoken)) {
        createAndSaveCSRFToken()
    }
    if (!checkForValidCSRFToken()) {
        return string
    }
    return putCSRFTokenInURL(string)
}

const putCSRFTokenInURL = function (string = null) {
    if (!isDefined(string)) {
        string = window.location.href
    }
    var localToken = sessionStorage.getItem('csrftoken')
    var querySeparator = string.indexOf('?') > -1 ? '&' : '?'
    if (string.match('/[?&](csrftoken)=[^&]*/') !== null) {
        // eslint-disable-next-line
        string = string.replace('/([?&]csrftoken)=[^&]*/', $token)
    } else {
        if (localToken !== null) {
            localToken = localToken.replace(/"/g, '')
        }
        string += querySeparator + 'csrftoken=' + localToken
    }
    return string
}

const createAndSaveCSRFToken = function () {
    var existingCsrftoken = sessionStorage.getItem('csrftoken')
    if (
        existingCsrftoken !== null &&
        existingCsrftoken !== '' &&
        existingCsrftoken !== undefined
    ) {
        existingCsrftoken = existingCsrftoken.replace(/"/g, '')
        return existingCsrftoken
    }
    var csrfToken = guid()
    csrfToken = csrfToken.replace(/"/g, '')
    sessionStorage.setItem('csrftoken', csrfToken)
    return csrfToken
}

const guid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
    }
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4()
}

const convertMetrics = function (from, type, metric, value) {
    if (+metric === 0 || value === null) {
        if (from === 'metric') {
            return value
        }
        return value + type
    }

    metric = +metric
    if (from === 'weight') {
        if (metric === 1) {
            let stone = Math.floor(value / 6.35029)
            let remainingPound = Math.fmod(value, 6.35029)
            let pound = remainingPound / 0.4536

            value = stone + '.' + Math.floor(pound)
        } else {
            value = value * 2.2046
        }
    } else if (from === 'height') {
        if (metric === 1 || metric === 2) {
            let feet = Math.floor(value / 30.48)
            let remainingInch = Math.fmod(value, 30.48)
            let inch = remainingInch / 2.54

            value = feet + '.' + Math.floor(inch)
        } else {
            value = Math.round(value, 1)
        }
    } else if (from === 'metric') {
        if (type === 'weight') {
            if (metric === 1) {
                let values = value.split('.')
                let stone = values[0]
                let pound = 0
                if (values.length > 1) {
                    pound = values[1]
                }
                stone = +stone * 6.35029
                pound = +pound * 0.4536
                value = stone + pound
            } else {
                value = value / 2.2046
            }
            type = ''
        } else if (type === 'height') {
            if (metric === 1 || metric === 2) {
                let values = value.split('.')
                let feet = values[0]
                let inch = 0
                if (values.length > 1) {
                    inch = values[1]
                }
                feet = +feet * 30.48
                inch = +inch * 2.54
                value = feet + inch
            } else {
                //
            }
            type = ''
        }
    }

    if (from != 'metric') {
        type = convertMetricsLabels(
            +type.length <= 2 ? true : false,
            from,
            metric,
            type
        )
        return Math.round(value * 10) / 10 + type
    } else {
        return Math.round(value * 10) / 10
    }
}

const convertMetricsLabels = function (short, from, metric, value) {
    if (+metric === 0) {
        return value
    }

    let toreplace = null
    if (short) {
        if (from === 'weight') {
            toreplace = +metric === 1 ? 'st' : 'lb'
            value = value.replace('kg', toreplace)
        } else {
            toreplace = +metric === 1 ? 'ft' : 'Ø'
            value = value.replace('cm', toreplace)
        }
    } else {
        if (from === 'weight') {
            toreplace = +metric === 1 ? 'stone' : 'pound'
            value = value.replace('kilogramme', toreplace)
        } else {
            toreplace = +metric === 1 ? 'feet' : 'Ø'
            value = value.replace('centimètre', toreplace)
        }
    }

    return value
}

const capitalize = function (text) {
    return text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
}

const getUser = function (callback, force = false) {
    let sessionUser = sessionStorage.getItem('adminuser')
    if (
        sessionUser === null ||
        sessionUser === undefined ||
        sessionUser === 'false' ||
        force
    ) {
        Admin.getUser().then((res) => {
            if (!isDefined(res)) {
                localStorage.removeItem('apitoken')
                if (callback !== null) {
                    callback(null)
                }
                return false
            }
            sessionUser = res.data
            if (
                isDefined(sessionUser) &&
                isDefined(sessionUser.media) &&
                isDefined(sessionUser.media.url)
            ) {
                if (sessionUser.media.name == 'DEFAULT') {
                    sessionUser.media.url = null
                }
            }
            sessionUser = getUserRole(sessionUser)
            Hospital.getHospitals().then((res) => {
                if (isDefined(res.data) && Array.isArray(res.data)) {
                    let refHosp = res.data.filter(
                        (f) => f.name === sessionUser.customer
                    )[0]
                    if (!isDefined(refHosp)) {
                        refHosp = res.data.filter(
                            (f) =>
                                isDefined(f.parent) &&
                                f.parent.name === sessionUser.customer
                        )[0]
                    }
                    if (isDefined(refHosp)) {
                        refHosp.customerModules.weightModule = false
                        for (let monitoringSetting in refHosp.customerModules
                            .monitoringSettings) {
                            if (
                                refHosp.customerModules.monitoringSettings[
                                    monitoringSetting
                                ].name === 'CryptedWeight' &&
                                refHosp.customerModules.monitoringSettings[
                                    monitoringSetting
                                ].status === true
                            ) {
                                refHosp.customerModules.weightModule = true
                            }
                        }
                    }
                    if (isDefined(refHosp)) {
                        sessionUser.refHosp = refHosp
                    }
                }
                sessionStorage.setItem(
                    'adminuser',
                    JSON.stringify(parseDoctorRoles(sessionUser))
                )
                if (callback !== null) {
                    callback(sessionUser)
                }
            })
        })
    } else {
        sessionUser = getUserRole(JSON.parse(sessionUser))
        if (callback !== null) {
            callback(sessionUser)
        }
    }
}

const parseJsonObjectToString = function (obj) {
    return JSON.stringify(obj)
}
const parseStringToJsonObj = function (obj) {
    return JSON.parse(obj)
}

const getUserRole = function (user) {
    if (isDefined(user)) {
        user.role = 'user'
        let userRoles = user.roles
        if (isDefined(userRoles)) {
            if (!Array.isArray(userRoles)) {
                userRoles = Object.values(userRoles)
            }
            //
            if (user.roles.indexOf('ROLE_DOCTOR') > -1) {
                user.role = 'cardio'
            } else if (userRoles.indexOf('ROLE_NURSE') > -1) {
                user.role = 'nurse'
            } else if (userRoles.indexOf('ROLE_COACH') > -1) {
                user.role = 'coach'
            } else if (userRoles.indexOf('ROLE_MEDICAL_PROMOTER') > -1) {
                user.role = 'promoter'
            }
        }
    }
    return user
}

const setUser = function (user) {
    sessionStorage.setItem('adminuser', JSON.stringify(parseDoctorRoles(user)))
    return user
}

const parseDoctorRoles = function (doctor) {
    let doctorRoles = doctor.roles
    if (isDefined(doctorRoles)) {
        if (!Array.isArray(doctorRoles)) {
            doctorRoles = Object.values(doctorRoles)
        }
        //
        doctor.readableRole = 'user'
        if (doctorRoles.indexOf('ROLE_DOCTOR') > -1) {
            doctor.readableRole = 'cardio'
        } else if (doctorRoles.indexOf('ROLE_COACH') > -1) {
            doctor.readableRole = 'coach'
        } else if (
            doctorRoles.indexOf('ROLE_NURSE') > -1 ||
            doctor.roles.indexOf('ROLE_MEDICAL_SUPERVISOR') > -1
        ) {
            doctor.readableRole = 'nurse'
        } else if (doctorRoles.indexOf('ROLE_MEDICAL_PROMOTER') > -1) {
            doctor.readableRole = 'promoter'
        }
    }
    return doctor
}

const compareByKey = function (key, order = 'asc') {
    return function (a, b) {
        if (
            !Object.prototype.hasOwnProperty.call(a, key) ||
            !Object.prototype.hasOwnProperty.call(b, key)
        ) {
            // property doesn't exist on either object
            return 0
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
            comparison = 1
        } else if (varA < varB) {
            comparison = -1
        }
        return order == 'desc' ? comparison * -1 : comparison
    }
}

const trimSpecialChar = function (str) {
    let dict = {
        á: 'a',
        à: 'a',
        â: 'a',
        ç: 'c',
        é: 'e',
        è: 'e',
        ê: 'e',
        ë: 'e',
        ù: 'u',
        û: 'u',
        ô: 'o',
    }
    if (Array.isArray(str)) {
        for (var entry in str) {
            if (typeof str[entry].replace === 'function') {
                str[entry] = str[entry].replace(/[^\w ]/g, function (char) {
                    return dict[char] || char
                })
            }
        }
    } else {
        if (typeof str.replace === 'function') {
            str = str.replace(/[^\w ]/g, function (char) {
                return dict[char] || char
            })
        }
    }
    return str
}

const isFloat = function (n) {
    // if ((n === '') || (n === null) || (n === undefined)) {
    //     return false;
    // }
    // return n === +n && n !== (n|0);
    if (n === '' || n === null || n === undefined) {
        return false
    }
    return !isNaN(n)
}

const isInteger = function (n) {
    // return n === +n && n === (n|0);
    if (
        n === '' ||
        n === null ||
        n === undefined ||
        String(n).indexOf('.') > -1 ||
        String(n).indexOf(',') > -1
    ) {
        return false
    }
    return !isNaN(n)
}

const castToNumberOfFloat = function (str) {
    if (str === null || str === undefined || str === '' || isNaN(str)) {
        return null
    } else if (isInteger(str) || isFloat(str)) {
        return str
    } else if (str.indexOf('.') > -1 || str.indexOf(',') > -1) {
        return parseFloat(str)
    } else {
        return parseInt(str)
    }
}

const redirectToWebOrApp = function () {
    let schemelink = getQueryVariable('schemelink', false)
    if (!schemelink) {
        schemelink = getQueryVariable('scheme', false)
    }
    if (isMobile() && isDefined(schemelink) && schemelink !== '') {
        window.location.href = schemelink
    } else {
        redirectToIndex()
    }
}

const redirectToGoodIndex = function (params = '') {
    //
    getUser((doctor) => {
        let userconsent = localStorage.getItem('userconsent')
        //
        if (userconsent || userconsent === 'true') {
            let redirect = getQueryVariable('redirect', false)
            if (isDefined(redirect) && redirect != false) {
                let redirectRoute =
                    allRoutes[redirect].pathname + '?redirect=true'
                if (redirectRoute.indexOf(':id') > -1) {
                    let userId = getQueryVariable('userId', false)
                    redirectRoute = redirectRoute.replace(':id', userId)
                }
                let sp = getQueryVariable('sp', false)
                if (isDefined(sp) && sp != false) {
                    redirectRoute += '&sp=' + sp
                }
                let trgid = getQueryVariable('trgid', false)
                if (isDefined(trgid) && trgid != false) {
                    redirectRoute += '&trgid=' + trgid
                }
                let strgid = getQueryVariable('strgid', false)
                if (isDefined(strgid) && strgid != false) {
                    redirectRoute += '&strgid=' + strgid
                }
                let surveyName = getQueryVariable('surveyName', false)
                if (isDefined(surveyName) && surveyName != false) {
                    redirectRoute += '&surveyName=' + surveyName
                }
                let aid = getQueryVariable('aid', false)
                if (isDefined(aid) && aid != false) {
                    redirectRoute += '&aid=' + aid
                }
                redirect = redirectRoute
            } else {
                redirect = null
            }
            //
            localStorage.removeItem('userconsent')
            Hospital.getHospitals().then((res) => {
                if (isDefined(res.data)) {
                    let refHosp = res.data.filter(
                        (f) => f.name === doctor.customer
                    )[0]
                    if (isDefined(refHosp)) {
                        if (
                            isDefined(
                                refHosp.customerModules.studySurveyResult
                            ) &&
                            refHosp.customerModules.studySurveyResult === true
                        ) {
                            window.location.href = addCSRFToken(
                                redirect ||
                                    allRoutes['private.alertsrds.listing']
                                        .pathname + params
                            )
                            return false
                        }
                        if (
                            isDefined(refHosp.customerModules.study) &&
                            refHosp.customerModules.study === true
                        ) {
                            window.location.href = addCSRFToken(
                                redirect ||
                                    allRoutes['private.alertscurve.listing']
                                        .pathname + params
                            )
                            return false
                        }
                    }
                }
                //
                window.location.href = addCSRFToken(
                    redirect ||
                        allRoutes['private.patients.listing'].pathname + params
                )
            })
        } else {
            window.location.href = addCSRFToken(
                allRoutes['private.consent'].pathname + params
            )
        }
    })
}

const isMobile = function () {
    var check = false
    // eslint-disable-next-line
    ;(function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    return check
}

const isMobileOrTablet = function () {
    var check = false
    // eslint-disable-next-line
    ;(function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    return check
}

// function parseDate(date) {
//     //
//     const parsed = Date.parse(date);
//     if (!isNaN(parsed)) {
//         return parsed;
//     }
//     return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
// }

// const today = new Date();
const today = moment()
const fromNow = function (date) {
    let sufix = ''
    if (date === null) {
        return '/'
    }
    // construct this fucking dumb date for this stupid safari that only create GMT date
    let tmpDate = date
    tmpDate = tmpDate.split('.')[0].split(' ')
    let tmpDateDate = tmpDate[0]
    tmpDateDate = tmpDateDate.split('-')
    let tmpDateHours = tmpDate[1]
    tmpDateHours = tmpDateHours.split(':')
    // let fuckinDate = new Date(+tmpDateDate[0], (+tmpDateDate[1] - 1), +tmpDateDate[2], +tmpDateHours[0], +tmpDateHours[1], +tmpDateHours[2]);
    let fuckinDate = moment(
        +tmpDateDate[0],
        +tmpDateDate[1] - 1,
        +tmpDateDate[2],
        +tmpDateHours[0],
        +tmpDateHours[1],
        +tmpDateHours[2]
    )
    //
    var delta = Math.abs(fuckinDate.valueOf() - today.valueOf()) / 1000
    var diff = ''
    var days = Math.floor(delta / 86400)
    delta -= days * 86400
    var hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600
    var minutes = Math.floor(delta / 60) % 60
    delta -= minutes * 60
    var seconds = Math.round(delta % 60)
    //
    if (days < 1) {
        if (hours < 1) {
            if (minutes < 1) {
                diff = seconds
                sufix = +seconds >= 2 ? t('secondes') : t('seconde')
            } else {
                diff = minutes
                sufix = +minutes >= 2 ? t('minutes') : t('minute')
            }
        } else {
            diff = hours
            sufix = +hours >= 2 ? t('heures') : t('heure')
        }
    } else {
        diff = days
        sufix = +days >= 2 ? t('jours') : t('jour')
    }
    return diff + ' ' + sufix
}

const deepCopy = function (src) {
    let target = Array.isArray(src) ? [] : {}
    for (let key in src) {
        let v = src[key]
        if (v) {
            if (typeof v === 'object') {
                if (v.lastModified !== undefined) {
                    target[key] = v // to keep File object intact
                } else {
                    target[key] = deepCopy(v)
                }
            } else {
                target[key] = v
            }
        } else {
            target[key] = v
        }
    }
    return target
}
const valueCopy = function (src) {
    return JSON.parse(JSON.stringify(src))
}

const createQueryMessage = function (success, type, kind) {
    let mssgPrevix =
        kind === 'create'
            ? t('Création')
            : kind === 'update'
              ? t('Mise à jour')
              : t('Suppression')
    if (!success) {
        mssgPrevix =
            kind === 'create'
                ? t('Échec de la création')
                : kind === 'update'
                  ? t('Échec de la mise à jour')
                  : t('Échec de la suppression')
    }
    let mssgCard = t('de la carte')
    if (type !== null && type !== undefined) {
        if (type === 'evaluation_generique') {
            mssgCard = t('de l‘évaluation classique')
        } else if (type === 'evaluation_comportement') {
            mssgCard = t('de l‘évaluation comportement')
        } else if (type === 'evaluation_survey') {
            mssgCard = t('de l‘évaluation questionnaire')
        } else if (type === 'accompagnement_generique') {
            mssgCard = t('du conseil')
        } else if (type === 'article') {
            mssgCard = t('de l‘article')
        } else if (type === 'recipe') {
            mssgCard = t('de la recette')
        } else if (type === 'bandeau_image') {
            mssgCard = t('du bandeau image')
        } else if (type === 'hello') {
            mssgCard = t('de la carte bonjour')
        } else if (type === 'citation') {
            mssgCard = t('de la citation')
        } else if (type === 'coup_de_pouce') {
            mssgCard = t('de la carte coup de pouce')
        } else if (type === 'dashboard_info') {
            mssgCard = t('de la carte dashboard')
        } else if (type === 'mail_hebdo') {
            mssgCard = t('du mail hebdo')
        } else if (type === 'partenaire') {
            mssgCard = t('de la carte partenaire')
        } else if (type === 'program') {
            mssgCard = t('du programme')
        } else if (type === 'quizz') {
            mssgCard = t('du quizz')
        } else if (type === 'objective') {
            mssgCard = t('de l‘objectif')
        } else if (type === 'alert') {
            mssgCard = t('de l‘alerte')
        } else if (type === 'mailstory') {
            mssgCard = t('de l‘email')
        } else if (type === 'coach') {
            mssgCard = t('du coach')
        } else if (type === 'aliment') {
            mssgCard = t('de l‘aliment')
        } else if (type === 'user') {
            mssgCard = t('de l‘utilisateur')
        } else if (type === 'customer') {
            mssgCard = t('de l‘entreprise')
        } else if (type === 'reward') {
            mssgCard = t('de l‘action')
        } else if (type === 'rew-promotions') {
            mssgCard = t('de la promotion')
        } else if (type === 'gamif-badge') {
            mssgCard = t('du badge')
        } else if (type === 'rew-actions') {
            mssgCard = t('de l‘action')
        } else if (type === 'rew-level') {
            mssgCard = t('du niveau')
        } else if (type === 'formulaire_generique') {
            mssgCard = t('du formulaire classique')
        } else if (type === 'formulaire_comportement') {
            mssgCard = t('du formulaire comportement')
        } else if (type === 'status') {
            mssgCard = t('du statut')
        } else if (type === 'frequency') {
            mssgCard = t('de la fréquence')
        } else if (type === 'shown') {
            mssgCard = t('de la visibilité')
        } else if (type === 'forum-topic') {
            mssgCard = t('du topic')
        } else if (type === 'forum-tag') {
            mssgCard = t('du tag')
        } else if (type === 'calendar') {
            mssgCard = t('du calendrier')
        } else if (type === 'establishments') {
            mssgCard = t('de l‘établissement')
        } else if (type === 'nurses') {
            mssgCard = t('du soignant')
        } else if (type === 'patients') {
            mssgCard = t('du patient')
        } else if (type === 'mass-treatment') {
            mssgCard = t('par lot')
        } else if (['defis', 'challenge'].indexOf(type.toLowerCase()) > -1) {
            mssgCard = t('du défis')
        } else if (type === 'resetpasswordUser') {
            mssgCard = ''
            if (success) {
                mssgPrevix = t(
                    'Mail de réinitialisation de mot de passe envoyé'
                )
            }
        } else if (type === 'cleartimelineUser') {
            mssgCard = ''
            if (success) {
                mssgPrevix = t('Timeline vidée')
            }
        } else if (['deleteUser', 'resiliateUser'].indexOf(type) > -1) {
            mssgCard = t("de l'utilisateur")
        } else if (['deleteCustomer', 'resiliateCustomer'].indexOf(type) > -1) {
            mssgCard = t("de l'entreprise")
        } else if (
            ['deleteEstablishment', 'resiliateEstablishment'].indexOf(type) > -1
        ) {
            mssgCard = t('de l‘établissement')
        } else if (['deleteNurse', 'resiliateNurse'].indexOf(type) > -1) {
            mssgCard = t('du soignant')
        } else if (['deletePatient', 'resiliatePatient'].indexOf(type) > -1) {
            mssgCard = t('du patient')
        }
    }
    let mssgContent = mssgPrevix + ' ' + mssgCard
    return { type: success ? 'success' : 'error', message: mssgContent }
}

const isEmail = function (string) {
    // eslint-disable-next-line
    var regex = /(.+)@(.+){2,}\.(.+){2,3}/
    if (regex.test(string)) {
        return true
    }
    return false
}

const ensurePasswordStrength = function (pwd) {
    var returnValue = true
    if (pwd === '') {
        returnValue = t('Saisissez un mot de passe.')
        // eslint-disable-next-line
    } else if (
        pwd.length < 8 ||
        pwd.match(/[A-Z]/g) === null ||
        pwd.match(/[a-z]/g) === null ||
        pwd.match(/[-!$%^&*()_+|~=`{}\/[:;<>?,.@#\]]/g) === null ||
        pwd.match(/[0-9]/g) === null
    ) {
        returnValue = t(
            'Votre mot de passe doit être composé de 8 caractères minimum avec au moins 1 majuscule, 1 minuscule, 1 chiffre, 1 caractère spécial.'
        )
    }
    //
    let atLeastHeight = false,
        oneUppercase = false,
        oneLowercase = false,
        oneInt = false,
        oneSpecialChar = false,
        isValid = true
    if (pwd.length > 8) {
        atLeastHeight = true
    } else {
        atLeastHeight = false
        isValid = false
    }
    if (pwd.match(/[A-Z]/g) !== null) {
        oneUppercase = true
    } else {
        oneUppercase = false
        isValid = false
    }
    if (pwd.match(/[a-z]/g) !== null) {
        oneLowercase = true
    } else {
        oneLowercase = false
        isValid = false
    }
    if (pwd.match(/[0-9]/g) !== null) {
        oneInt = true
    } else {
        oneInt = false
        isValid = false
    }
    // eslint-disable-next-line
    if (pwd.match(/[-!$%^&*()_+|~=`{}\/[:;<>?,.@#\]]/g) !== null) {
        oneSpecialChar = true
    } else {
        oneSpecialChar = false
        isValid = false
    }
    //
    return {
        message: returnValue,
        atLeastHeight: atLeastHeight,
        oneUppercase: oneUppercase,
        oneLowercase: oneLowercase,
        oneInt: oneInt,
        oneSpecialChar: oneSpecialChar,
        isValid: isValid,
    }
}

const isDefined = function (
    variable,
    andNotEmpty = false,
    andNotFalse = false
) {
    if (variable !== null && variable !== undefined) {
        if (andNotEmpty) {
            if (variable !== '') {
                return true
            }
            return false
        } else if (andNotFalse) {
            if (variable !== false) {
                return true
            }
            return false
        }
        return true
    }
    return false
}

const isObject = function (obj) {
    if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
        return true
    }
    return false
}

const isValidDateYMDObj = function (YMDobj) {
    if (YMDobj.day !== '' && YMDobj.month !== '' && YMDobj.year !== '') {
        return true
    } else if (YMDobj.day !== '' || YMDobj.month !== '' || YMDobj.year !== '') {
        return true
    }
    return false
}

const isObjEmpty = function (obj) {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false
        }
    }

    return true
}

const sortObject = function (o) {
    return Object.keys(o)
        .sort()
        .reduce((r, k) => ((r[k] = o[k]), r), {})
}
const sortObjectBy = function (arr, key) {
    arr.sort(function (a, b) {
        var keyA = a[key],
            keyB = b[key]
        // Compare the 2 dates
        if (+keyA < +keyB) return -1
        if (+keyA > +keyB) return 1
        return 0
    })
    return arr
}

const createUTCDate = function (
    date = null,
    withOffset = true,
    setMidnight = false
) {
    var oDate = moment()
    if (date !== null) {
        if (typeof date === 'string') {
            if (date.indexOf('.') > -1 && date.indexOf(' ') > -1) {
                date = date.replace(' ', 'T') + 'Z'
            } else if (date.indexOf('.') > -1) {
                return date
            }
            oDate = moment(date)
        } else if (
            isDefined(date.day) &&
            isDefined(date.month) &&
            isDefined(date.year)
        ) {
            oDate = moment(date.year + '-' + date.month + '-' + date.day)
        }
    } else {
        oDate.set('millisecond', 0)
    }
    if (setMidnight) {
        oDate = oDate.startOf('day')
    }
    oDate = oDate.utc()
    var offset = withOffset ? oDate.utcOffset() : 0
    oDate.set({ hours: oDate.hour() + offset * 60 * -1000 })
    return oDate
}

const getDateObjFromYMD = function (ymd = null, time = 'now', isUTC = false) {
    let refLang = localStorage.getItem('locale')
    if (!isDefined(refLang) || refLang == false) {
        refLang = 'fr-FR'
    } else {
        refLang = refLang.replace('_', '-')
    }
    refLang = refLang.split('-')[0]
    if (time === 'now') {
        // time = new Date().toLocaleTimeString(refLang, {hour: 'numeric', minute: 'numeric', second: 'numeric'}) + '.000000';
        time = moment().locale(refLang).format('HH:mm:ss') //.toLocaleTimeString(refLang, {hour: 'numeric', minute: 'numeric', second: 'numeric'}) + '.000000';
    } else if (!isDefined(time)) {
        time = '00:00:00.000000'
    }
    if (ymd === '') {
        return ymd
    }
    if (!ymd || !isDefined(ymd)) {
        // var today = new Date();
        var today = moment()
        return today.startOf('day').format('YYYY-MM-DD')
        // return today.year() + '-' + String(+today.month() + 1).padStart(2, 0) + '-' + String(today.toDate()).padStart(2, 0) + 'T' + time + 'Z';
    }

    var dateYear = null
    var dateMonth = null
    var dateDay = null
    if (isDefined(ymd.year)) {
        dateYear = ymd.year
    } else {
        dateYear = ymd.substring(0, 4)
    }
    if (isDefined(ymd.month)) {
        dateMonth = ymd.month
    } else {
        dateMonth = ymd.substring(4, 6)
    }
    if (isDefined(ymd.day)) {
        dateDay = ymd.day
    } else {
        dateDay = ymd.substring(6, 8)
    }
    if (!isDefined(dateDay) || !isDefined(dateMonth) || !isDefined(dateYear)) {
        return null
    }
    var parsedDate = dateYear + '-' + dateMonth + '-' + dateDay + ' ' + time
    if (isUTC !== undefined && isUTC === true) {
        // var tz = (new Date().getTimezoneOffset()) / -60;
        var tz = moment().utcOffset() / 60
        var tzSign = '+'
        if (tz < 0) {
            tzSign = '-'
        }
        if (String(String(tz).replace('-', '')).length < 2) {
            tz = '0' + String(tz).replace('-', '')
        }
        parsedDate = moment
            .utc(
                dateYear +
                    '-' +
                    dateMonth +
                    '-' +
                    dateDay +
                    'T' +
                    time +
                    tzSign +
                    tz +
                    ':00'
            )
            .format('YYYY-MM-DD HH:mm:ss')
    }

    parsedDate = {
        // date: new Date(dateYear + '-' + dateMonth + '-' + dateDay),
        date: parsedDate,
        timezone: 'UTC',
        timezone_type: 3,
    }

    return parsedDate
}

const addZero = function (i) {
    if (+i < 10) {
        i = '0' + +i
    }
    return i
}

const getDaysInMonth = function (month, year) {
    var date = moment([year, month, 1])
    var days = []
    while (date.month() === month) {
        days.push(moment(date))
        date.add(1, 'days')
    }
    return days
}

const getDaysInWeek = function (day) {
    var week = new Array()
    day.set(day.toDate() - day.day() + 1)
    for (var i = 0; i < 7; i++) {
        week.push(moment(day))
        day.add(1, 'days')
    }
    return week
}

const getReadableDate = function (
    dat,
    format = 'Do MMMM YYYY',
    getShorts = false,
    delimiter = null
) {
    // YYYY/MM/DD HH:mm:ss ZZ
    let refLang = localStorage.getItem('locale')
    if (!isDefined(refLang) || refLang == false) {
        refLang = 'fr-FR'
    } else {
        refLang = refLang.replace('_', '-')
    }
    refLang = refLang.split('-')[0]
    var date = moment(dat)
    if (getShorts) {
        let YMD = getYMDfromDateObj(null)
        let today = moment(YMD.year + '-' + YMD.month + '-' + YMD.day)
        let yesterday = moment(YMD.year + '-' + YMD.month + '-' + YMD.day)
        yesterday.subtract(1, 'days')
        let refD = moment(dat)
        if (refD.isUtc()) {
            refD = moment(refD.toDate())
        }
        refD.startOf('day')
        //
        if (
            getYMDfromDateObj(today, true, true) ==
            getYMDfromDateObj(refD, true, true)
        )
            return t("aujourd'hui")
        else if (
            getYMDfromDateObj(yesterday, true, true) ==
            getYMDfromDateObj(refD, true, true)
        )
            return t('hier')
    }
    //
    if (date.isUtc()) {
        date = moment(date.toDate())
    }
    let dateString = date.locale(refLang).format(format)
    if (isDefined(delimiter)) {
        return dateString.replace(/\//g, delimiter)
    }
    return dateString
}

const logoutAndRedirect = function () {
    sessionStorage.removeItem('adminuser')
    localStorage.removeItem('refresh')
    localStorage.removeItem('expiration')
    localStorage.removeItem('apitoken')
    //
    redirectToIndex()
}

const redirectToIndex = function () {
    let redirect = APP_FRONT_WEB_URL + allRoutes['index'].pathname
    setTimeout(() => {
        window.location.href = redirect
    }, 210)
}

const closest = function (el, selector, stopSelector) {
    var retval = null
    while (el) {
        if (el.className.indexOf(selector) > -1) {
            retval = el
            break
        } else if (stopSelector && el.className.indexOf(stopSelector) > -1) {
            break
        }
        el = el.parentElement
    }
    return retval
}

const getSupport = function (key = 'mailto') {
    let support = ' support@theraflow.care'
    let customerObj = constants.custo.CUSTOMERS[APP_CUSTOMISATION.toLowerCase()]
    if (isDefined(customerObj) && isDefined(customerObj[key])) {
        support = customerObj[key]
    } else if (key === 'whole') {
        support = customerObj
    }
    //
    return support
}

const ensureNIRValidity = function (nir, gender, optional = false) {
    if (optional && nir === '') {
        return true
    }
    //
    if (nir.length !== 15) {
        return t(
            'Le NIR est composé de 15 chiffres, veuillez vérifier votre saisie.'
        )
    } else if (!isDefined(gender) || gender === '') {
        return t(
            'Veuillez renseigner votre sexe pour déterminer la validité de votre NIR.'
        )
    } else if (gender === true || +gender === 1) {
        if (+nir.charAt(0) !== 1) {
            return t(
                'Le NIR doit commencer par 1 pour un homme, 2 pour une femme.'
            )
        }
    } else if (gender == false || +gender === 0) {
        if (+nir.charAt(0) !== 2) {
            return t(
                'Le NIR doit commencer par 1 pour un homme, 2 pour une femme.'
            )
        }
    }
    let nirKey = nir.substr(nir.length - 2)
    let nirVerif = nir.substr(0, nir.length - 2)
    let nirCalcul = Math.trunc(+nirVerif / 97)
    nirCalcul = +nirVerif - +nirCalcul * 97
    if (97 - +nirCalcul !== +nirKey) {
        return t('Le NIR renseigné ne semble pas valide.')
    }

    return true
}

const getDiffDate = function (date1 = null, date2, readable = false) {
    if (!isDefined(date1)) {
        date1 = moment()
    }
    var timeDiff = date2.valueOf() - date1.valueOf()
    var diffDays = Math.floor(timeDiff / (1000 * 3600 * 24))
    if (readable) {
        return Math.abs(Math.floor(diffDays / 365))
    }
    return diffDays
}

const getAge = function (date1, date2, readable = false) {
    // var date2UTC = new Date(Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate()));
    var date2UTC = date2.utc()
    // var date1UTC = new Date(Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate()));
    var date1UTC = date1.utc()
    var yAppendix
    var days = date2UTC.toDate() - date1UTC.toDate()
    if (days < 0) {
        date2UTC.set('month', date2UTC.month() - 1)
        days += DaysInMonth(date2UTC)
    }

    var months = date2UTC.month() - date1UTC.month()
    if (months < 0) {
        date2UTC.set('year', date2UTC.year() - 1)
        months += 12
    }

    var years = date2UTC.year() - date1UTC.year()
    if (years > 1) yAppendix = ' ' + t('ans')
    else yAppendix = ' ' + t('an')

    if (!readable) {
        yAppendix = ''
    }
    return years + yAppendix
}

const getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling
    // If there's no selector, return the first sibling
    if (!selector) return sibling
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling
        sibling = sibling.nextElementSibling
    }
}

const getPreviousSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.previousElementSibling
    // If there's no selector, return the first sibling
    if (!selector) return sibling
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling
        sibling = sibling.previousElementSibling
    }
}

function DaysInMonth(date2UTC) {
    // var monthStart = new Date(date2UTC.getFullYear(), date2UTC.getMonth(), 1);
    var monthStart = moment([date2UTC.year(), date2UTC.month(), 1])
    // var monthEnd = new Date(date2UTC.getFullYear(), date2UTC.getMonth() + 1, 1);
    var monthEnd = moment([date2UTC.year(), date2UTC.month() + 1, 1])
    var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24)
    return monthLength
}

function hasOnlyDigit(value) {
    return /^\d+$/.test(value)
}
const castToBool = function (bool) {
    if (bool === 'false') {
        return false
    } else if (bool === 'true') {
        return true
    }
    if (bool === '0' || bool === '1' || bool === '2') {
        return +bool
    }
    return bool
}

const parseMobileLeadingPlus = function (prefix) {
    if (isDefined(prefix) && String(prefix).indexOf('+') === -1) {
        return '+' + prefix
    }
    return prefix
}
const parseMobileLeadingZero = function (phone) {
    if (isDefined(phone) && String(phone).length === 9) {
        return '0' + phone
    }
    return phone
}

const arrayRange = function (start, end, step) {
    let returnedArrayRange = []
    for (var i = start; i <= end; i += step) {
        returnedArrayRange.push(i)
    }
    return returnedArrayRange
}

const isValidDate = function (d) {
    return d instanceof Date && !isNaN(d)
}

const getHash = function (input) {
    var hash = 0,
        len = input.length
    for (var i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i)
        hash |= 0 // to 32bit integer
    }
    return hash
}

const sortStringNumber = function (arr, key) {
    // eslint-disable-next-line
    var regex = /[A-Za-z\ \-]+([0-9]+)/

    function map(str) {
        if (key !== null) {
            if (regex.exec(str[key]) === null) {
                return false
            }
            return Number(regex.exec(str[key])[1])
        }
        return Number(regex.exec(str)[1])
    }

    return arr.sort(function (a, b) {
        var av = map(a),
            bv = map(b)
        return av < bv ? -1 : av > bv ? 1 : 0
    })
}

const definePatientSession = function () {
    let unique_id = getQueryVariable('unique_id', false)
    let last_name = getQueryVariable('last_name', false)
    let first_name = getQueryVariable('first_name', false)
    let date_of_birth = getQueryVariable('date_of_birth', false)
    let phone = getQueryVariable('phone', false)
    let prefix = getQueryVariable('prefix', false)
    let email = getQueryVariable('email', false)
    let maiden_name = getQueryVariable('maiden_name', false)
    //
    if (isDefined(unique_id, false, true)) {
        sessionStorage.setItem('thf-unique_id', unique_id)
    }
    if (isDefined(last_name, false, true)) {
        sessionStorage.setItem('thf-last_name', last_name)
    }
    if (isDefined(first_name, false, true)) {
        sessionStorage.setItem('thf-first_name', first_name)
    }
    if (isDefined(date_of_birth, false, true)) {
        sessionStorage.setItem('thf-date_of_birth', date_of_birth)
    }
    if (isDefined(phone, false, true)) {
        sessionStorage.setItem('thf-phone', phone)
    }
    if (isDefined(prefix, false, true)) {
        sessionStorage.setItem('thf-prefix', prefix)
    }
    if (isDefined(email, false, true)) {
        sessionStorage.setItem('thf-email', email)
    }
    if (isDefined(maiden_name, false, true)) {
        sessionStorage.setItem('thf-maiden_name', maiden_name)
    }
}

export { sortStringNumber, isObject, definePatientSession }
export {
    getQueryVariable,
    getMinHourFromMinutes,
    getClosest,
    $,
    getReadableDate,
    closest,
    getDaysInWeek,
    getDaysInMonth,
}
export {
    createCookie,
    readCookie,
    eraseCookie,
    scrollTo,
    isEmail,
    isValidDateYMDObj,
    addZero,
    getAge,
    redirectToGoodIndex,
}
export {
    convertMetrics,
    convertMetricsLabels,
    getUser,
    setUser,
    logoutAndRedirect,
    redirectToIndex,
    parseJsonObjectToString,
    parseStringToJsonObj,
}
export {
    capitalize,
    compareByKey,
    trimSpecialChar,
    castToNumberOfFloat,
    castToBool,
    getNextSibling,
    getPreviousSibling,
}
export {
    isMobile,
    isMobileOrTablet,
    redirectToWebOrApp,
    sortObject,
    createDateComplientSafari,
    arrayRange,
    isAuthorizedRole,
}
export {
    addCSRFToken,
    checkForValidCSRFToken,
    createAndSaveCSRFToken,
    sortObjectBy,
    getDiffDate,
    guid,
    isValidDate,
}
export {
    getDateObjFromYMD,
    getYMDfromDateObj,
    isDefined,
    createUTCDate,
    getSupport,
    ensureNIRValidity,
    getHash,
}
export {
    isInteger,
    isFloat,
    fromNow,
    deepCopy,
    valueCopy,
    createQueryMessage,
    isObjEmpty,
    ensurePasswordStrength,
    parseMobileLeadingPlus,
    parseMobileLeadingZero,
}
