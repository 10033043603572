import { t } from 'i18next'
import { Medical } from '@app/api'
import {
    getYMDfromDateObj,
    coreFn,
    createUTCDate,
    getDiffDate,
    scrollTo,
    deepCopy,
    getDateObjFromYMD,
} from '@app/core'
import { displayErrors } from '@app/api/errors'
import * as constants from '@app/constants'
import { isMandatoryField } from './isMandatory'
import {
    sortTimelineByStudyDate,
    sortTimelineByStudyMoment,
    sortTimelineByStudySurvey,
} from './sortTimeline'
import { isApiError } from '@app/core/isApiError/isApiError'
import { settings } from '@app/core/settings'

/*
redirect depuis le mail:
http://localhost:8084/patient/edit/605b9014ade57a6fdc5cbf8e?csrftoken=de6f69b8fdff5826286f599af76af49d
&redirect=private.patient.edit&userId=605b9014ade57a6fdc5cbf8e&sp=study&trgid=605c5119847aa6713d3469e1&strgid=2021-04-2911:35:30.770000

localhost:8084?redirect=private.patient.edit&userId=605b9014ade57a6fdc5cbf8e&sp=study&trgid=605c5119847aa6713d3469e1&strgid=1
 */

/**
 * @module PatientEditStudy
 * @description Patient configuration etp
 */

const isNotMoment = ['palatine']
const defaultStudyData = { issueSentBy: 'SMS' }
const patientDateKeys = [
    'inclusionDate',
    'surgeryDate',
    'visitDate',
    'beginDate',
    'endDateLogo',
    'endDate',
    'firstInterviewDate',
    'postT2Date',
    'postSurgeryDate',
]

const { APP_WEBAPP_URL, APP_FRONT_WEB_URL } = settings

export default {
    initAction: function () {
        let PatientEditStudy = {
            onComponentCreate: (props) => (state, actions) => {
                actions._setState({ key: 'patientid', value: props.patientid })
                let autoGenerated = false
                if (
                    props.editedPatient.email.includes(props.editedPatient.id)
                ) {
                    autoGenerated = true
                }
                actions._setState({
                    key: 'autoGeneratedEmail',
                    value: autoGenerated,
                })
                actions._setState({
                    key: 'editedPatient',
                    value: props.editedPatient,
                })
                actions._setState({
                    key: 'referenceHealTeam',
                    value: props.referenceHealTeam,
                })
                //
                let patientHealTeam = []
                if (props.editedPatient) {
                    const userTheraflow = props.editedPatient.userTheraflow
                    if (userTheraflow.doctor) {
                        patientHealTeam.push(userTheraflow.doctor.id)
                    }
                    if (userTheraflow.coach) {
                        patientHealTeam.push(userTheraflow.coach.id)
                    }
                    if (userTheraflow.nurses.length > 0) {
                        patientHealTeam = patientHealTeam.concat(
                            userTheraflow.nurses.map((m) => m.id)
                        )
                    }
                }
                actions._setState({
                    key: 'patientHealTeam',
                    value: patientHealTeam,
                })
                //
                let hospital = props.hospitals[0]
                if (hospital) {
                    let studies = constants.custo.studies.filter(
                        (f) =>
                            !!hospital.customerModules.studySettings.find(
                                (fh) => fh.name === f.key && fh.status
                            )
                    )
                    actions._setState({ key: 'studies', value: studies })
                    let defaultStudies = constants.custo.studies.filter(
                        (f) =>
                            !!hospital.customerModules.studySettings.find(
                                (fh) =>
                                    fh.name === f.key && fh.status && fh.default
                            )
                    )
                    const defaultFirstStudy = defaultStudies[0]
                    if (defaultFirstStudy) {
                        actions._setValues({ name: defaultFirstStudy })
                    }
                }
                //
                let queryParam = state.queryParam
                if (props.trgid) {
                    queryParam.studyid = props.trgid
                }
                if (props.strgid) {
                    queryParam.commentid = props.strgid
                }
                actions._setState({ key: 'queryParam', value: queryParam })
                actions._setState({
                    key: 'updateParentState',
                    value: props.updateParentState,
                })
                //
                actions.updateUser({
                    patient: props.editedPatient,
                    studyName: props.surveyName || null,
                })
            },
            /**
             * Get patient study, then call [hydratePatient]{@link module:PatientEditStudy~PatientEditStudy:hydratePatient})
             * @function PatientEditStudy:updateUser
             * @param {object} patient - Patient object
             */
            updateUser:
                ({ patient, studyName = null }) =>
                (state, actions) => {
                    Medical.getStudy(state.patientid).then((patientStudy) => {
                        if (!studyName) {
                            studyName =
                                patientStudy.data.find((std) => std.status)
                                    ?.name ?? patientStudy.data?.[0]?.name
                            if (!studyName) {
                                studyName = state.values.name
                            }
                        }
                        let studyData = (patientStudy.data || []).find(
                            (f) => f.id === state.values.id
                        )
                        let existingStudy = true
                        //
                        if (studyData) {
                            if (!studyData.id) {
                                studyData = state.studies.find(
                                    (f) => f.key === studyData.name
                                )
                                if (!existingStudy) {
                                    existingStudy = false
                                }
                            }
                        } else {
                            existingStudy = false
                        }
                        //
                        if (!existingStudy) {
                            if (studyName) {
                                // new study
                                studyData = { ...defaultStudyData }
                            } else {
                                // at init, when there is running study
                                studyData = patientStudy.data.find(
                                    (f) => f.status
                                )
                            }
                            if (!studyData) {
                                studyData = state.studies[0]
                            }
                            //
                            if (studyData) {
                                studyName =
                                    studyData.name || studyData.key || studyName
                                actions._setValues({ name: studyName })
                                //
                                const namedActiveStudy = patientStudy.data.find(
                                    (f) => f.name === studyName && f.status
                                )
                                if (namedActiveStudy) {
                                    studyData = namedActiveStudy
                                }
                            }
                        }
                        //
                        if (patient && (!studyData || !studyData.id)) {
                            studyData = { ...defaultStudyData }
                            if (studyData.inclusionDate) {
                                studyData.inclusionDate = patient.creation
                            } else if (studyData.visitDate) {
                                studyData.visitDate = patient.creation
                            }
                            actions._setState({ key: 'studyId', value: null })
                        } else {
                            actions._setState({
                                key: 'studyId',
                                value: studyData.id,
                            })
                        }
                        if (
                            studyData &&
                            constants.custo.studies.find(
                                (f) => f.key === studyName
                            )
                        ) {
                            if (!isNotMoment.includes(studyName)) {
                                actions._setState({
                                    key: 'displayMode',
                                    value: 'moment',
                                })
                            }
                        }
                        //
                        actions._setState({
                            key: 'formSubmittedOnce',
                            value: false,
                        })
                        actions._setState({ key: 'loaded', value: true })
                        actions.hydratePatient(studyData)
                        //
                        actions.getMedicalStudyTimeline({
                            patientid: state.editedPatient.id,
                            targetstd: studyName,
                        })
                        if (studyName) {
                            actions._setValues({ name: studyName })
                        }
                    })
                },
            getMedicalStudyTimeline:
                ({ patientid, targetstd = null }) =>
                (state, actions) => {
                    Medical.getStudyTimeline(patientid).then((res) => {
                        let queryParam = state.queryParam
                        let usersTimelines = res.data
                        let activeTimelines = usersTimelines.filter(
                            (f) => f.status
                        )
                        let currentTimeline = null
                        //
                        if (queryParam.studyid) {
                            for (const tm in usersTimelines) {
                                if (
                                    usersTimelines[tm]?.timeline?.find(
                                        (evt) =>
                                            evt?.surveyId === queryParam.studyid
                                    )
                                ) {
                                    currentTimeline = usersTimelines[tm]
                                }
                            }
                        } else {
                            //
                            if (targetstd) {
                                currentTimeline =
                                    usersTimelines?.find(
                                        (tml) =>
                                            tml.name === targetstd && tml.status
                                    ) || null
                            }
                            if (!currentTimeline) {
                                currentTimeline =
                                    activeTimelines?.find(
                                        (tml) =>
                                            tml.name === targetstd && tml.status
                                    ) || null
                            }
                            if (!targetstd && !currentTimeline) {
                                currentTimeline =
                                    activeTimelines?.find(
                                        (tml) => tml.status
                                    ) || null
                            }
                        }
                        //
                        actions._setState({
                            key: 'usersTimelines',
                            value: usersTimelines,
                        })
                        actions._setState({
                            key: 'activeTimelines',
                            value: activeTimelines,
                        })
                        //
                        if (currentTimeline) {
                            currentTimeline =
                                actions.parseCurrentTimeline(currentTimeline)
                            actions._setState({
                                key: 'currentTimeline',
                                value: currentTimeline,
                            })
                            actions._setState({ key: 'loaded', value: true })
                            //
                            if (queryParam.studyid) {
                                setTimeout(() => {
                                    let trgSpan = document.getElementById(
                                        `getsurvey-${queryParam.studyid}`
                                    )
                                    if (trgSpan) {
                                        trgSpan.click()
                                        setTimeout(() => {
                                            let trgModal =
                                                document.querySelector(
                                                    `.btzModal#modalcommentstudy-${queryParam.studyid}`
                                                )
                                            let offset = 0
                                            if (trgModal) {
                                                offset = trgModal.querySelector(
                                                    '.btzInformations-btzInformationsModal'
                                                ).clientHeight
                                                trgModal = trgModal.firstChild
                                            }
                                            //
                                            if (queryParam.commentid) {
                                                let trgComment =
                                                    document.getElementById(
                                                        `surveyanswer-${queryParam.studyid}-$(queryParam.commentid}`
                                                    )
                                                if (trgModal && trgComment) {
                                                    scrollTo(
                                                        trgModal,
                                                        trgComment.offsetTop +
                                                            offset,
                                                        360
                                                    )
                                                }
                                                setTimeout(() => {
                                                    queryParam.commentid = null
                                                    queryParam.studyid = null
                                                    actions._setState({
                                                        key: 'queryParam',
                                                        value: queryParam,
                                                    })
                                                }, 3000)
                                            }
                                        }, 1000)
                                    }
                                }, 1000)
                            }
                        } else {
                            actions._setState({ key: 'loaded', value: true })
                        }
                        actions._setState({
                            key: 'displayMode',
                            value: 'moment',
                        })
                        //
                        let pastTimelines = res.data
                            .filter((f) => f.status === false)
                            .reverse()
                        for (const tm in pastTimelines) {
                            pastTimelines[tm].timeline = pastTimelines[
                                tm
                            ].timeline
                                .sort(sortTimelineByStudySurvey)
                                .sort(sortTimelineByStudyMoment)
                                .sort(sortTimelineByStudyDate)
                        }
                        actions._setState({
                            key: 'pastTimelines',
                            value: pastTimelines,
                        })
                        //
                        if (!currentTimeline) {
                            actions.updateStudyMomentEligibility()
                            actions._setState({
                                key: 'currentTimeline',
                                value: null,
                            })
                        } else {
                            actions.hydratePatient(
                                currentTimeline.userMedicalStudyDatas
                            )
                            if (!state.studyId) {
                                actions._setState({
                                    key: 'studyId',
                                    value: currentTimeline.userMedicalStudyId,
                                })
                            }
                            actions._setValues({ name: currentTimeline.name })
                            actions._setState({
                                key: 'momentEligibility',
                                value: [],
                            })
                        }
                    })
                },
            parseCurrentTimeline: (currentTimeline) => (state, actions) => {
                if (!currentTimeline) {
                    return currentTimeline
                }
                for (const entry in currentTimeline.timeline) {
                    if (
                        getDiffDate(
                            null,
                            createUTCDate(
                                currentTimeline.timeline[entry].date.date
                            )
                        ) > 0
                    ) {
                        currentTimeline.timeline[entry].inFutur = true
                    } else {
                        currentTimeline.timeline[entry].inFutur = false
                    }
                }
                //
                currentTimeline.timeline = currentTimeline.timeline
                    .sort(sortTimelineByStudySurvey)
                    .sort(sortTimelineByStudyMoment)
                    .sort(sortTimelineByStudyDate)
                //
                return currentTimeline
            },
            updateStudyMomentEligibility: () => (state, actions) => {
                let parsedValues = actions.parseDataStudy()
                //
                if (isNotMoment.includes(parsedValues.name) || !parsedValues) {
                    actions._setState({ key: 'momentEligibility', value: [] })
                    return false
                }
                if (state.currentTimeline || !state.loaded) {
                    return false
                }
                //
                Medical.getBufferedStudyMomentEligibility(parsedValues).then(
                    (res) => {
                        if (!isApiError(res.data)) {
                            let momentEligibility = Object.values(
                                res.data
                            ).flat()
                            //
                            let momentEligibilityTemp =
                                momentEligibility.reduce(function (acc, obj) {
                                    let tobj = obj[Object.keys(obj)[0]]
                                    let cle
                                    if (tobj) {
                                        cle = tobj.date
                                        if (!acc[cle]) {
                                            acc[cle] = []
                                        }
                                    } else {
                                        tobj = {}
                                        cle = 'nodate'
                                        if (!acc[cle]) {
                                            acc[cle] = []
                                        }
                                    }
                                    //
                                    tobj.moment = Object.keys(obj)[0]
                                    acc[cle].push(tobj)
                                    return acc
                                }, {})
                            momentEligibilityTemp = Object.values(
                                momentEligibilityTemp
                            )
                            actions._setState({
                                key: 'momentEligibility',
                                value: momentEligibilityTemp,
                            })
                        }
                    }
                )
            },
            retrieveEventLabel:
                ({ el, item }) =>
                () => {
                    let label = ''
                    if (item.label === 'visitDate') {
                        label = t('Première visite')
                    } else if (item.label === 'surgeryDate') {
                        label = t('Chirurgie')
                    } else if (item.label === 'inclusionDate') {
                        label = t("Date d'inclusion")
                    } else if (item.label === 'firstInterviewDate') {
                        label = t('Date de 1er entretien')
                    } else if (item.label === 'postT2Date') {
                        label = t("Date d'entretien post-T2")
                    } else if (item.label === 'postSurgeryDate') {
                        label = t("Date d'entretien post-op")
                    }
                    el.innerHTML = label
                },
            /**
             * Update state with patient data
             * @function PatientEditStudy:hydratePatient
             * @param {object} patient - Patient object
             */
            hydratePatient: (patient) => (state, actions) => {
                if (!patient) {
                    return false
                }
                let values = state.values
                if (patient.issueSentBy) {
                    values.issueSentBy = patient.issueSentBy
                    state.isValid.issueSentBy = true
                    actions._setTouched({ issueSentBy: true })
                }
                patientDateKeys.forEach((key) => {
                    if (patient[key]) {
                        values[key] = getYMDfromDateObj(
                            createUTCDate(patient[key].date)
                        )
                        state.isValid[key] = true
                        actions._setTouched({ key: true })
                    }
                })
                actions._setValuesObj(values)
            },
            retrieveErrorSendBy: (target) => (state, actions) => {
                let isvalid = false,
                    sendBy = state.values.issueSentBy
                if (target) {
                    actions._retrieveInput(target)
                    sendBy = target.values
                }

                if (isNotMoment.includes(state.values.name)) {
                    isvalid = true
                } else if (sendBy === 'EMAIL' && state.autoGeneratedEmail) {
                    actions._setTypingFalse()
                    if (!target) {
                        actions._setErrorMessages({
                            issueSentBy: t(
                                'Rendez-vous dans l’onglet « Profil » pour définir l’adresse email de votre patient.'
                            ),
                        })
                    }
                    actions._setValidity({ issueSentBy: false })
                } else {
                    isvalid = true
                    actions._setErrorMessages({ issueSentBy: '' })
                }
                if (isvalid) {
                    isvalid = actions.retrieveErrorMessageStudy(target)
                }
                //
                actions._setState({ key: 'isValidForm', value: isvalid })
                return isvalid
            },
            retrieveErrorMessageStudy: (target) => (state, actions) => {
                let isvalid = true
                if (target?.target) {
                    target = target.target.getAttribute('name')
                }

                const errorMessages = {
                    inclusionDate: t(
                        "merci de renseigner une date d'inclusion valide (jj/mm/aaaa)"
                    ),
                    beginDate: t(
                        'merci de renseigner une date de début de traitement loco-régional valide (jj/mm/aaaa)'
                    ),
                    endDateLogo: t(
                        'merci de renseigner une date de fin de traitement loco-régional valide (jj/mm/aaaa)'
                    ),
                    endDate: t(
                        'merci de renseigner une date de fin de traitement valide (jj/mm/aaaa)'
                    ),
                    visitDate: t(
                        'merci de renseigner une date de première visite valide (jj/mm/aaaa)'
                    ),
                    surgeryDate: t(
                        'merci de renseigner une date de chirurgie valide (jj/mm/aaaa)'
                    ),
                    firstInterviewDate: t(
                        'merci de renseigner une date de 1er entretien valide (jj/mm/aaaa)'
                    ),
                    postT2Date: t(
                        "merci de renseigner une date d'entretien post-T2 valide (jj/mm/aaaa)"
                    ),
                    postSurgeryDate: t(
                        "merci de renseigner une date d'entretien post-op valide (jj/mm/aaaa)"
                    ),
                }
                const valuesKeys = Object.keys(state.values)
                //
                valuesKeys.forEach((key) => {
                    if (state.values[key] !== undefined) {
                        const isMandatory = isMandatoryField(state.values, key)
                        //
                        if (!state.isValid[key] && isMandatory) {
                            isvalid = false
                        }
                        if (state.formSubmittedOnce) {
                            if (!state.isValid[key]) {
                                actions._setErrorMessages({
                                    [key]: errorMessages[key],
                                })
                                actions._setTyping({ [key]: false })
                            } else {
                                actions._setErrorMessages({
                                    [key]: '',
                                })
                            }
                        }
                    }
                })
                //
                actions._setState({ key: 'formIsSubmitting', value: false })
                actions._setState({ key: 'isValidForm', value: isvalid })
                return isvalid
            },
            /**
             * Validation of study and error message displaying
             * @function PatientEditStudy:onSubmit
             */
            onSubmit: (event) => (state, actions) => {
                event.preventDefault()

                if (state.formIsSubmitting) {
                    return false
                }
                actions._setState({ key: 'formSubmittedOnce', value: true })
                //
                if (actions.retrieveErrorSendBy()) {
                    actions._setTypingFalse()
                    actions._setValues({ status: true })
                    actions.updateStudy()
                } else {
                    displayErrors(
                        t('Veuillez renseigner toutes les informations.'),
                        2000
                    )
                }
            },
            setModaldisplayed: (newState) => () => ({
                modaldisplayed: newState,
            }),
            retrieveStudy: (stdname) => (state, actions) => {
                actions._setValuesObj({ ...defaultStudyData })
                actions.updateUser({
                    patient: state.editedPatient,
                    studyName: stdname,
                })
            },
            switchCurrentStudy: (stdname) => (state, actions) => {
                if (stdname !== state.currentTimeline?.name) {
                    actions._setValuesObj({ ...defaultStudyData })
                    actions._setValues({ name: stdname })
                    if (
                        state.activeTimelines
                            ?.filter((tml) => tml.status)
                            ?.map((tml) => tml.name)
                            ?.includes(stdname)
                    ) {
                        // active study
                        const currentTimeline = actions.parseCurrentTimeline(
                            state.activeTimelines?.find(
                                (std) => std.name === stdname && std.status
                            )
                        )
                        actions.hydratePatient(
                            currentTimeline.userMedicalStudyDatas
                        )
                        actions._setState({
                            key: 'currentTimeline',
                            value: currentTimeline,
                        })
                    } else {
                        // new study
                        actions._setState({
                            key: 'currentTimeline',
                            value: null,
                        })
                        actions.retrieveStudy(stdname)
                    }
                }
            },
            getSurveyResults:
                ({ surveyid, answerid, marker }) =>
                (state, actions) => {
                    let currentAnswer = {}
                    currentAnswer.id = answerid
                    //
                    Medical.getStudyAnswers(answerid).then((res) => {
                        actions._setState({
                            key: 'currentAnswer',
                            value: res.data,
                        })
                        actions._setState({
                            key: 'newAnswerSelected',
                            value: false,
                        })
                        if (marker) {
                            actions.setModaldisplayed(marker)
                            actions._setState({
                                key: 'formSubmittedOnce',
                                value: false,
                            })
                        }
                    })
                },
            updatePatientState: () => (state, actions) => {
                let newAction = state.currentAnswer.action
                if (!newAction || newAction === '') {
                    //
                    return false
                }
                Medical.updateStudyAnswerAction(state.currentAnswer.id, {
                    action: newAction,
                }).then((res) => {
                    let currentAnswer = state.currentAnswer
                    if (res?.data?.action) {
                        currentAnswer.action = res.data.action
                        //
                        let currentTM = state.currentTimeline
                        if (currentTM?.timeline) {
                            let currentTMEntry = currentTM.timeline.find(
                                (f) => f.answerId === res.data.id
                            )
                            if (currentTMEntry) {
                                currentTMEntry.action = res.data.action.action
                            }
                            actions._setState({
                                key: 'currentTimeline',
                                value: currentTM,
                            })
                        } else {
                            // editing pastTimeline
                            let pastTimelines = state.pastTimelines
                            if (pastTimelines) {
                                let pastTM = pastTimelines.find((pst) =>
                                    pst.timeline.find(
                                        (f) => f.answerId === res.data.id
                                    )
                                )
                                if (pastTM) {
                                    let pastTMEntry = pastTM.timeline.find(
                                        (f) => f.answerId === res.data.id
                                    )
                                    if (pastTMEntry) {
                                        pastTMEntry.action =
                                            res.data.action.action
                                    }
                                    actions._setState({
                                        key: 'pastTimelines',
                                        value: pastTimelines,
                                    })
                                }
                            }
                        }
                    }
                    //
                    actions._setState({
                        key: 'currentAnswer',
                        value: currentAnswer,
                    })
                    actions._setState({
                        key: 'newAnswerSelected',
                        value: false,
                    })
                    //
                    const event = new CustomEvent('updateInfos')
                    window.dispatchEvent(event)
                })
            },
            updateCurrentActionState: (input) => (state, actions) => {
                const { values } = input
                let currentAnswer = state.currentAnswer
                currentAnswer.action = values
                actions._setState({
                    key: 'currentAnswer',
                    value: currentAnswer,
                })
                actions._setState({ key: 'newAnswerSelected', value: true })
            },
            retrieveSurvey:
                ({ item, userMedicalStudyToken, answerName, surveyId }) =>
                (state, actions) => {
                    let token = encodeURIComponent(
                        localStorage.getItem('apitoken')
                    )
                    let upanswer = ''
                    if (answerName) {
                        upanswer = `&upanswer=${answerName}&upanswerid=${item.answerId}`
                        actions._setState({
                            key: 'answerName',
                            value: answerName,
                        })
                        actions._setState({
                            key: 'answerId',
                            value: item.answerId,
                        })
                    }
                    let iframeUrl = `${APP_WEBAPP_URL}/medical-study/${item.surveyId}/${state.editedPatient.id}/${token}/?display=popup${upanswer}`
                    //
                    actions._setState({ key: 'surveyUrl', value: iframeUrl })
                    let tmt = setTimeout(() => {
                        clearTimeout(tmt)
                        const iframe =
                            document.getElementById('inlineFrameExample')
                        if (!iframe) {
                            return false
                        }
                        // eslint-disable-next-line
                        const messager = new Messager({
                            remoteWindow: iframe.contentWindow,
                            local: APP_FRONT_WEB_URL,
                            remote: APP_WEBAPP_URL,
                        })
                        messager.bind('updateTimelines', () => {
                            actions._setState({
                                key: 'surveyUrl',
                                value: null,
                            })
                            actions.getMedicalStudyTimeline({
                                patientid: state.editedPatient.id,
                                targetstd: state.values.name,
                            })
                            messager.destroy()
                        })
                        actions._setState({
                            key: 'messagerObj',
                            value: messager,
                        })
                    }, 210)
                },
            buffStudyValues: () => (state, actions) => {
                actions._setState({
                    key: 'buffedValues',
                    value: deepCopy(state.values),
                })
            },
            retrieveBuffedValues: () => (state, actions) => {
                actions._setState({
                    key: 'values',
                    value: deepCopy(state.buffedValues),
                })
            },
            parseDataStudy: () => (state, actions) => {
                let parsedValues = deepCopy(state.values)

                let successfullyParsed = true
                patientDateKeys.forEach((key) => {
                    if (
                        parsedValues[key] &&
                        state.isValid[key] &&
                        Object.values(parsedValues[key]).join('') !== ''
                    ) {
                        parsedValues[key] = getDateObjFromYMD(
                            `${parsedValues[key].year}${parsedValues[key].month}${parsedValues[key].day}`
                        )
                    } else {
                        if (isMandatoryField(parsedValues, key)) {
                            successfullyParsed = false
                        } else {
                            parsedValues[key] = undefined
                        }
                    }
                })
                if (!successfullyParsed) {
                    return successfullyParsed
                }
                for (const parsedValue in parsedValues) {
                    if (
                        parsedValues[parsedValue] &&
                        patientDateKeys.includes(parsedValue)
                    ) {
                        parsedValues[parsedValue].date = createUTCDate(
                            parsedValues[parsedValue].date,
                            true,
                            true
                        ).format('YYYY-MM-DD HH:mm:ss')
                    }
                }
                //
                return parsedValues
            },
            clearModalState: () => (state, actions) => {
                let queryParam = state.queryParam
                queryParam.commentid = null
                queryParam.studyid = null
                actions._setState({ key: 'queryParam', value: queryParam })
                //
                state.updateParentState({ key: 'strgid', value: null })
                state.updateParentState({ key: 'trgid', value: null })
                actions.setModaldisplayed('')
            },
            closeSurveyIframe: () => (state, actions) => {
                actions.setModaldisplayed('')
                actions._setState({ key: 'surveyUrl', value: null })
                if (state.messagerObj) {
                    state.messagerObj.destroy()
                    actions._setState({ key: 'messagerObj', value: null })
                }
            },
            /**
             * Submit study form, then call [updateUser]{@link module:PatientEditStudy~PatientEditStudy:updateUser})
             * @function PatientEditStudy:updateStudy
             */
            updateStudy: () => (state, actions) => {
                if (state.formIsSubmitting) {
                    return false
                }
                //
                let parsedValues = actions.parseDataStudy()
                actions._setState({ key: 'formIsSubmitting', value: true })
                //
                if (
                    state?.currentTimeline?.userMedicalStudyId &&
                    parsedValues !== false
                ) {
                    Medical.updateStudy(
                        state.patientid,
                        state.currentTimeline.userMedicalStudyId,
                        parsedValues
                    ).then((res) => {
                        actions._setState({
                            key: 'formIsSubmitting',
                            value: false,
                        })
                        actions._setState({
                            key: 'newAnswerSelected',
                            value: false,
                        })
                        if (res.data.id) {
                            displayErrors(
                                t('Étude médical mise à jour avec succès.'),
                                3000,
                                'success'
                            )
                            actions.updateUser({
                                patient: null,
                                studyName: parsedValues.name,
                            })
                            actions.setModaldisplayed('')
                            actions._setState({
                                key: 'buffedValues',
                                value: null,
                            })
                        } else {
                            displayErrors(res.data.message, 3000)
                        }
                    })
                } else {
                    Medical.sendStudy(state.patientid, parsedValues).then(
                        (res) => {
                            actions._setState({
                                key: 'formIsSubmitting',
                                value: false,
                            })
                            actions._setState({
                                key: 'newAnswerSelected',
                                value: false,
                            })
                            if (res.data.id) {
                                displayErrors(
                                    t('Étude médicale créée avec succès.'),
                                    3000,
                                    'success'
                                )
                                actions.updateUser({
                                    patient: null,
                                    studyName: parsedValues.name,
                                })
                                actions.setModaldisplayed('')
                                actions._setState({
                                    key: 'buffedValues',
                                    value: null,
                                })
                            } else {
                                displayErrors(res.data.message, 3000)
                            }
                        }
                    )
                }
            },
            endStudy: () => (state, actions) => {
                actions._setValues({ status: false })
                actions.updateStudy()
            },
            commentCallback: (currentAnswer) => (state, actions) => {
                let answerId = currentAnswer.id
                let searchedAnswer = null
                if (state?.currentTimeline?.timeline) {
                    searchedAnswer = state.currentTimeline.timeline.find(
                        (f) => f.answerId === answerId
                    )
                }
                if (!searchedAnswer && state.pastTimelines.length > 0) {
                    for (let tm in state.pastTimelines) {
                        if (
                            state.pastTimelines[tm].timeline &&
                            state.pastTimelines[tm].timeline.length > 0
                        ) {
                            searchedAnswer = state.pastTimelines[
                                tm
                            ].timeline.find((f) => f.answerId === answerId)
                            if (searchedAnswer) {
                                break
                            }
                        }
                    }
                }
                if (searchedAnswer) {
                    if (!searchedAnswer.commentsCount) {
                        searchedAnswer.commentsCount = 0
                    }
                    searchedAnswer.commentsCount += 1
                }
                if (!currentAnswer.surveyId) {
                    currentAnswer.surveyId = searchedAnswer.surveyId
                }
                //
                actions._setState({
                    key: 'currentAnswer',
                    value: currentAnswer,
                })
            },
            _globalOnInputCallback: () => (state, actions) => {
                const timelineInitialized = state.currentTimeline !== undefined
                const timelineActive = state.currentTimeline?.status === true
                if (timelineInitialized && !timelineActive) {
                    actions.updateStudyMomentEligibility()
                }
            },
        }
        PatientEditStudy = { ...coreFn, ...PatientEditStudy }
        return PatientEditStudy
    },
}
