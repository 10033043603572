import { t } from 'i18next'
import { h } from 'hyperapp'
import { Form, FormInput } from '@app/elements'
import {
    isDefined,
    getReadableDate,
    createUTCDate,
    getYMDfromDateObj,
} from '@app/core'

import * as constants from '@app/constants'

export const TableViewDefault = (props, actions) => (
    <table
        key={'thfTable-' + props.menu}
        class={'thfTable thfTable-' + props.menu}
        style="margin-top: 0px;"
    >
        <thead>
            <tr>
                {(props.menu === 'recent' || props.menu === 'all') && (
                    <th class="thfTable-cellType">{t('Type')}</th>
                )}
                <th class="thfTable-cellDate">{t('Date')}</th>
                <th class="thfTable-cellValue">{t('Valeurs')}</th>
                {props.themeCurrent.split('_')[0] !== 'OBSERVANCE' && (
                    <th class="thfTable-cellReason">{t('Alertes')}</th>
                )}
                {props.themeCurrent.split('_')[0] !== 'OBSERVANCE' && (
                    <th class="thfTable-cellAction">{t('Actions')}</th>
                )}
            </tr>
        </thead>
        {isDefined(props.tableDatasReversed) &&
        props.tableDatasReversed.length > 0 ? (
            <tbody>
                {props.tableDatasReversed
                    .filter((f) => isDefined(f.date))
                    .map((item, idx) => (
                        <tr
                            class={
                                props.menu === 'recent' || props.menu === 'all'
                                    ? 'thfTable-cliquable'
                                    : ''
                            }
                            onclick={() => {
                                if (
                                    props.menu === 'recent' ||
                                    props.menu === 'all'
                                ) {
                                    props.redirectToDatavizAlert({
                                        date: getYMDfromDateObj(
                                            createUTCDate(item.date.date),
                                            true,
                                            true
                                        ),
                                        crypt: item.dataType,
                                        period: 'week',
                                    })
                                }
                            }}
                        >
                            {(props.menu === 'recent' ||
                                props.menu === 'all') && (
                                <td class="thfTable-cellType">
                                    <p title={t(item.readableDataType)}>
                                        {t(item.readableDataType)}
                                    </p>
                                </td>
                            )}
                            <td class="thfTable-cellDate">
                                {getReadableDate(
                                    createUTCDate(item.date.date),
                                    'DD/MM/YY',
                                    true
                                ) +
                                    ' - ' +
                                    getReadableDate(
                                        createUTCDate(item.date.date),
                                        'HH:mm'
                                    )}
                            </td>
                            <td class="thfTable-cellValue">
                                <p
                                    class={
                                        'thfDatavizLegend-color ' +
                                        item.triggerType
                                    }
                                    oncreate={(e) => {
                                        props.retrieveLegendColor({
                                            e: e,
                                            oValue: item,
                                            chart: props.editingMesure
                                                .alertValueKey,
                                        })
                                    }}
                                    onupdate={(e) => {
                                        props.retrieveLegendColor({
                                            e: e,
                                            oValue: item,
                                            chart: props.editingMesure
                                                .alertValueKey,
                                        })
                                    }}
                                ></p>
                                <p
                                    class="thfDatavizLegend-label"
                                    oncreate={(e) => {
                                        props.retrieveCryptValue({
                                            e: e,
                                            item: item,
                                        })
                                    }}
                                    onupdate={(e) => {
                                        props.retrieveCryptValue({
                                            e: e,
                                            item: item,
                                        })
                                    }}
                                ></p>
                            </td>
                            {props.themeCurrent.split('_')[0] !==
                                'OBSERVANCE' && (
                                <td
                                    class="thfTable-cellReason"
                                    oncreate={(e) => {
                                        props.retrieveReason({
                                            e: e,
                                            oValue: item,
                                        })
                                    }}
                                    onupdate={(e) => {
                                        props.retrieveReason({
                                            e: e,
                                            oValue: item,
                                        })
                                    }}
                                ></td>
                            )}
                            {props.themeCurrent.split('_')[0] !==
                                'OBSERVANCE' && (
                                <td class="thfTable-cellAction">
                                    {isDefined(
                                        constants.custo.telesuiviActionsAlert.filter(
                                            (f) => +f.key === item.status
                                        )[0]
                                    ) ? (
                                        <Form>
                                            <Form.Group>
                                                <FormInput
                                                    key={'input-action-' + idx}
                                                    type="dropdown"
                                                    id={item.id}
                                                    name="action"
                                                    value={
                                                        constants.custo.telesuiviActionsAlert.filter(
                                                            (f) =>
                                                                +f.key ===
                                                                item.status
                                                        )[0].key
                                                    }
                                                    list={
                                                        constants.custo
                                                            .telesuiviActionsAlert
                                                    }
                                                    initialCalls={false}
                                                    validation={true}
                                                    withSearch={false}
                                                    textedArea={false}
                                                    oninputcb={
                                                        props.retrieveInputAction
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </Form>
                                    ) : (
                                        <p>{'-'}</p>
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
            </tbody>
        ) : (
            <tbody>
                <tr>
                    <td colspan="5">
                        <p class="" style="padding: 50px 0;text-align: center">
                            {t('aucune mesure sur cette période')}
                        </p>
                    </td>
                </tr>
            </tbody>
        )}
    </table>
)
