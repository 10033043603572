import { settings } from '@app/core/settings';
import { handleErrors } from '@app/api/errors';
import { queueQueries } from '@app/api/controlleur';

const { APP_API_URL } = settings;

function getSchedule(id, type) {
    return queueQueries(`${APP_API_URL}/theraflow/measure-schedule/${id}/${type}/`, 'GET');
}
function createSchedule(id, data) {
    return queueQueries(`${APP_API_URL}/theraflow/measure-schedule/${id}/`, 'POST', 'application/json', true, true, true, true, true, data);
}
function updateSchedule(id, scheduleid, data) {
    return queueQueries(`${APP_API_URL}/theraflow/measure-schedule/${id}/${scheduleid}/`, 'PUT', 'application/json', true, true, true, true, true, data);
}
function deleteSchedule(id, scheduleid) {
    return queueQueries(`${APP_API_URL}/theraflow/measure-schedule/${id}/${scheduleid}/`, 'DELETE');
}
function deleteScheduleWithAlerts(id, scheduleid) {
    return queueQueries(`${APP_API_URL}/theraflow/measure-schedule/${id}/${scheduleid}/delete-alerts/`, 'DELETE');
}

export const Schedule = {
    getSchedule,
    createSchedule,
    updateSchedule,
    deleteSchedule,
    deleteScheduleWithAlerts
};