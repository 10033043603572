import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getYMDfromDateObj, getDateObjFromYMD, getReadableDate, getDiffDate, createUTCDate, createDateComplientSafari } from '@app/core';
import { Card, Button, Form, FormInput, Tile, Enter, Modal } from '@app/elements';
import * as constants from '@app/constants';

import { settings } from '@app/core/settings';
const { APP_API_URL } = settings;

import { ViewHorizontalTable } from './views/horizontalTable/view-default';
import { ViewConfiguration } from './views/configuration/view-default';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patient-central'>
                {state.configurationMode ?
                    <ViewConfiguration {...state} customer={props.customer} retrieveInput={actions.retrieveInput} selectThisOrgan={actions.selectThisOrgan} activateThisTreatment={actions.activateThisTreatment} _setState={actions._setState} onCancelEditSurvey={actions.onCancelEditSurvey} onSaveStudy={() => { actions.onSaveStudy(true); }} updateStudy={actions.updateStudy} retrieveStudy={actions.retrieveStudy} onEditSurvey={actions.onEditSurvey} backToSurvey={actions.backToSurvey} retrieveSurveyContent={actions.retrieveSurveyContent} cancelPhaseSwitching={actions.cancelPhaseSwitching} switchStudies={actions.switchStudies} confirmPhaseSwitching={actions.confirmPhaseSwitching}></ViewConfiguration>
                    :
                    <ViewHorizontalTable {...state} customer={props.customer} retrieveInput={actions.retrieveInput} onAcquitSurvey={actions.onAcquitSurvey} onCancelAcquitSurvey={actions.onCancelAcquitSurvey} acquitSurvey={actions.acquitSurvey} loadNextSurvey={actions.loadNextSurvey} onSaveStudy={() => { actions.onSaveStudy(false); }} onCancelEditSurvey={actions.onCancelEditSurvey} retrieveStudy={actions.retrieveStudy} sendInstantSurvey={actions.sendInstantSurvey} scrollBoard={actions.scrollBoard} _setState={actions._setState} switchDisplayedAlert={actions.switchDisplayedAlert} commentCallback={actions.commentCallback} updateCurrentActionState={actions.updateCurrentActionState} exportSurvey={actions.exportSurvey} updateStudy={actions.updateStudy} onEditSurvey={actions.onEditSurvey} updateCurrentMedicalStudy={actions.updateCurrentMedicalStudy} retrieveSurveyContent={actions.retrieveSurveyContent} cancelPhaseSwitching={actions.cancelPhaseSwitching} switchStudies={actions.switchStudies} confirmPhaseSwitching={actions.confirmPhaseSwitching} defineSurveyUrl={actions.defineSurveyUrl} setModaldisplayed={actions.setModaldisplayed}></ViewHorizontalTable>
                }
            </div>
        );
        return view;
    }
}