let settings = {
    APP_FRONT_WEB_URL: process.env.APP_FRONT_WEB_URL,
    APP_API_URL: process.env.APP_API_URL,
    APP_WEBAPP_URL: process.env.APP_WEBAPP_URL,
    APP_CUSTOMISATION: process.env.APP_CUSTOMISATION,
    APP_FALLBACKLANG: process.env.APP_FALLBACKLANG,
    LANGUAGES: { fr_FR: 'Français', en_GB: 'Anglais' },
    METRICS: {
        '0-0': 'kg / cm',
        '0-1': 'kg / feet',
        '1-0': 'stones / cm',
        '1-1': 'stones / feet',
        '2-0': 'pounds / cm',
        '2-1': 'pounds / feet',
    },
}

if (window.hasRuntimeEnv) {
    const runtimeEnvSettings = JSON.parse(JSON.stringify(window.appRuntimeEnv))
    settings = { ...settings, ...runtimeEnvSettings }
}

export { settings }
