import { t } from 'i18next';
import { h } from 'hyperapp';
import { settings } from '@app/core/settings';
import { isDefined, getDateObjFromYMD, getYMDfromDateObj, createDateComplientSafari, createUTCDate, getReadableDate } from '@app/core';
import { Button, Card, Form, FormInput } from '@app/elements';

import * as constants from '@app/constants';
const { APP_API_URL } = settings;

import icoTreat from '@app/img/ico/ico-treat.png';
import icoTreatx2 from '@app/img/ico/ico-treat@2x.png';
import icoExport from '@app/img/ico/ico-export.png';
import icoExportx2 from '@app/img/ico/ico-export@2x.png';
import icoExportGrey from '@app/img/ico/ico-export-grey.png';
import icoExportGreyx2 from '@app/img/ico/ico-export-grey@2x.png';

import CommentFeature from './../../../study/views/modals/commentFeature';

export const ModalViewAcquit = (props, actions) => (
    <Card classes={'btzInformations-btzInformationsModal btzSplittedModal'}>
        <Card.Header classes={'btzCard-btzHeader-btzModal'}>
            <div key={props.modalEditing} class={'btzClassMarker-btz btzClassMarker-btzBGColored'} key={'acquit'}>
                <p class='btzAboveTitle'>{props.editedPatient.fname + ' ' + props.editedPatient.lname}</p>
                <p class='btzTitle btzXl rslMarker'>{props.answer.usualName + ' - ' + getReadableDate(createUTCDate(props.answer.date.date), 'DD/MM/YYYY')}
                    {props.customer === 'resilience' &&
                        <font class='btn-link' style='display: block;margin-top: -5px;'>{props.editedPatient.phonePrefix + '' + props.editedPatient.phone}</font>
                    }
                </p>
            </div>
        </Card.Header>
        <Card.Body>
            <div class='btzCard-btzBody-splitted btzCard-btzBody-splittedColored'>
                <Form onsubmit={event => event.preventDefault()}>
                    <Form.Group>
                        <div class='thfSurveyReport-cellPopupContent'>
                            {isDefined(props.medicalStudyCards?.filter((card) => (isDefined(props.answer.values.filter((f) => f.evaluationName === card.name)[0]) && isDefined(props.answer.alerts.filter((f) => f.evaluationName === card.name)?.[0])))?.[0]) ?
                                <div class='thfSurveyReport-cellPopupContent-cellModalBlock'>
                                    {props.medicalStudyCards.filter((card) => (isDefined(props.answer.values.filter((f) => f.evaluationName === card.name)[0]) && isDefined(props.answer.alerts.filter((f) => f.evaluationName === card.name)[0]))).map((card) =>
                                        <dummy style='display: block;'>
                                            {card.labels.map((label) =>
                                                /*<p class={'thfSurveyReport-cellAcquit thfSurveyReport-cellPopupContentAnswer thfInput'}><span>{card.shortTitle}</span><p class='thfSurveyReport-cellAcquit-grade'>{' = '}</p><font style='margin-left: 15px;' class={'selected thfSurveyReport-cell thfSurveyReport-cellPopup thfSurveyReport-cell-' + (isDefined(props.answer.values.filter((f) => f.evaluationName === card.name)[0]) ? (isDefined(label.tag) ? props.answer.values.filter((f) => f.evaluationName === card.name)[0].value : 'noValue') : 'noValue') + ' thfSurveyReport-cellAlert-' + (isDefined(props.answer.alerts.filter((f) => f.evaluationName === card.name)[0]) ? 'isAlert' : 'isNotAlert')}><not>{props.answer.values.filter((f) => f.evaluationName === card.name)[0].value}</not></font></p>*/
                                                <p class={'thfSurveyReport-cellAcquit thfSurveyReport-cellPopupContentAnswer thfInput'}><span>{card.shortTitle}</span><p class='thfSurveyReport-cellAcquit-grade'>{' = '}</p><font style='margin-left: 15px;' class={'selected thfSurveyReport-cell thfSurveyReport-cellPopup thfSurveyReport-cell-' + (isDefined(props.answer.values.filter((f) => f.evaluationName === card.name)[0]) ? (isDefined(label.tag) ? props.answer.alerts.filter((f) => f.evaluationName === card.name)[0].color : 'noValue') : 'noValue') + ' thfSurveyReport-cellAlert-' + (isDefined(props.answer.alerts.filter((f) => f.evaluationName === card.name)[0]) ? 'isAlert' : 'isNotAlert')}><not>{props.answer.values.filter((f) => f.evaluationName === card.name)[0].value}</not></font></p>
                                            )}
                                        </dummy>
                                    )}
                                </div>
                                :
                                <div class='thfSurveyReport-cellPopupContent-cellModalBlock'>
                                    <p class='' style='margin: 0px;padding: 15px !important;white-space: normal;'>{(props.answer.alerts.length > 0) ? t('Questionnaire non répondu dans le temps imparti.') : t('Aucune alerte émise pour ce questionnaire')}</p>
                                </div>
                            }
                        </div>
                    </Form.Group>
                </Form>
                {(props.answer.alerts.length > 0) &&
                    <dummy>
                        <div class='btzCard-btzHeader-btzModal'>
                            <p class='btzTitle btzXl'>{t('Gestion de l\'alerte')}</p>
                            <p class='btzSubTitle'>{t('Dans la liste ci-dessous, choisissez l\'action que vous avez opérée pour traiter ces alertes puis cliquez sur « Traiter le rapport ».')}</p>
                        </div>
                        <Form>
                            <Form.Group>
                                <FormInput
                                    key={'input-patientstate'}
                                    type='dropdown'
                                    id={'patientstate'}
                                    name='patientstate'
                                    value={(isDefined(props.currentAnswer.action) ? props.currentAnswer.action.action : '')}
                                    list={constants.custo.studyActionsAlertRDS.filter((f) => f.service.indexOf('survey') > -1)}
                                    initialCalls={false}
                                    validation={true}
                                    withSearch={false}
                                    textedArea={false}
                                    stricMode={true}
                                    oninputcb={props.updateCurrentActionState}
                                    placeholder={t('Choisissez une action')}
                                    required
                                />
                                {(isDefined(props.currentAnswer.action) && isDefined(props.currentAnswer.action.date)) &&
                                    <div class='btzGreenBgColored' style='margin-bottom: 25px;'>
                                        {t('Rapport traité le') + ' ' + getReadableDate(createUTCDate(props.currentAnswer.action.date.date), 'DD/MM/YYYY à HH:mm') + ' ' + t('par') + ' ' + props.currentAnswer.action.author.fname.charAt(0) + '.' + props.currentAnswer.action.author.lname}
                                    </div>
                                }
                                {(!isDefined(props.currentAnswer.action) || props.newAnswerSelected) &&
                                    <Form.Group classes='btzForm-btzFooter' style={{ 'padding': '0 15px', 'display': 'inline-block' }}>
                                        <Button anchorRight flat width={'60%'} active={isDefined(props.currentAnswer.action)} onclick={(e) => {
                                            props.onSubmit(e);
                                        }}>
                                            <img class='' src={icoTreat} srcset={`${icoTreatx2} 2x`} alt='' style='display: inline-block;margin-right: 10px;vertical-align: middle;' />{t('Traiter le rapport')}
                                        </Button>
                                    </Form.Group>
                                }
                            </Form.Group>
                        </Form>
                    </dummy>
                }
            </div>
            <div class='btzCard-btzBody-splitted btzCard-btzBody-splittedColored btzMobileHidding'>
                <div key={props.modalEditing} class={'btzClassMarker-btz'} key={'acquit'}>
                    <div class='btzCard-btzHeader-btzModal'>
                        <p class='btzTitle btzXl'>{t('Exporter le document')}</p>
                    </div>
                    <Form onsubmit={event => event.preventDefault()}>
                        <Form.Group classes='btzForm-btzFooter' style={{ 'padding': '0 15px', 'margin-top': '-30px' }}>
                            <div class='btzButtonWrapper'>
                                <a class='btn btn-flat btn-revertedColor' href={APP_API_URL + "/theraflow/medical-study-repeatable/export/" + props.editedPatient.id + "/" + props.medicalStudyEdited.name + "/" + getYMDfromDateObj(props.currentAnswer.date.date, true, true) + "?xtoken=" + encodeURIComponent(localStorage.getItem('apitoken'))} target='_blank'>
                                    {props.customer === 'resilience' ?
                                        <img class='' src={icoExportGrey} srcset={`${icoExportGreyx2} 2x`} alt='' style='display: inline-block;margin-right: 19px;vertical-align: middle;' />
                                        :
                                        <img class='' src={icoExport} srcset={`${icoExportx2} 2x`} alt='' style='display: inline-block;margin-right: 19px;vertical-align: middle;' />
                                    }
                                    {t('Exporter (pdf)')}
                                </a>
                            </div>
                        </Form.Group>
                    </Form>
                </div>
            </div>
            <div class='btzCard-btzBody-splitted btzCard-btzBody-splittedColored'>
                <div key={props.modalEditing} class={'btzClassMarker-btz btzClassMarker-btzBGColored'} key={'acquit'}>
                    <CommentFeature {...props} closeButton={false} callback={props.commentCallback}></CommentFeature>
                </div>
            </div>
        </Card.Body>
    </Card>
);