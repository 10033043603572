import { t } from 'i18next';
import { h } from 'hyperapp';
import { isDefined, getReadableDate, createUTCDate } from '@app/core';
import { allRoutes, addCSRFToken, getYMDfromDateObj } from '@app/core';
import { Form, Card, Enter, Paginate, FormInput, Button } from '@app/elements';
import * as constants from '@app/constants';

import { settings } from '@app/core/settings';
const { APP_API_URL } = settings;

import icoCE from '@app/img/mentions/logo-right-ce.png';
import icoCEx2 from '@app/img/mentions/logo-right-ce@2x.png';

import icoSortAlpha from '@app/img/ico/table/ico-sort-alpha.png';
import icoSortAlphax2 from '@app/img/ico/table/ico-sort-alpha@2x.png';

import icoSortNum from '@app/img/ico/table/ico-sort-num.png';
import icoSortNumx2 from '@app/img/ico/table/ico-sort-num@2x.png';

import icoAdd from '@app/img/ico/ico-add.png';
import icoAddx2 from '@app/img/ico/ico-add@2x.png';

export default {
    initView: function() {
        let view = (state, actions) => (props, children) => (
            <div class='patients-central'>
                {isDefined(state.alerts) &&
                    <dummy>
                        {((state.alerts.length === 0) && state.noAlertAtAll) ?
                            <div class='thfEmptyTable'>
                                {(state.noPatients === true) ?
                                    <dummy style='display: block;text-align: left;'>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Pas de patient inclus')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Ajoutez un premier patient pour voir le reste des fonctionnalités.')}</p>
                                        <div style='margin: 15px auto auto; display: inline-block;'>
                                            <Button active onclick={() => { window.location.href = addCSRFToken(allRoutes['private.patient.add'].pathname); }}>
                                                <img src={icoAdd} srcset={`${icoAddx2} 2x`} alt='' />
                                                {t('Ajouter un patient')}
                                            </Button>
                                        </div>
                                    </dummy>
                                    :
                                    <dummy>
                                        <p class='thfEmptyTable-thfEmptyTableTitle'>{t('Aucune alerte à traiter')}</p>
                                        <p class='thfEmptyTable-thfEmptyTableSubTitle'>{t('Vous n’avez actuellement aucune alerte à traiter pour vos patients.')}</p>
                                    </dummy>
                                }
                                <div class='thfTableCertification' style='float: none;margin: 20px auto auto;width: auto;'>
                                    {props.customer === 'oncoflow' &&
                                        <p class=''>{'Oncolaxy - ' + t('DM Classe IIa')}</p>
                                    }
                                    {props.customer === 'cardiolaxy' &&
                                        <p class=''>{'Cardiolaxy - ' + t('DM Classe IIa')}</p>
                                    }
                                    {(['cardiolaxy', 'oncoflow'].indexOf(props.customer) > -1) &&
                                        <img src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />
                                    }
                                </div>
                            </div>
                            :
                            <Card classes={'btzPageCard btzBackgroundCover'} kind={'datas'}>
                                <Card.Header classes={'btzCard-btzHeader-btzPage'}>
                                    <Enter time={constants.custo.ANIM_DURATION} easing={constants.custo.ANIM_EASING} css={constants.custo.ANIM_CSS}>
                                        <table class='thfTable'>
                                            <thead>
                                                <tr>
                                                    <th class='thfTable-cellName'><p class='thfTable-formInputLabel'>{t('Nom')}</p><img src={icoSortAlpha} srcset={`${icoSortAlphax2} 2x`} alt='' onclick={() => actions.onSortList('lastName')} /></th>
                                                    <th class='thfTable-cellPatho'><p class='thfTable-formInputLabel'>{t('Pathologie')}</p></th>
                                                    <th class='thfTable-cellDate'><p class='thfTable-formInputLabel'>{t('Date')}</p>
                                                        <Form onsubmit={event => event.preventDefault()} classes={'hidden-field'}>
                                                            <Form.Group>
                                                                <FormInput
                                                                    key={'datesort'}
                                                                    type='hidden-dropdown'
                                                                    id={'datesort'}
                                                                    name='selectedDate'
                                                                    value={state.selectedDate}
                                                                    list={constants.custo.alertPeriods}
                                                                    initialCalls={false}
                                                                    withSearch={false}
                                                                    textedArea={false}
                                                                    oninputcb={actions.retrieveDateSort}
                                                                    dropddownImg={icoSortNumx2}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </th>
                                                    <th class='thfTable-cellSurvey'><p class='thfTable-formInputLabel'>{t('Questionnaire')}</p>
                                                        <Form onsubmit={event => event.preventDefault()} classes={'hidden-field'}>
                                                            <Form.Group>
                                                                <FormInput
                                                                    key={'surveysort'}
                                                                    type='hidden-dropdown'
                                                                    id={'surveysort'}
                                                                    name='selectedSurvey'
                                                                    value={state.selectedSurvey}
                                                                    list={state.availableSurveys}
                                                                    initialCalls={false}
                                                                    withSearch={false}
                                                                    textedArea={false}
                                                                    oninputcb={actions.retrieveSurveySort}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </th>
                                                    <th class='thfTable-cellAlertColors'><p class='thfTable-formInputLabel'>{t('Alertes')}</p>
                                                        <Form onsubmit={event => event.preventDefault()} classes={'hidden-field'}>
                                                            <Form.Group>
                                                                <FormInput
                                                                    key={'alertcolorssort'}
                                                                    type='hidden-dropdown'
                                                                    id={'alertcolorssort'}
                                                                    name='selectedAlertColors'
                                                                    value={state.selectedAlertColors}
                                                                    list={constants.custo.alertColors}
                                                                    initialCalls={false}
                                                                    withSearch={false}
                                                                    textedArea={false}
                                                                    oninputcb={actions.retrieveAlertColorsSort}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </th>
                                                    <th class='thfTable-cellMore'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.alerts.map(item =>
                                                    <tr>
                                                        <td class='thfTable-cellName' onclick={() => { actions.redirectToPatient(item.id); }}>
                                                            {((isDefined(item.user.userTheraflow) && (item.user.userTheraflow.etapes === true)) ? <font>{t('e')}</font> : '')}
                                                            <div class='btzCard-btzHeader-btzPage-btzNamePuce btzCard-btzHeader-btzPage-btzNamePuce-small'>
                                                                <p key={'listing-alertrds-' + item.user.fname + item.user.lname} oncreate={(el) => { actions.getPatientName({el: el, patient: item}); }}></p>
                                                            </div>
                                                            <p class='btzCard-btzHeader-btzPage-btzNamePuce-small-fullName'>{item.user.lname + ' ' + item.user.fname}</p>
                                                        </td>
                                                        <td class='thfTable-cellPatho'><span class='thfTable-cellColored' title={(isDefined(item.user.userTheraflow.pathologiesType) && Array.isArray(item.user.userTheraflow.pathologiesType) && (item.user.userTheraflow.pathologiesType.length > 0)) ? constants.custo.pathologiesTypes.filter((f) => item.user.userTheraflow.pathologiesType.indexOf(f.key) > -1).map((m) => t(m.label)).join(', ') : 'Ø'}>{(isDefined(item.user.userTheraflow.pathologiesType) && Array.isArray(item.user.userTheraflow.pathologiesType) && (item.user.userTheraflow.pathologiesType.length > 0)) ? constants.custo.pathologiesTypes.filter((f) => item.user.userTheraflow.pathologiesType.indexOf(f.key) > -1).map((m) => t(m.label)).join(', ') : 'Ø'}</span></td>
                                                        <td class='thfTable-cellDate' onclick={() => { actions.redirectToPatient(item.id); }}>
                                                            <p>{getReadableDate(createUTCDate(item.date.date), 'DD MMMM', true)}</p>
                                                            {item.empty ?
                                                                <p class='ovr-lightgrey-colored'>{t('non renseigné')}</p>
                                                                :
                                                                <p class='ovr-lightgrey-colored'>{getReadableDate(createUTCDate(item.date.date), 'HH:mm')}</p>
                                                            }
                                                        </td>
                                                        <td class='thfTable-cellPatientId' onclick={() => { actions.redirectToPatient(item.id); }}>{state.availableSurveys.filter((f) => f.key === item.name)[0]?.label}</td>
                                                        <td class='thfTable-cellAlertColors'>
                                                            {item.empty ?
                                                                <dummy>
                                                                    <div class='thfTable-cellAlertColorsBlock'>
                                                                        <p class='thfTable-cellAlertColorsLabel'>{'?'}</p>
                                                                        <p class='thfTable-cellAlertColored thfTable-cellAlertColoredGrey'></p>
                                                                    </div>
                                                                    <div class='thfTable-cellAlertColorsBlock'>
                                                                        <p class='thfTable-cellAlertColorsLabel'>{'?'}</p>
                                                                        <p class='thfTable-cellAlertColored thfTable-cellAlertColoredGrey'></p>
                                                                    </div>
                                                                    <div class='thfTable-cellAlertColorsBlock'>
                                                                        <p class='thfTable-cellAlertColorsLabel'>{'?'}</p>
                                                                        <p class='thfTable-cellAlertColored thfTable-cellAlertColoredGrey'></p>
                                                                    </div>
                                                                </dummy>
                                                                :
                                                                <dummy style='white-space: nowrap;'>
                                                                    {isDefined(item.alerts.filter((f) => f.color === 4)[0]) &&
                                                                        <dummy style='position: relative;' tabindex='0' onclick={(e) => { e.preventDefault(); e.stopPropagation(); actions.retrieveAlertDetail({id: item.id, color: 4}) }}>
                                                                            <div class='thfTable-cellAlertColorsBlock'>
                                                                                <p class='thfTable-cellAlertColorsLabel'>{item.alerts.filter((f) => f.color === 4).length}</p>
                                                                                <p class='thfTable-cellAlertColored thfTable-cellAlertColoredRed'></p>
                                                                            </div>
                                                                            {(isDefined(state.alertDetail) && (state.displayedPopup === (item.id + '-' + 4))) &&
                                                                                <div class='lightPopup'>
                                                                                    <div class='lightPopup-content'>
                                                                                        {state.alertDetail.filter((f) => f.color === 4).map((lrtD) =>
                                                                                            <div class='thfTable-cellAlertColoredDetail'>
                                                                                                <p class='thfTable-cellAlertColoredDetailQuestion'>{lrtD.question}</p>
                                                                                                <p class='thfTable-cellAlertColoredDetailAnswer'>{lrtD.labeledAnswer}</p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div class='lightPopup-footer' onclick={(e) => { e.preventDefault(); e.stopPropagation(); actions.redirectToPatient(item.id) }}>
                                                                                        <p class=''>{t('Voir le détail')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </dummy>
                                                                    }
                                                                    {isDefined(item.alerts.filter((f) => f.color === 3)[0]) &&
                                                                        <dummy style='position: relative;' tabindex='0' onclick={(e) => { e.preventDefault(); e.stopPropagation(); actions.retrieveAlertDetail({id: item.id, color: 3}) }}>
                                                                            <div class='thfTable-cellAlertColorsBlock'>
                                                                                <p class='thfTable-cellAlertColorsLabel'>{item.alerts.filter((f) => f.color === 3).length}</p>
                                                                                <p class='thfTable-cellAlertColored thfTable-cellAlertColoredOrange'></p>
                                                                            </div>
                                                                            {(isDefined(state.alertDetail) && (state.displayedPopup === (item.id + '-' + 3))) &&
                                                                                <div class='lightPopup'>
                                                                                    <div class='lightPopup-content'>
                                                                                        {state.alertDetail.filter((f) => f.color === 3).map((lrtD) =>
                                                                                            <div class='thfTable-cellAlertColoredDetail'>
                                                                                                <p class='thfTable-cellAlertColoredDetailQuestion'>{lrtD.question}</p>
                                                                                                <p class='thfTable-cellAlertColoredDetailAnswer'>{lrtD.labeledAnswer}</p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div class='lightPopup-footer' onclick={(e) => { e.preventDefault(); e.stopPropagation(); actions.redirectToPatient(item.id) }}>
                                                                                        <p class=''>{t('Voir le détail')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </dummy>
                                                                    }
                                                                    {isDefined(item.alerts.filter((f) => f.color === 2)[0]) &&
                                                                        <dummy style='position: relative;' tabindex='0' onclick={(e) => { e.preventDefault(); e.stopPropagation(); actions.retrieveAlertDetail({id: item.id, color: 2}) }}>
                                                                            <div class='thfTable-cellAlertColorsBlock'>
                                                                                <p class='thfTable-cellAlertColorsLabel'>{item.alerts.filter((f) => f.color === 2).length}</p>
                                                                                <p class='thfTable-cellAlertColored thfTable-cellAlertColoredYellow'></p>
                                                                            </div>
                                                                            {(isDefined(state.alertDetail) && (state.displayedPopup === (item.id + '-' + 2))) &&
                                                                                <div class='lightPopup'>
                                                                                    <div class='lightPopup-content'>
                                                                                        {state.alertDetail.filter((f) => f.color === 2).map((lrtD) =>
                                                                                            <div class='thfTable-cellAlertColoredDetail'>
                                                                                                <p class='thfTable-cellAlertColoredDetailQuestion'>{lrtD.question}</p>
                                                                                                <p class='thfTable-cellAlertColoredDetailAnswer'>{lrtD.labeledAnswer}</p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div class='lightPopup-footer' onclick={(e) => { e.preventDefault(); e.stopPropagation(); actions.redirectToPatient(item.id) }}>
                                                                                        <p class=''>{t('Voir le détail')}</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </dummy>
                                                                    }
                                                                </dummy>
                                                            }
                                                        </td>
                                                        <td class='thfTable-cellMore'>
                                                            <Form onsubmit={event => event.preventDefault()} classes={'hidden-field three-little-dot'}>
                                                                <Form.Group>
                                                                    <FormInput
                                                                        key={'surveyexport'}
                                                                        type='hidden-dropdown'
                                                                        id={'surveyexport'}
                                                                        name=''
                                                                        list={[{'label': t('Voir le détail'), 'key': 'redirect', 'redirectLink': () => { actions.redirectToPatient(item.id); }}, {'label': t('Exporter en PDF'), 'key': 'export', 'directLink': `${APP_API_URL}/theraflow/medical-study-repeatable/export/${item.user.id}/${item.name}/${getYMDfromDateObj(item.date.date, true, true)}/?xtoken=${encodeURIComponent(localStorage.getItem('apitoken'))}`}]}
                                                                        oninputcb={actions.exportSurvey}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </Enter>
                                </Card.Header>
                                <div class='thfTableCertification'>
                                    <p class=''>{'Oncolaxy '}<img style='vertical-align: sub;' src={icoCE} srcset={`${icoCEx2} 2x`} alt='' />{' 0459'}</p>
                                </div>
                                <Paginate targetPage={actions.targetPage} nextPage={actions.nextPage} prevPage={actions.prevPage} limit={state.alerts.length} total={state.totalAlerts} bypage={state.bypage} page={state.page}></Paginate>
                            </Card>
                        }
                    </dummy>
                }
            </div>
        );
        return view;
    }
}