import { t } from 'i18next'
import { User, Hospital, TwilioAPI, Consent } from '@app/api'
import { getUser, coreFn, scrollTo } from '@app/core'
import { isDefined, getQueryVariable, addCSRFToken, allRoutes } from '@app/core'
import { settings } from '@app/core/settings'
import { displayErrors } from '@app/api/errors'
import * as constants from '@app/constants'

const { APP_IC_MODULE, APP_FRONT_WEB_URL } = settings

/**
 * @module PatientEdit
 * @description Patient edition pages
 */

export default {
    initAction: function () {
        let PatientEdit = {
            onComponentCreate: (props) => (state, actions) => {
                let patientid = props?.match?.params?.id
                if (!isDefined(patientid)) {
                    //
                    return false
                }
                let subpage = getQueryVariable('sp')
                let patientUniqId = sessionStorage.getItem('thf-unique_id')
                if (isDefined(patientUniqId, true, true)) {
                    subpage = 'profil'
                }
                // used for oneofsurvey
                let trgid = getQueryVariable('trgid')
                if (isDefined(trgid) && trgid !== false) {
                    actions._setState({ key: 'trgid', value: trgid })
                }
                let strgid = getQueryVariable('strgid')
                if (isDefined(strgid) && strgid !== false) {
                    actions._setState({ key: 'strgid', value: strgid })
                }
                // used for repeatable rds
                let surveyName = getQueryVariable('surveyName')
                if (
                    isDefined(surveyName, true, true) &&
                    surveyName !== false &&
                    subpage === 'survey'
                ) {
                    actions._setState({ key: 'trgid', value: surveyName })
                } else {
                    actions._setState({ key: 'surveyName', value: surveyName })
                }
                let aid = getQueryVariable('aid')
                if (isDefined(aid) && aid !== false) {
                    actions._setState({ key: 'strgid', value: aid })
                }
                //
                let date = getQueryVariable('date')
                let mesure = getQueryVariable('mesure')
                let period = getQueryVariable('period')
                //
                if (props.customer === 'theraflow') {
                    actions._setState({ key: 'visioCallActive', value: true })
                }
                //
                if (date && mesure && period) {
                    actions._setState({
                        key: 'dynamicParam',
                        value: { date: date, mesure: mesure, period: period },
                    })
                }
                //
                actions._setState({ key: 'twilioUniqName', value: patientid })
                actions._setState({ key: 'patientid', value: patientid })
                actions._setState({
                    key: 'ICMesure',
                    value: APP_IC_MODULE || '',
                })
                let then = (loggedUser) => {
                    actions._setState({ key: 'doctor', value: loggedUser })
                    actions._setState({
                        key: 'doctorRole',
                        value: loggedUser.readableRole,
                    })
                    Consent.getUserLegalAgreement(patientid).then(
                        (consents) => {
                            //
                            User.getTheraflowUserById(patientid).then(
                                (editedPatient) => {
                                    let editedPatientData = editedPatient.data
                                    //
                                    let isAuthorisedDoctor = false
                                    if (
                                        loggedUser.id ===
                                        editedPatientData?.userTheraflow?.doctor
                                            ?.id
                                    ) {
                                        isAuthorisedDoctor = true
                                    }
                                    if (
                                        loggedUser.id ===
                                        editedPatientData?.userTheraflow?.coach
                                            ?.id
                                    ) {
                                        isAuthorisedDoctor = true
                                    }
                                    if (
                                        isDefined(
                                            editedPatientData.userTheraflow
                                                .nurses
                                        ) &&
                                        editedPatientData.userTheraflow.nurses
                                            .length > 0
                                    ) {
                                        if (
                                            editedPatientData.userTheraflow.nurses
                                                .map((m) => m.id)
                                                .indexOf(loggedUser.id) > -1
                                        ) {
                                            isAuthorisedDoctor = true
                                        }
                                    }
                                    if (
                                        loggedUser.readableRole === 'promoter'
                                    ) {
                                        isAuthorisedDoctor = true
                                    }
                                    if (!isAuthorisedDoctor) {
                                        displayErrors(
                                            t(
                                                "Vous n'avez pas le droit d'accéder à ce patient."
                                            ),
                                            3000
                                        )
                                        setTimeout(() => {
                                            window.location.href = addCSRFToken(
                                                allRoutes[
                                                    'private.patients.listing'
                                                ].pathname
                                            )
                                        }, 3000)
                                        return false
                                    }
                                    //
                                    let isMobile =
                                        localStorage.getItem('isMobile')
                                    if (
                                        isMobile != true &&
                                        isMobile != 'true'
                                    ) {
                                        if (
                                            editedPatientData.customer
                                                .customerModules
                                                .studySurveyResult === true
                                        ) {
                                            actions._setState({
                                                key: 'currentPage',
                                                value: 'survey',
                                            })
                                        } else if (
                                            editedPatientData.customer
                                                .customerModules.study === true
                                        ) {
                                            actions._setState({
                                                key: 'currentPage',
                                                value: 'study',
                                            })
                                        }
                                    }
                                    //
                                    Hospital.getHospitals().then(
                                        (hospitals) => {
                                            let patientConsent = consents.data
                                            if (
                                                Array.isArray(patientConsent) &&
                                                patientConsent.length > 0
                                            ) {
                                                patientConsent =
                                                    patientConsent[0]
                                            }
                                            editedPatientData.consents =
                                                patientConsent
                                            //
                                            if (
                                                isDefined(patientConsent) &&
                                                isDefined(
                                                    patientConsent.legalDocuments
                                                )
                                            ) {
                                                let userLegalAgreements =
                                                    patientConsent.userLegalAgreements
                                                let allConsents =
                                                    patientConsent.legalDocuments
                                                //
                                                for (let allConsent in allConsents) {
                                                    let filteredAgreement =
                                                        userLegalAgreements.filter(
                                                            (f) =>
                                                                f.legalDocument ===
                                                                allConsents[
                                                                    allConsent
                                                                ].id
                                                        )[0]
                                                    if (
                                                        isDefined(
                                                            filteredAgreement
                                                        ) &&
                                                        isDefined(
                                                            filteredAgreement.agreement
                                                        )
                                                    ) {
                                                        if (
                                                            filteredAgreement.agreement ===
                                                            true
                                                        ) {
                                                            allConsents[
                                                                allConsent
                                                            ].agreement = true
                                                            allConsents[
                                                                allConsent
                                                            ].acceptDate =
                                                                filteredAgreement.date.date
                                                        } else if (
                                                            filteredAgreement.agreement ===
                                                            false
                                                        ) {
                                                            allConsents[
                                                                allConsent
                                                            ].agreement = false
                                                        }
                                                    }
                                                }
                                                editedPatientData.consents =
                                                    allConsents
                                            }
                                            //
                                            let hospitalsData = hospitals.data
                                            if (
                                                !isDefined(hospitalsData) ||
                                                !Array.isArray(hospitalsData)
                                            ) {
                                                hospitalsData = []
                                            }
                                            //
                                            let editedPatientDataCustomer =
                                                editedPatientData.customer
                                            let patientCustomer =
                                                hospitalsData.filter(
                                                    (hospital) =>
                                                        editedPatientDataCustomer.id ===
                                                        hospital.id
                                                )[0]
                                            if (isDefined(patientCustomer)) {
                                                actions._setState({
                                                    key: 'referenceCustomer',
                                                    value: patientCustomer.id,
                                                })
                                            }
                                            let refHosps = isDefined(
                                                patientCustomer
                                            )
                                                ? hospitalsData.filter(
                                                      (f) =>
                                                          f.name ===
                                                          patientCustomer.name
                                                  )
                                                : null
                                            //
                                            let refHosp = isDefined(refHosps)
                                                ? refHosps[0]
                                                : null
                                            let refNurseHosp = isDefined(
                                                patientCustomer
                                            )
                                                ? hospitalsData.filter(
                                                      (f) =>
                                                          f.parent.name ===
                                                              patientCustomer.name ||
                                                          f.parent.name ===
                                                              patientCustomer
                                                                  .parent.name
                                                  )
                                                : null
                                            actions._setState({
                                                key: 'hospitals',
                                                value: refHosps,
                                            })
                                            //
                                            if (isDefined(refHosps)) {
                                                let studies =
                                                    constants.custo.studies.filter(
                                                        (f) =>
                                                            isDefined(
                                                                refHosps[0].customerModules.studySettings.filter(
                                                                    (fh) =>
                                                                        fh.name ===
                                                                            f.key &&
                                                                        fh.status
                                                                )[0]
                                                            )
                                                    )
                                                actions._setState({
                                                    key: 'studies',
                                                    value: studies,
                                                })
                                            }
                                            //
                                            if (
                                                loggedUser.readableRole ===
                                                    'nurse' &&
                                                isDefined(refNurseHosp)
                                            ) {
                                                refHosp = refNurseHosp[0]
                                                actions._setState({
                                                    key: 'hospitals',
                                                    value: refNurseHosp,
                                                })
                                                refNurseHosp = refNurseHosp.map(
                                                    (hosp) => {
                                                        return {
                                                            key: hosp.id,
                                                            label: hosp.name,
                                                        }
                                                    }
                                                )
                                                actions._setState({
                                                    key: 'referenceHospital',
                                                    value: refNurseHosp,
                                                })
                                                //
                                            } else if (isDefined(refHosps)) {
                                                actions._setState({
                                                    key: 'referenceHospital',
                                                    value: refHosps.map(
                                                        (hosp) => {
                                                            return {
                                                                key: hosp.id,
                                                                label: hosp.name,
                                                            }
                                                        }
                                                    ),
                                                })
                                            }
                                            if (
                                                isDefined(patientCustomer) ||
                                                isDefined(refHosp)
                                            ) {
                                                actions.parseHospitalHealTeam(
                                                    patientCustomer || refHosp
                                                )
                                            }
                                            //
                                            editedPatientData.mobile = 'Ø'
                                            if (
                                                isDefined(
                                                    editedPatientData.devices
                                                ) &&
                                                editedPatientData.devices
                                                    .length > 0
                                            ) {
                                                let editedPatientMobile =
                                                    editedPatientData.devices.filter(
                                                        (f) => f.state === true
                                                    )[0]
                                                if (
                                                    isDefined(
                                                        editedPatientMobile
                                                    )
                                                ) {
                                                    editedPatientData.mobile =
                                                        editedPatientMobile.type +
                                                        ' ' +
                                                        (editedPatientMobile.type ===
                                                        'android'
                                                            ? (editedPatientMobile.deviceVersion ||
                                                                  'Ø') +
                                                              ' ' +
                                                              (editedPatientMobile.model ||
                                                                  'Ø')
                                                            : '') +
                                                        ' - ' +
                                                        editedPatientMobile.version
                                                }
                                            }
                                            actions._setState({
                                                key: 'editedPatient',
                                                value: editedPatientData,
                                            })
                                            if (
                                                isDefined(subpage, true) &&
                                                subpage !== false
                                            ) {
                                                actions._setState({
                                                    key: 'currentPage',
                                                    value: subpage,
                                                })
                                            }
                                            actions._setState({
                                                key: 'loaded',
                                                value: true,
                                            })
                                        }
                                    )
                                }
                            )
                        }
                    )
                }
                getUser(then)
            },
            updateCurrentPage: (opt) => (state, actions) => {
                let subpage = null
                if (isDefined(opt.subpage)) {
                    subpage = opt.subpage
                }
                let focusfield = null
                if (isDefined(opt.focusfield)) {
                    focusfield = opt.focusfield
                }
                if (isDefined(subpage, true) && subpage !== false) {
                    actions._setState({ key: 'currentPage', value: subpage })
                }
                if (isDefined(focusfield, true) && focusfield !== false) {
                    setTimeout(() => {
                        let targetField = document.querySelector('#pathology')
                        scrollTo(
                            document.querySelector(
                                '.btzMainPage.btzMainPageCenter'
                            ),
                            targetField.getBoundingClientRect().top - 100,
                            160
                        )
                        setTimeout(() => {
                            targetField.focus()
                        }, 400)
                    }, 320)
                }
            },
            /**
             * Parse hospital heal team following current selected hospital
             * @param {object} hospital - Hospital object
             * @function PatientEdit:parseHospitalHealTeam
             */
            parseHospitalHealTeam: (hospital) => (state, actions) => {
                let healTeam = [],
                    coachTeam = []
                for (var heal in hospital.nurses) {
                    healTeam.push({
                        label:
                            hospital.nurses[heal].fname +
                            ' ' +
                            hospital.nurses[heal].lname,
                        key: hospital.nurses[heal].id,
                        status:
                            hospital.nurses[heal].status,
                    })
                    coachTeam.push({
                        label:
                            hospital.nurses[heal].fname +
                            ' ' +
                            hospital.nurses[heal].lname,
                        key: hospital.nurses[heal].id,
                        status:
                            hospital.nurses[heal].status,
                    })
                }
                for (var coach in hospital.coaches) {
                    coachTeam.push({
                        label:
                            hospital.coaches[coach].fname +
                            ' ' +
                            hospital.coaches[coach].lname,
                        key: hospital.coaches[coach].id,
                        status:
                            hospital.coaches[coach].status,
                    })
                }
                actions._setState({
                    key: 'referenceCoachTeam',
                    value: coachTeam,
                })
                actions._setState({ key: 'referenceHealTeam', value: healTeam })
                actions._setState({
                    key: 'referenceCustomer',
                    value: hospital.id,
                })
            },
            /**
             * Generate a token to initialize Twilio video conference, then call [createTwilioVideo]{@link module:PatientEdit~PatientEdit:createTwilioVideo})
             * @function PatientEdit:onTwilioCall
             */
            onTwilioCall: () => (state, actions) => {
                var successCallback = function (stream) {
                    actions._setState({ key: 'stream', value: stream })
                    actions.setModalDisplayed(true)
                    actions.setModalEditing('twilio')
                    actions.createTwilioVideo()
                }
                var errorCallback = function (error) {
                    if (error.name == 'NotAllowedError') {
                        alert(
                            t(
                                "Vous devez autoriser l'utilisation de votre webcam pour utiliser la visioconférence avec votre patient !"
                            )
                        )
                        actions.onTwilioKilled()
                    }
                }
                //
                navigator.mediaDevices
                    .getUserMedia({ audio: true, video: true })
                    .then(successCallback, errorCallback)
                    .catch(errorCallback)
            },
            /**
             * Confirm visio conference hang up, then call [onTwilioKilled]{@link module:PatientEdit~PatientEdit:onTwilioKilled})
             * @function PatientEdit:onTwilioCall
             */
            onTwilioConfirmHangup: () => (state, actions) => {
                if (isDefined(state.twilioRoom)) {
                    actions.onTwilioKilled()
                    TwilioAPI.closeRoom(state.twilioUniqName)
                } else {
                    actions.onTwilioKilled()
                }
            },
            /**
             * Display hang up confirm modal
             * @function PatientEdit:onTwilioHangup
             */
            onTwilioHangup: () => (state, actions) => {
                actions._setState({ key: 'modalHangup', value: true })
            },
            /**
             * Cancel hang up confirm modal
             * @function PatientEdit:onTwilioCancelHangup
             */
            onTwilioCancelHangup: () => (state, actions) => {
                actions._setState({ key: 'modalHangup', value: false })
            },
            /**
             * Request user for video/micro authorisation, set quitting room / hang up listeners, then initialize video/micro Twilio container
             * @function PatientEdit:createTwilioVideo
             * @param {object} twilioInfo - Twilio object info
             */
            createTwilioVideo: () => (state, actions) => {
                TwilioAPI.generateToken(
                    state.twilioUniqName,
                    state.twilioUniqName + '-doctor'
                )
                    .then((twilioInfo) => {
                        if (!isDefined(twilioInfo.data.token)) {
                            return false
                        }
                        var options = {
                            uniqueName: state.twilioUniqName,
                            enableTurn: true,
                            type: 'peer-to-peer',
                            audio: true,
                            video: {
                                width: 300,
                                height: 300,
                            },
                        }
                        //
                        import('twilio-video').then((Video) => {
                            Video.connect(twilioInfo.data.token, options).then(
                                (room) => {
                                    actions._setState({
                                        key: 'twilioRoom',
                                        value: room,
                                    })
                                    //

                                    room.participants.forEach(
                                        actions.participantConnected
                                    )
                                    room.on(
                                        'participantConnected',
                                        actions.participantConnected
                                    )

                                    room.on(
                                        'participantDisconnected',
                                        actions.participantDisconnected
                                    )
                                    room.once(
                                        'disconnected',
                                        function (room, error) {
                                            room.participants.forEach(
                                                actions.participantDisconnected
                                            )
                                            actions.onTwilioKilled()
                                        }
                                    )
                                    //
                                    const localMediaContainer =
                                        document.createElement('div')
                                    localMediaContainer.id = 'owner'
                                    Video.createLocalVideoTrack({
                                        // audio: true,
                                        video: {
                                            width: 300,
                                            height: 300,
                                        },
                                    }).then((track) => {
                                        localMediaContainer.appendChild(
                                            track.attach()
                                        )
                                        //
                                        actions._setState({
                                            key: 'twilioDoctorTrackVideo',
                                            value: track,
                                        })
                                    })
                                    //
                                    Video.createLocalAudioTrack({
                                        audio: true,
                                        // video: {
                                        //     width: 300,
                                        //     height: 300,
                                        // },
                                    }).then((track) => {
                                        localMediaContainer.appendChild(
                                            track.attach()
                                        )
                                        //
                                        actions._setState({
                                            key: 'twilioDoctorTrackAudio',
                                            value: track,
                                        })
                                    })
                                    document
                                        .getElementById('call-doctor-container')
                                        .append(localMediaContainer)
                                }
                            )
                        })
                        //
                        window.addEventListener('beforeunload', function (e) {
                            let match = e.target[Object.keys(e.target)[0]]
                            if (isDefined(match)) {
                                match = match[Object.keys(match)[0]]
                                if (isDefined(match)) {
                                    // eslint-disable-next-line
                                    match.match(
                                        /^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i
                                    )
                                    let domain = match && match[1]
                                    //
                                    // eslint-disable-next-line
                                    let refMath = APP_FRONT_WEB_URL.match(
                                        /^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i
                                    )
                                    let refDomain = refMath && refMath[1]
                                    if (e.origin !== refDomain) {
                                        return false
                                    }
                                }
                            }
                            actions.onTwilioConfirmHangup(e)
                        })
                        window.addEventListener('pagehide', function (e) {
                            let match = e.target[Object.keys(e.target)[0]]
                            if (isDefined(match)) {
                                match = match[Object.keys(match)[0]]
                                if (isDefined(match)) {
                                    // eslint-disable-next-line
                                    match.match(
                                        /^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i
                                    )
                                    let domain = match && match[1]
                                    //
                                    // eslint-disable-next-line
                                    let refMath = APP_FRONT_WEB_URL.match(
                                        /^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i
                                    )
                                    let refDomain = refMath && refMath[1]
                                    if (e.origin !== refDomain) {
                                        return false
                                    }
                                }
                            }
                            actions.onTwilioConfirmHangup(e)
                        })
                    })
                    .catch((error) => {
                        alert(
                            t(
                                "Vous devez autoriser l'utilisation de votre webcam pour utiliser la visioconférence avec votre patient !"
                            )
                        )
                        actions.onTwilioKilled()
                    })
            },
            /**
             * Participant connect to Twilio video conference
             * @function PatientEdit:participantConnected
             * @param {object} participant - Twilio object participant
             */
            participantConnected: (participant) => (state, actions) => {
                var div = document.getElementById(participant.sid)
                if (div && div.length > 0) {
                    //
                } else {
                    div = document.createElement('div')
                    div.id = participant.sid
                    div.classList.add('called-patient')
                }

                participant.on('trackSubscribed', (track) => {
                    actions.trackSubscribed({ div: div, track: track })
                })
                participant.on('trackUnsubscribed', actions.trackUnsubscribed)

                participant.tracks.forEach((publication) => {
                    if (publication.isSubscribed) {
                        actions.trackSubscribed({
                            div: div,
                            track: publication.track,
                        })
                    }
                })
                //
                document.getElementById(
                    'call-patient-container-waiting'
                ).style.display = 'none'
                var patientCalledContainer = document.getElementById(
                    'call-patient-container'
                )
                patientCalledContainer.appendChild(div)
            },
            /**
             * Participant disconnect to Twilio video conference
             * @function PatientEdit:participantDisconnected
             * @param {object} participant - Twilio object participant
             */
            participantDisconnected: (participant) => (state, actions) => {
                let oParticipant = document.getElementById(participant.sid)
                if (oParticipant && oParticipant.length > 0) {
                    oParticipant.remove()
                }
            },
            /**
             * Display video/audio track of connected participant
             * @function PatientEdit:trackSubscribed
             * @param {object} div - Dom element
             * @param {object} track - Twilio object track
             */
            trackSubscribed:
                ({ div, track }) =>
                (state, actions) => {
                    div.appendChild(track.attach())
                },
            /**
             * Kill and remove video/audio track of disconnected participant
             * @function PatientEdit:trackUnsubscribed
             * @param {object} track - Twilio object track
             */
            trackUnsubscribed: (track) => (state, actions) => {
                track.detach().forEach((element) => element.remove())
            },
            /**
             * Kill and disconnect Twilio room
             * @function PatientEdit:onTwilioKilled
             */
            onTwilioKilled: () => (state, actions) => {
                actions.setModalDisplayed(false)
                actions._setState({ key: 'modalHangup', value: false })
                //
                if (isDefined(state.twilioDoctorTrackVideo)) {
                    state.twilioDoctorTrackVideo.stop()
                }
                if (isDefined(state.twilioDoctorTrackAudio)) {
                    state.twilioDoctorTrackAudio.stop()
                }
                if (isDefined(state.stream)) {
                    state.stream.getTracks().forEach((track) => {
                        track.stop()
                    })
                }
                //
                if (isDefined(state.twilioRoom)) {
                    try {
                        state.twilioRoom.localParticipant.tracks.forEach(
                            (publication) => {
                                actions.trackUnsubscribed(publication.track)
                            }
                        )
                    } catch (error) {
                        //
                    }
                    state.twilioRoom.disconnect()
                    actions._setState({ key: 'twilioRoom', value: null })
                }
            },
            /**
             * Get theraflow patient and update state (used for header patient pages)
             * @function PatientEdit:getTheraflowUser
             * @param {input} input - Contain [isvalid], [name], [id] and [values] fields
             */
            getTheraflowUser: () => (state, actions) => {
                Consent.getUserLegalAgreement(state.patientid).then(
                    (consents) => {
                        User.getTheraflowUserById(state.patientid).then(
                            (editedPatient) => {
                                let editedPatientData = editedPatient.data
                                let patientConsent = consents.data
                                if (
                                    Array.isArray(patientConsent) &&
                                    patientConsent.length > 0
                                ) {
                                    patientConsent = patientConsent[0]
                                }
                                editedPatientData.consents = patientConsent
                                if (
                                    isDefined(patientConsent) &&
                                    isDefined(patientConsent.legalDocuments)
                                ) {
                                    let userLegalAgreements =
                                        patientConsent.userLegalAgreements
                                    let allConsents =
                                        patientConsent.legalDocuments
                                    //
                                    for (let allConsent in allConsents) {
                                        let filteredAgreement =
                                            userLegalAgreements.filter(
                                                (f) =>
                                                    f.legalDocument ===
                                                    allConsents[allConsent].id
                                            )[0]
                                        if (
                                            isDefined(filteredAgreement) &&
                                            isDefined(
                                                filteredAgreement.agreement
                                            )
                                        ) {
                                            if (
                                                filteredAgreement.agreement ===
                                                true
                                            ) {
                                                allConsents[
                                                    allConsent
                                                ].agreement = true
                                                allConsents[
                                                    allConsent
                                                ].acceptDate =
                                                    filteredAgreement.date.date
                                            } else if (
                                                filteredAgreement.agreement ===
                                                false
                                            ) {
                                                allConsents[
                                                    allConsent
                                                ].agreement = false
                                            }
                                        }
                                    }
                                    editedPatientData.consents = allConsents
                                }
                                actions._setState({
                                    key: 'editedPatient',
                                    value: editedPatientData,
                                })
                            }
                        )
                    }
                )
            },
            /**
             * Display disclamer popup if changes was not saved before changing page
             * @function PatientEdit:switchingPage
             * @param {string} nextPage - The next page to display
             */
            switchingPage: (nextPage) => (state, actions) => {
                if (state.atLeastOneModification) {
                    let resultat = window.confirm(
                        t(
                            'Cette page demande de confirmer sa fermeture ; des données saisies pourraient ne pas être enregistrées.'
                        )
                    )
                    if (resultat) {
                        actions._setState({
                            key: 'atLeastOneModification',
                            value: false,
                        })
                        actions._setState({
                            key: 'currentPage',
                            value: nextPage,
                        })
                    }
                } else {
                    actions._setState({ key: 'currentPage', value: nextPage })
                }
            },
            /**
             * Used to detect an edition, to display disclamer popup (see [switchingPage]{@link module:PatientEdit~PatientEdit:switchingPage}))
             * @function PatientEdit:setAtLeastOneModification
             * @param {boolean} modified - True if at least one modification occured
             */
            setAtLeastOneModification: (modified) => (state, actions) => ({
                atLeastOneModification: modified,
            }),
            /**
             * Update modal display status and kind of display
             * @function PatientEdit:setModalDisplayed
             * @param {boolean} active - Show/hide modal, if false, empty <modalEditing>
             */
            setModalDisplayed: (active) => (state, actions) => ({
                modalDisplayed: active,
                modalEditing: active ? state.modalEditing : '',
            }),
            setModalEditing: (active) => (state, actions) => ({
                modalEditing: active,
            }),
        }
        PatientEdit = { ...coreFn, ...PatientEdit }
        return PatientEdit
    },
}
