import { isDefined, getUser, getHash } from '@app/core'
import { settings } from '@app/core/settings'
import { ClientErrors } from '@app/api'
import sourceMappedStackTrace from 'sourcemapped-stacktrace'

const { APP_CUSTOMISATION } = settings

let jsError = {},
    timerError = null,
    maxCallInOneSecond = 2,
    nErrorCalls = 0,
    errorHash = '',
    errorMsg = '',
    errorFilter = ['api', 'constants', 'core', 'elements', 'layouts', 'modules']
export const clientErrorsHandler = function (
    msg,
    url,
    lineNo,
    columnNo,
    error,
    loggedUser = null
) {
    sourceMappedStackTrace.mapStackTrace(
        isDefined(error) ? error.stack : error,
        function (mappedStack) {
            if (
                isDefined(
                    errorFilter.filter(
                        (eFilter) => mappedStack[0].indexOf(eFilter) > -1
                    )[0]
                )
            ) {
                errorMsg = (error.message || msg) + ' > ' + mappedStack[0]
                errorHash = getHash(errorMsg + ' - ' + process.env.APP_VERSION)
                let isErrorHash = localStorage.getItem('hash-' + errorHash),
                    errorEnv = process.env.NODE_ENV || 'unknown',
                    errorUrl = window.location.href
                if (
                    !isDefined(errorHash) ||
                    errorHash === '' ||
                    !isDefined(errorMsg) ||
                    errorMsg === '' ||
                    (isDefined(isErrorHash) && isErrorHash === 'true')
                ) {
                    return false
                }
                if (
                    errorEnv === 'production' &&
                    errorUrl.indexOf('-release') > -1
                ) {
                    errorEnv = 'release'
                }
                //
                jsError = {
                    hash: errorHash,
                    url: errorUrl,
                    error: errorMsg,
                    stack: mappedStack.join('\n'),
                    userId:
                        isDefined(loggedUser) && isDefined(loggedUser.id)
                            ? loggedUser.id
                            : 'guest',
                    ua: navigator.userAgent,
                    instance: (APP_CUSTOMISATION || 'unknown') + '-IM',
                    version: process.env.APP_VERSION || 'unknown',
                    env: errorEnv,
                }
                //
                localStorage.setItem('hash-' + errorHash, 'true')
                ClientErrors.sendClientError(jsError).then(() => {
                    //
                })
            }
        }
    )
}
if (process.env.NODE_ENV !== 'development') {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        if (nErrorCalls > maxCallInOneSecond) {
            return
        }
        nErrorCalls++
        try {
            getUser(
                (loggedUser) => {
                    clientErrorsHandler(
                        msg,
                        url,
                        lineNo,
                        columnNo,
                        error,
                        loggedUser
                    )
                },
                false,
                true,
                false
            )
        } catch (e) {
            clientErrorsHandler(msg, url, lineNo, columnNo, error)
        }
        //
        if (timerError) {
            return
        }
        //
        timerError = setInterval(function () {
            if (nErrorCalls === 0) {
                clearInterval(timerError)
                timerError = null
                return
            }
            //
            nErrorCalls--
        }, 1000)
        //
        return false
    }
}
